$block: "twoX-content-block";
$breakpoint-size: "large";

.#{$block} {

  .content-block {
    &:first-child {
      padding-right: map-get($grid-column-gutter, $breakpoint-size)/3;
    }

    &:last-child {
      padding-left: map-get($grid-column-gutter, $breakpoint-size)/3;
    }

    &__content {
      width: auto !important;
    }

    .overview-text__heading {
      font-size: rem-calc(24);
    }

    .image-block {
      .ie & {
        height: rem-calc(345);
      }
    }
  }
}
