$block: "link-list";
$breakpoint-size: "large";

.#{$block} {

  li {
    display: inline-block;

    .dropdown-content {
      width: initial;
      padding: rem-calc(10) rem-calc(60);
      padding-left: 0;
      background-color: #EEEEEE;
      position: absolute;
      top: 30px;
    }
  }

  &__item {
    padding: $space4;
    display: inline;
    white-space: nowrap;
  }

  &--utility-nav {
    font-size: rem-calc(12);
  }

  &--pdp-sticky-nav {
    margin-right: map-get($grid-column-gutter, $breakpoint-size);

    li:first-child {
      margin-right: rem-calc(24);
    }
  }
}