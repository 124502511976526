$block: "overlapping-block";
$breakpoint-size: "large";

.#{$block} {
  &__image {
    position: absolute;
    top: rem-calc(60);
    left: 50%;
  }

  .list-block {
    width: 60%;

    &__wrapper {
      width: 82%;
    }

    &__item {
      padding-right: $space-larger;
    }
  }
}