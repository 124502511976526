$block: "overview-text";
$breakpoint-size: "medium";

.#{$block} {
  margin-bottom: $space-mid;

  .rte-block {
    margin-top: $space2;
  }
}
