$block: "quick-links-primary";
$breakpoint-size: "large";

.#{$block} {
  //min-height: rem-calc(51);
  background-color: $color-primary-brand-dark;

  &__wrapper {
    position: relative;
    display: flex;
	  flex-flow: row nowrap;
    max-width: $global-width;
    margin: auto;
    padding: rem-calc(5) rem-calc(15);
  }

  &__contact {
    justify-content: center;
    width: 50%;

    .cta {
      @include transition;
      width: fit-content;
      margin-right: 15px;
      padding: rem-calc(14.5 20);
      border: 1px solid #00345c;
      border-radius: 6px;

      .icon {
        display: none;
      }

      &:hover,
      &:focus {
        background: $color-brand-accent-1;
        color: $color-text;
      }
    }

    .quick-links-secondary {
      width: 49%;
      padding: 0;
      padding-right: 10px;
      border-radius: rem-calc(6px);

      &__contactDetails {
        top: 28px;
        right: initial;
        left: 5px;
        width: 100%;
        border-top: none;
      }

      &__contact--link--desktop {
        display: inline-block;
      }

      &__contact--link--mobile {
        display: none;
      }

      a {
        border-bottom: 0;
        text-align: left;

        &:after {
          right: -16px !important;
        }
      }
    }

  }

  &__logo {
    justify-content: flex-start;
    width: 60%;
    padding: 0 rem-calc(5);
    background-color: $color-primary-brand-dark;

    &--desktop {
        display: inline-block;
        width: 26%;
        margin: 0 rem-calc(15);
        padding: rem-calc(10) rem-calc(20);
    }

    &--mobile {
      display: none;
  }

    .#{$block}__nav {
      align-self: center;
      width: 50%;

      &:only-child {
        width: 70%;
      }

      &--jump-to {
        width: 47%;
        padding: rem-calc(18) 0;
        color: $color-white;
        font-size: $font-size-smaller;
      }

      li {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }

      .quick-links-secondary {
        background-color: $color-primary-brand-dark;
        color: $color-white;

        &__contact--dropdown > a {
          border-color: rgba(255,255,255,0.46);
          color: $color-white;
          font-family: $font-regular;
          font-size: rem-calc(18);
          font-weight: 400;

          &:after {
            border-color: $color-white transparent transparent;
          }
        }

        .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
          border-color: $color-white transparent transparent;
        }

        &__contactDetails {
            color: $color-gray-3;
        }
      }
    }
  }

  .brand-logo {

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // IE only fix for sticky nav logo
    &__logo {
      position: relative;
      top: rem-calc(10);
      margin-bottom: 0;

      img {
        max-height: rem-calc(40);
      }
    }

  }
	  display: block;
    width: 26%;
    height: 100%;

  }

  &__nav {
	  position: relative;
    width: 100%;
    height: initial;
    border-color: #000509;
	  z-index: 1;

    &--menuContainer {
      display: none;
    }

    & > ul {

      display: flex;
      justify-content: center;
    }

    &--listItem {

      @media screen and (max-width:rem-calc(1220)) {
        margin: 0 rem-calc(25) 0 0;
      }
      margin: 0 rem-calc(73) 0 0;

      &:last-child {
        margin: 0;
      }

      & > div {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.40);
      }
    }

    li {
      border-top: none;
    }

    &--link {
	  padding:rem-calc(20) 0;
      font-size: $font-size-smaller;

      &.dropdown-arrow:after {
        @include iconStyles;
        position: relative;
        left: 5px;
        display: inline-block;
        font-size: 6px; //using rem-calc here appears to not enable the icon to get small enough, but switching to pixels does
        content: map-get($icons, icon-arrow-small);
      }

      &.dropdown-arrow.is-active {
        &.dropdown-arrow:after {
          transform: rotate(180deg);
        }
      }

      &:focus {
        color: $color-white;
      }
    }
  }

  &--content-landing {

    .quick-links-secondary .accordion-menu .nested.is-accordion-submenu {
      top: 25px;
      left: 1px;
      width: 100%;
      margin: 0px;
    }

    .quick-links-primary__logo .quick-links-primary__nav {

      margin-left: rem-calc(29);

      .quick-links-secondary__contact--link--desktop {
        border-bottom: 1px solid rgba(255,255,255,0.46);;
      }

      &--jump-to {
        display: inline-block;
        color: $color-white;
      }
    }

  }
}
