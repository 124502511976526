$block: "cookies-removed";
$breakpoint-size: "small";

.#{$block} {
  position: fixed;
  bottom: 0;
  right: -550px;
  min-width: 300px;
  max-width: 700px;
  z-index: $z-index-max;
  background: $color-white;
  box-shadow: 0 0 25px rgba(0,0,0,0.1), 10px -10px 0 $color-primary-brand;
  padding: $space;
  transition: all .5s ease;

  &.show {
    display: block;
    right: 20px;
    
  }

  &__content p {
    text-align: center;
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    margin-bottom: $space;

    a {
      @include underlineLink;
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    align-items: center;

    .cta {
      padding-left: $space5;
      padding-right: $space5;
      flex-basis: 47%;
    }

    .pdp-primary-cta {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
        margin-right: 10px;
      }
    }

    .view-detail-cta {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
        margin-left: 10px;
      }
    }
  }

}
