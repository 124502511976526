$block: "quick-menu-flat";
$breakpoint-size: "large";

.#{$block} {
  display: block;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  top: rem-calc(51);
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: $global-width;
    margin: auto;
    z-index: 10;
    height: auto;
    max-height: 80%;
  }

  &.is-hidden {
    display: none;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    background: $color-background-lighter;
    padding: rem-calc(16) 0;
    min-width: rem-calc(311);
    max-width: rem-calc(311);
    overflow: auto;
  }

  &__link {
    color: $color-text;
    font-size: $font-size-smaller;
    font-family: $font-medium;
    padding: $space2 $space-larger;
    line-height: 1.72;

    &.is-active {
      background: $color-primary-brand;
      color: $color-white;
    }
  }

  &__pane {
    display: none;
    padding: rem-calc(26) $space-larger;
    background: $color-white;
    width: 100%;

    &.is-active {
      display: block;
      overflow: hidden auto;
    }

    & > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex-basis: 25%;
        margin-bottom: $space;
        padding-right: $space3;

        &:nth-of-type(4n) {
          padding-right: 0;
        }

        ul {
          margin-top: rem-calc(12);

          li {
            line-height: rem-calc(35);
          }
        }
      }
    }
  }

  &__sub-type-title {
    color: $color-text;
    font-size: $font-size-standard;
    font-family: $font-medium;
    padding-bottom: rem-calc(16);
  }

  &__sub-type-product {
    color: $color-gray-3;
    font-size: $font-size-smaller;
    font-family: $font-regular;
    margin-bottom: rem-calc(15);
    margin-top: rem-calc(15);
    padding-bottom: 0;
    padding-top: 0;
  }

  li {
    list-style: none;
  }

  ul {
    margin-left: 0;
  }
}