$block: 'geo-locator-popup';
$breakpoint-size: 'small';

.#{$block} {
	$backdrop: rgba($color-black, .5);
	$gutter-width: rem-calc(20);

	position: fixed;
	top: 15%;
	right: ($gutter-width / 2);
	left: ($gutter-width / 2);
	bottom: auto;
	display: none;
	width: calc(100% - #{$gutter-width});
	height: auto;
	margin-right: auto;
	margin-left: auto;
	padding: rem-calc(45) rem-calc(20) rem-calc(40);
	border: 3px solid $color-primary-brand;
	background-color: $color-white;
	z-index: $z-index-geo-locator-popup;

	&__backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		background-color: $backdrop;
		z-index: $z-index-geo-locator-popup-backdrop;
	}

	&__close {
		position: absolute;
		top: 5px;
		right: $gutter-width;
		transition: color $transition-med;
		color: $color-gray;
		font-size: rem-calc(25);
		font-weight: bold;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $color-black;
		}
	}

	&__content {
		h2,
		p {
			color: $color-black;
		}

		h2 {
			margin-bottom: rem-calc(10);
			font-size: rem-calc(24);
		}

		p {
			margin-bottom: rem-calc(20);
			font-size: rem-calc(14);
		}

		.cta {
			width: 100%;
			margin-bottom: rem-calc(10);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__flag {
		width: 50px;
		height: 37px;
	}

	&__img-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem-calc(25);
	}

	&__logo {
		width: 90px;
	}

	&--show,
	&__backdrop--show {
		display: block;
	}
}
