$block: "content-block";
$breakpoint-size: "small";

/* The typography in the content block should be consistent with the banner-content */

.#{$block} {
  color: $color-white;

  &__display-category {
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    padding-bottom: $space4;
    text-transform: uppercase;
  }

  .rte-block {
    padding: 0;
    color: $color-white;

    p,
    li {
      font-size: map-get(map-get($typography, $breakpoint-size), p);
      color: $color-white;
      margin: 0;
    }

    a {
      background-image: unset;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        background-image: linear-gradient(#FF9500, #FF9500);
        background-size: 100% 2px;
      }
    }

    ul, ol {
      left: 1.25rem;
      position: relative;
      list-style-type: disc;
    }

  }

  .cta {
    margin-top: $space;
    font-size: map-get(map-get($typography, $breakpoint-size), p);
  }

  .image-block {

    &__picture {
      min-height: rem-calc(280);
      picture {
        min-height: rem-calc(280);
      }
    }

    img {
      object-fit: cover;
      min-height: rem-calc(280);
    }
  }

  &__content {
    background-color: $color-primary-brand-lighter;
    position: relative;
    padding: $space-mid $space5;
    box-shadow: 10px 10px 0 $color-white;

    .overview-text__heading {
      a {
        @include underlineLink;
        font-size: rem-calc(18);
        background-image: linear-gradient($color-white, $color-white);
      }
    }

    a {
      color: $color-white;
    }

    &.clearBg {
      background: transparent;
      box-shadow: none;
    }
  }

  .image-block {
    height: auto;
  }

  h2 {
    text-align: left;
    margin-bottom: $space4;
  }

  .overview-text {
    padding: 0;
    margin-bottom: 0;
  }

  .pdp-primary-cta {
    @include transition;
    color: $color-primary-brand;
    background: $color-white;

    &:hover {
      color: $color-black;
      background: $color-brand-accent-1;
    }
  }

  // event details
  &__eventDetails {
    padding: rem-calc(14 0 20);

    &--span {
      font-weight: bold;
      text-transform: uppercase;
      padding-right: rem-calc(10);
      font-size: rem-calc(11);
      line-height: 2;
    }

    &--details {
      font-size: $font-size-smaller;
    }

    p {
      display: flex;
      color: $color-white;

      &:last-child {
        margin: 0;
      }
    }
  }

  &--hero {
    // look for these styles in the content-overlay-block partials
  }

  &--news {
    .#{$block}__content {
      background-color: $color-white;
      color: $color-gray-3;
      box-shadow: none;

      h2 {
        a {
          color: $color-text;
          font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
        }
      }

      li {
        color: $color-primary-brand;

        &:before {
          display: none;
        }
      }

      a {
        color: $color-primary-brand;

        &:hover {
          color: $color-black;
        }
      }

      .rte-block {
        p {
          color: $color-gray-3;
        }
      }

      .pdp-primary-cta {
        border: 1px solid $color-primary-brand;

        &:hover {
          border: 1px solid $color-brand-accent-1;
        }
      }

    }
  }
}
