$block: "close-nav";
$breakpoint-size: "small";

.#{$block} {
  background: $color-black;
  height: rem-calc(60);
  cursor: pointer;

  .icon {
    &:before {
      color: $color-white;
      font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    }

  }

}