$block: "quick-links-secondary";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;

  &__large {
    display: none;
  }

  &__contact {
    position: relative;

    &--dropdown {
      text-align: center;
      font-size: $font-size-mobile-smallest;
      line-height: 1.2;

      & > a {
        font-family: $font-bold;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 0;
        color: $color-black;
        border-bottom: 1px solid $color-border;
      }
    }

    &--link,
    &--socialMedia span {
      color: $color-gray-3;
      text-transform: uppercase;
      display: block;
      font-size: $font-size-mobile-smallest;
      font-family: $font-bold;
      margin: 0;
      margin-right: rem-calc(10);
      margin-bottom: rem-calc(10);
      width: 100%;
      text-align: left;
      line-height: 1.2;
    }

    &--link {
      font-weight: bold;

      .icon {
        position: relative;
        left: -10px;
      }

      .icon:before {
        font-size: rem-calc(14);
      }

      &:hover,
      &:focus {
        span {
          color: $color-black;
        }
      }
    }
    

    &--socialMedia {
      margin-top: rem-calc(10);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-top: 1px solid #D2D2D2;
      padding: rem-calc(20) rem-calc(20) 0 rem-calc(20) !important;

      a {
        padding:0 !important;

        .icon:before {
          font-size: rem-calc(25);
          margin-right: rem-calc(15);
        }

        &:hover {
          .icon:before {
            color: $color-primary-brand;
          }
        }
      }
    }
  }

  &__rte {
    a,
    p {
      font-size: $font-size-mobile-smallest;
      font-weight: bold;
      padding: rem-calc(20) 0;
      color: $color-black;
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
    }
  }

  &__contactDetails {
    background: $color-white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: rem-calc(14) 0;
    box-shadow: 0 4px 5px 0 #AFAFAF;

    & > li {
      padding: 0;

      ul {
        padding-top: rem-calc(10);
        border-bottom: 1px solid $color-border;

        & > li a {
          color: $color-secondary-text;
          padding: 14px 0 !important;

          &:hover {
            color: $color-primary-brand;
          }
        }
      }
    }
  }

  li {
	  line-height: 1;
    list-style: none;
  }

  // foundation
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    border-color: $color-black transparent transparent;
  }

  .accordion-menu .nested.is-accordion-submenu {
    margin: 0;
  }

  .accordion-menu .nested.is-accordion-submenu a {
    padding-left: 30px;
  }


  .accordion-menu .is-accordion-submenu a.quick-links__contact--social {
    padding: rem-calc(10);
  }

  .accordion-menu .is-accordion-submenu a.quick-links__contact--link {
    padding: 0 rem-calc(10);
  }

  ////////////////
  //Modifier Dark
  &--dark {
    background: $color-black;

    a,
    p,
    span,
    .quick-links-secondary__contact--socialMedia a .icon:before {
      color: $color-white;
    }

    .quick-links-secondary__contactDetails a:hover,
    .quick-links-secondary__contactDetails a:focus {
      color: $color-primary-brand;

      span {
        color: $color-white;
      }
    }

    .quick-links-secondary__contact--socialMedia a:hover {
      .icon:before {
        color: $color-primary-brand;
      }
    }

    .quick-links-secondary__contact--link:hover,
    .quick-links-secondary__contact--link:focus {
      span {
        color: $color-white;
      }
    }

    .quick-links-secondary__contactDetails {
      opacity: 1;
      background: $color-black;
    }

    .quick-links-secondary__contactDetails > li ul > li a {
      color: $color-white;
      opacity: .8;
    }

    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
      border-color: $color-white transparent transparent;
    }
  }
}
