$block: "media-block";
$breakpoint-size: "large";

.#{$block} {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: $global-width-pdp-comp;
  }

  &__content {
    flex: 1;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 $space-largest 0 0;
    //max-width: rem-calc(704);
  }

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    padding: 0;
    margin-bottom: $space;
  }

  &__media {
    padding: 0;
    flex: 1;
    flex-basis: 50%;
    width: 50%;
    align-self: center;
    max-width: rem-calc(594);
  }

  .rte-block {
    padding-top: 0;
    padding-left: 0;
  }

  &--media-left {

    .media-block__wrapper {
      flex-direction: row-reverse;
    }

    .media-block {
      &__content {
        padding-right: 0;
        padding-left: $space-largest;
      }
    }
  }

  &--icon {
    .media-container {
      flex-basis: 75%;
    }

    .icon-container {
      flex-basis: 25%;
    }

    .media-block__content {
      padding-right: map-get($grid-column-gutter, $breakpoint-size);
    }
  }

  .image-block__picture {
    margin-top: 0;
  }
}
