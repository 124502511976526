$block: "secondary-cta";
$breakpoint-size: "small";

.#{$block} {
  @include transition;
  color: $color-white;
  border: 1px solid $color-white;
  background-color: transparent;

  &:visited{
    color: $color-white;
  }

  &:hover, &:focus {
    color: $color-text;
    border-color: $color-brand-accent-1;
    background-color: $color-brand-accent-1;
  }

}
