$block: "brand-logo";
$breakpoint-size: "medium";

$logo-size: rem-calc(40);

.#{$block} {

	&__logo {
		min-height: $logo-size * 3;

		img {
			max-height: $logo-size;
			max-width: $logo-size * 4;
		}
	}

}
