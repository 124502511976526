$block: "nav-item";
$breakpoint-size: "small";

.#{$block} {

  &__sub-menu {
    li {
      background-color: $color-white;

      a {
        color: $color-gray-3;
        font-family: $font-medium !important;
      }
    }
  }

  &__list-item-country {
    width: 100% !important;
    height: auto !important;
    background: #0D0D0D;

    a {
      color: $color-white;
      padding: 0 !important;

      &::after {
        display: none !important;
      }
    }

    .is-submenu-parent-item {
      .nav-item__link-label--country {
        padding: inherit;
        padding-left: rem-calc(20);
        background: inherit;
        color: inherit;
      }
    }
  }

  &__sub-menu--brand {
    height: rem-calc(59) !important;
    padding-top: 0 !important;
	padding-right: 1.25rem!important;
  }

  &__link-label--country {
    @include transition;
    display: flex;
    position: relative;
    align-items: center;
    padding: $space5;
    border-top: 1px solid $color-gray-light;
    color: $color-white;
    background: $color-black;
    font-family: $font-medium;
    font-size: rem-calc(14);
    font-weight: 700;

    &:hover, &:focus {
      color: $color-primary-brand-light;
    }

    img {
      margin-right: $space2;
    }

    &:after {
      display: inline-block;
      position: absolute;
      @include iconStyles;
      content: map-get($icons, icon-arrow-small);
      font-size: 6px;
      right: 20px;
      transform: rotate(-90deg);
    }
  }

  &--isCountry {
    padding-left: $space5;
    display: flex;

    div {
      display: flex;
      align-items: center;

      p {
        font-family: $font-bold;
        font-size: rem-calc(13);
        margin: 0 map-get($grid-column-gutter, $breakpoint-size) 0 0;
      }

      a {
        color: $color-primary-brand-lighter !important;
        font-size: map-get(map-get($typography, $breakpoint-size), p-small) !important;
        padding: map-get($grid-column-gutter, $breakpoint-size) 5px !important;

        &.last {
          &:before {
            content: "|";
            color: $color-gray-3;
            padding-right: rem-calc(6);
          }
        }
      }
    }
  }
}
