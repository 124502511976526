$block: "nav-tab-panel";
$breakpoint-size: "small";

.#{$block} {

  .dropdown-pane {
    width: auto;
    padding: 0;

    .contents {
      background: $color-gray-lightest;
    }
  }
}