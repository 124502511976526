$block: "narrow-banner";
$breakpoint-size: "medium";

.#{$block} {
  &__heroImg {
    height: rem-calc(275);
  }

  &__heading {
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
  }

  &__description {
    font-size: map-get(map-get($typography, $breakpoint-size), p);
  }

  /////////////////////////////////
  //MODIFIER: ALIGN CONTENT COLUMN
  &--contentColumn {
    .#{$block} {
      &__content {
        width: 60%;
        margin-left: auto;
      }
    }
  }
}