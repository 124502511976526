$block: 'product-title';
$breakpoint-size: 'small';

.#{$block} {
	position: relative;
    display: grid;
    grid-template-columns: 1fr fit-content(80%) 1fr;
    width: 100%;

	&.component {
		margin-top: rem-calc(70);
		padding: 0 rem-calc(10);
	}

	&:before,
	&:after {
		position: relative;
		top: 0;
		bottom:: 0;
		display: inline-block;
		width: 100%;
		height: 3px;
		margin-top: auto;
		margin-bottom: auto;
		background-color: $color-brand-accent-1;
		content: '';
	}

	h2 {
		margin-bottom: 0;
		padding: 0 rem-calc(10);
		font-size: $font-size-medium-2;
		text-align: center;
	}
}
