$block: "hero-content";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  justify-content: center;
  min-height: $hero-height;
  z-index: 2;

  &__text {
    color: $color-white;
    text-shadow: 0px 2px 4px rgba($color-black,0.5);

    &--company-name {
      font-family: $font-bold;
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      font-weight: 600;
      text-transform: uppercase;
    }

    &--logo {
      max-height: 44px;
      width: auto;
      margin-bottom: rem-calc(10);
    }

    &--header,
    &--header-mobile {
      font-family: $font-bold;
      padding: map-get($grid-column-gutter, $breakpoint-size) 0;
      margin: 0;
    }

    &--description {
      p  {
        color: $color-white;
        font-size: map-get(map-get($typography, $breakpoint-size), p);
      }
    }
  }

  &__cta {
    margin-top: $space;

    .cta {
      box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
    }
  }

  &__wrapper {
    margin: 0 auto;
    padding: $space-large map-get($grid-column-gutter, $breakpoint-size);
    width: 100%;
    justify-content: center;
  }

  // &--left {
  //   .#{$block} {
  //     &__wrapper {
  //       padding-left: map-get($grid-column-gutter, $breakpoint-size);
  //     }
  //   }
  // }

  &--solid-background {
    background-color: $color-primary-brand;
    position: relative;
    min-height: auto;
    width: 100%;

    // .#{$block}__wrapper {
    //   padding: $space-mid $space;
    // }

    .#{$block}__text {
      text-shadow: none;
      max-width: 100%;
      &--description {
        p {
          margin-top: $space;
        }
      }

      &--header  {
        font-size: rem-calc(24);
        font-family: $font-black;
        padding: 0;
      }
    }

    .#{$block}__cta {
      display: none;
    }
  }
}
