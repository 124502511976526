$block: "newsroom";
$breakpoint-size: "large";

.#{$block} {

    &__press-contacts {
        padding: rem-calc(70) rem-calc(60);

        &__contact {
            width: 15%;
        }

        &--wrapper {
            flex-direction: row;
        }

        &__title {
            width: 35%;
            
    
        }
    }
}
