//////// Block Grid Overrides ////////

[class*="medium-up-"] {
	display: flex;
	flex-wrap: wrap;
	min-width: 100%;
	@include removeClearfix;

	> .column {
		float: none;
	}
}

[class*="small-up-"],
[class*="medium-up-"] {
	> .column {
		// Add space between columns when they wrap
		margin-top: rem-calc(16);
	}
}

@for $col from 1 through $grid-column-count {
	// Flexbox
	@if($col <= $block-grid-max) {
		.medium-up-#{$col} {
			> .column {
				flex: 0 0 (100% / $col);
				max-width: (100% / $col); // https://stackoverflow.com/questions/19316449/ie10-flexbox-widths-include-padding-causing-overflow-box-sizing-border-box-do
				
				// The first row shouldn't have a margin on top
				&:nth-child(-n + #{$col}) {
					margin-top: 0;
				}
			}
		}
	}
}

/////// End Block Grid ////////