$block: "product-description";
$breakpoint-size: "medium";

.#{$block} {
  &__wrapper {
    display: flex;
    align-items: flex-start;

    .vertical-slider__img {
      img {
        .ie & {
          top: 0!important;
        }
      }
    }
  }

  &__detailsContainer,
  &__sliderLarge {
    width: 50%;
  }

  &__detailsContainer {
    padding-left: $space;
  }

}
