@import 'scss/vendors/foundation-settings';
@import 'foundation';
@include foundation-everything;
@include foundation-xy-grid-classes(
  /* options
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: true,
  $block-grid: true,
  $collapse: true,
  $offset: true,
  $vertical-grid: true,
  $frame-grid: true
  */
);

@import "scss/generic/mixins-utils";
@import "scss/generic/mixins";
@import "scss/generic/variables";
@import 'scss/generic/fonts';
@import 'scss/generic/icons';
@import "scss/generic/slick";
@import "scss/generic/slick-theme";
@import "scss/generic/global";

@import "./scss/base"; // Mobile First

@media (min-width: #{map-get($breakpoints, medium)}) {
  // Medium Up
  @import "./scss/medium";
}

@media (min-width: #{map-get($breakpoints, large)}) {
  // Large Up
  @import "./scss/large";
}

@media print {
	@import "scss/generic/print";
}
