$block: "vertical-slider";
$breakpoint-size: "large";

.#{$block} {
  &__sliderNav {
    padding-right: rem-calc(70);
  }

  &__mainImage {
    width: rem-calc(339);

    .vertical-slider__img {
      width: rem-calc(339);
      height: rem-calc(530);
    }
  }
}