//////
// Force Element To Self-Clear its Children
// https://css-tricks.com/snippets/css/clear-fix/
//////
@mixin clearfix($side:null) {
  @if($side==null or $side==false) {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  @else {
    clear: $side;
  }
}

@mixin removeClearfix {
  &:after {
    display: none;
  }
}

////////
// Typography
// @param {string} weight - accepts 'regular', 'bold', 'light', or 'italic', and falls back on regular if no param passed
////////
@mixin font($weight) {
  @if($weight=='regular') {
    font-family: $font-regular, $font-fallbacks;
  }

  @else if($weight=='bold') {
    font-family: $font-bold, $font-fallbacks;
  }

  @else if($weight=='light') {
    font-family: $font-light, $font-fallbacks;
  }

  @else if($weight=='italic') {
    font-family: $font-italic, $font-fallbacks;
  }

  @else {
    //fallback on regular if no param
    font-family: $font-regular, $font-fallbacks;
  }
}

////////
// Typography
////////
@mixin fontRegular {
  font-family: $font-regular, $font-fallbacks;
}

@mixin fontLight {
  font-family: $font-light, $font-fallbacks;
}

@mixin fontBold {
  font-family: $font-bold, $font-fallbacks;
}

@mixin fontItalic {
  font-family: $font-italic, $font-fallbacks;
}

////////
// Icon styles
////////
@mixin iconStyles {
  // use !important to prevent issues with browser extensions that change fonts
  font-family: $font-name-icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//////
// Any Icon
// Icons for all items
// @params included for customization
//////

@mixin any-icon($icon: 'folder', $fontSize: $font-size-small) {
  &:before {
    @include iconStyles;
    content: map-get($icons, $icon);
    font-size: $fontSize;
    position: relative;
  }
}

//////
// Shoves the element offscreen (but is still technically visible and focusable)
//////
@mixin visibleOffscreen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

//////
// Border/Stroke styles
// @param side = the side to apply the border to; default is null; null or false will apply it to all sides
// @param color = the color of the border; default to $color-stroke
//////
@mixin strokeThin($side:null, $color: $color-stroke) {
  @if($side==null or $side==false) {
    border: $stroke-thin solid $color;
  }

  @else {
    border-#{$side}: $stroke-thin solid $color;
  }
}

@mixin strokeMedium($side:null, $color: $color-stroke) {
  @if($side==null or $side==false) {
    border: $stroke-med solid $color;
  }

  @else {
    border-#{$side}: $stroke-med solid $color;
  }
}

@mixin strokeThick($side:null, $color: $color-stroke) {
  @if($side==null or $side==false) {
    border: $stroke-thick solid $color;
  }

  @else {
    border-#{$side}: $stroke-thick solid $color;
  }
}

@mixin strokeThicker($side:null, $color: $color-stroke) {
  @if($side==null or $side==false) {
    border: $stroke-thicker solid $color;
  }

  @else {
    border-#{$side}: $stroke-thicker solid $color;
  }
}

//////
// Box Shadow Mixin
// ex: @include box-shadow(2px 2px 2px rgba(#ccc, .8), -2px -2px 2px rgba(#ccc, 0.8)) ;
//////
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

//////
// Creates the line for use in the title-midline mixin
//////
@mixin title-midline-line {
  content: '';
  display: block;
  @include strokeThin(top);
}

//////
// Puts a line on either side of the text
// @param $align string - which side to align the text to. 'left', 'center' (default), 'right'
// @param $text-selector string - the selector for the text, should be a direct decendent and only child
//////
@mixin title-midline($align: 'left', $text-selector: 'span') {
  display: flex;
  align-items: center;

  @if($align=='center') {
    &:before, &:after {
      flex-grow: 1;
      @include title-midline-line;
    }

    #{$text-selector} {
      padding: 0 $space;
    }
  }

  @else if($align=='left') {
    &:after {
      flex-grow: 1;
      @include title-midline-line;
    }

    #{$text-selector} {
      padding: 0 $space 0 0;
    }
  }

  @else if($align=='right') {
    &:before {
      flex-grow: 1;
      @include title-midline-line;
    }

    #{$text-selector} {
      padding: 0 0 0 $space;
    }
  }

  #{$text-selector} {
    display: block;
    max-width: 80%;
    text-align: center;
  }
}


///////
// Mixin to ensure block grid elements are equal height; Apply to .row if this effect is required
///////
@mixin equalHeights {
  >.column {
    display: flex;
    width: 100%;
    align-items: stretch;

    .#{$object} {
      width: 100%;
    }
  }
}

///////
// Mixin to maintain object fit image aspect ratios
///////
@mixin aspectRatio($shape:null) {
  // by default, image is 16:9 landscape
  height: 0;
  position: relative;

  @if($shape==null or $shape==false) {
    padding-bottom: 56.25%; // 16:9 landscape
  }

  @else if($shape=="square") {
    padding-bottom: 100%; // square image
  }

  img {
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}


// This mixin can be used to set the object-fit:
// @param {String} $fit = fill | contain | cover | none | scale-down
// @param {String} $position = the position of the element
// https://github.com/bfred-it/object-fit-images/blob/master/preprocessors/mixin.scss
////////
@mixin object-fit($fit: fill, $position: null) {
  object-fit: $fit;

  @if $position {
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  }

  @else {
    font-family: 'object-fit: #{$fit}';
  }
}


//////
// Styling the placeholder (or element with placeholder class)
// Placeholder Selectors: http://stackoverflow.com/revisions/2610741/11
//////
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &.placeholder {
    @content;
  }
}


////////
// Remove the unit of a length
// https://css-tricks.com/snippets/sass/strip-unit-function/
// @param {Number} $number - Number to remove unit from
// @return {Number} - Unitless number
////////
@function strip-unit($number) {
  @if type-of($number)=='number'and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}


////////
// Gets the distance between the edge of the viewport and the edge of the content on one side
// Can be used in situations where the background is full bleed, but the content is aligned to the grid (when applied to padding)
// Assumptions: $gutter is less than 50% of the viewport; $maxWidth is never larger than the viewport
// @param {String} $breakpoint - The name of the breakpoint to pull the max-width and gutter information from
// @return {String} - The distance (potentially utilizing a calc function)
////////
@function getContainerGridGutter($breakpoint:small) {
  $containerGutter: 0;
  $maxWidth: map-get($content-max, $breakpoint);
  $gutter: map-get($grid-column-gutter, $breakpoint);

  @if(unit($maxWidth)=="%"and unit($gutter)=="%") {
    // e.g. maxWidth is 70%, gutter is 5%, the result would be 20%
    // if the viewport was 1000px wide, that would be 200px
    $containerGutter: ((100% - $maxWidth) / 2) + $gutter;
  }

  @else if(unit($maxWidth)==unit($gutter)) {
    // e.g. maxWidth is 700px, gutter is 50px, the result would be calc(50% - 300px)
    // if the viewport was 1000px wide, that would be 200px
    $containerGutter: calc(50% - #{($maxWidth / 2) - $gutter});
  }

  @else if(unit($maxWidth)=="%") {
    @if($maxWidth==100%) {
      // e.g. maxWidth is 100%, gutter is 50px, the result would be 50px
      // if the viewport was 1000px wide, that would be 50px
      $containerGutter: $gutter;
    }

    @else {
      // e.g. maxWidth is 70%, gutter is 50px, the result would be calc(15% + 50px)
      // if the viewport was 1000px wide, that would be 200px
      $containerGutter: calc(#{50% - ($maxWidth / 2)} - -#{$gutter}); // minus negative due to AEM minification
    }
  }

  @else if(unit($gutter)=="%") {
    // e.g. maxWidth is 700px, gutter is 5%, the result would be calc(55% - 350px)
    // if the viewport was 1000px wide, that would be 200px
    $containerGutter: calc(#{50% + $gutter} - #{$maxWidth / 2});
  }

  @else {
    // e.g. maxWidth is 700px, gutter is 5rem, the result would be calc(50% - 350px + 5rem)
    // if the viewport was 1000px wide and the base font size is 10px, that would be 200px
    $containerGutter: calc(50% - #{$maxWidth / 2} - -#{$gutter}); // minus negative due to AEM minification
  }

  @return $containerGutter;
}

////////
// Applys the page content container styles (.column.row)
// @param $breakpoint = the key in the $breakpoints map to apply the appropriate spacing
// @param $hasBaseStyles = if the element is inheriting the base styles from somewhere else
////////
@mixin pageContainer($breakpoint:'small', $hasBaseStyles:false) {
  max-width: $global-width;
  padding-left: #{map-get($grid-column-gutter, $breakpoint)};
  padding-right: #{map-get($grid-column-gutter, $breakpoint)};

  @if($hasBaseStyles==false) {
    margin-left: auto;
    margin-right: auto;
  }
}

///// Content Body
// 10 columns width
@mixin tenColContainer() {
  width: 76.18%;
  max-width: 1320px;
  margin: auto;
}

/////////
// Alternative content container for faux "full bleed" media align-items
/////////
@mixin fullBleedContainer {
  max-width: $full-bleed-max;
  margin: 0 auto;
}

////////
// The styles for the spinner
// Inspired by https://projects.lukehaas.me/css-loaders/
////////
@mixin spinner {
  $outside-edge: 4;
  $spinner-height: rem-calc(24);
  $dot-size: 1em;
  $dot-shadow-full: 0.2em; // Animating box shadow so the actual size of the dot will be height/width + this spread
  $dot-shadow-mid: 0;
  $dot-shadow-empty: #{-$dot-size};
  $dot-on-edge: ($outside-edge * 1em) - $dot-size;
  $dot-in-corner: ($outside-edge * 1em) - ($dot-size * 2);
  $increment: 100% / 8; // how to divide up the timing (8 positions that the dot will be in)

  // Positions of the dots
  $top: 0 #{-$dot-on-edge};
  $top-right: #{$dot-in-corner} #{-$dot-in-corner};
  $right: #{$dot-on-edge} 0;
  $bottom-right: #{$dot-in-corner} #{$dot-in-corner};
  $bottom: 0 #{$dot-on-edge};
  $bottom-left: #{-$dot-in-corner} #{$dot-in-corner};
  $left: #{-$dot-on-edge} 0;
  $top-left: #{-$dot-in-corner} #{-$dot-in-corner};

  // Active positions
  $top-active: #{$top} 0 #{$dot-shadow-full},
  #{$top-right} 0 #{$dot-shadow-mid},
  #{$right} 0 #{$dot-shadow-empty},
  #{$bottom-right} 0 #{$dot-shadow-empty},
  #{$bottom} 0 #{$dot-shadow-empty},
  #{$bottom-left} 0 #{$dot-shadow-empty},
  #{$left} 0 #{$dot-shadow-empty},
  #{$top-left} 0 #{$dot-shadow-mid};
  $top-right-active: #{$top} 0 #{$dot-shadow-mid},
  #{$top-right} 0 #{$dot-shadow-full},
  #{$right} 0 #{$dot-shadow-mid},
  #{$bottom-right} 0 #{$dot-shadow-empty},
  #{$bottom} 0 #{$dot-shadow-empty},
  #{$bottom-left} 0 #{$dot-shadow-empty},
  #{$left} 0 #{$dot-shadow-empty},
  #{$top-left} 0 #{$dot-shadow-empty};
  $right-active: #{$top} 0 #{$dot-shadow-empty},
  #{$top-right} 0 #{$dot-shadow-mid},
  #{$right} 0 #{$dot-shadow-full},
  #{$bottom-right} 0 #{$dot-shadow-mid},
  #{$bottom} 0 #{$dot-shadow-empty},
  #{$bottom-left} 0 #{$dot-shadow-empty},
  #{$left} 0 #{$dot-shadow-empty},
  #{$top-left} 0 #{$dot-shadow-empty};
  $bottom-right-active: #{$top} 0 #{$dot-shadow-empty},
  #{$top-right} 0 #{$dot-shadow-empty},
  #{$right} 0 #{$dot-shadow-mid},
  #{$bottom-right} 0 #{$dot-shadow-full},
  #{$bottom} 0 #{$dot-shadow-mid},
  #{$bottom-left} 0 #{$dot-shadow-empty},
  #{$left} 0 #{$dot-shadow-empty},
  #{$top-left} 0 #{$dot-shadow-empty};
  $bottom-active: #{$top} 0 #{$dot-shadow-empty},
  #{$top-right} 0 #{$dot-shadow-empty},
  #{$right} 0 #{$dot-shadow-empty},
  #{$bottom-right} 0 #{$dot-shadow-mid},
  #{$bottom} 0 #{$dot-shadow-full},
  #{$bottom-left} 0 #{$dot-shadow-mid},
  #{$left} 0 #{$dot-shadow-empty},
  #{$top-left} 0 #{$dot-shadow-empty};
  $bottom-left-active: #{$top} 0 #{$dot-shadow-empty},
  #{$top-right} 0 #{$dot-shadow-empty},
  #{$right} 0 #{$dot-shadow-empty},
  #{$bottom-right} 0 #{$dot-shadow-empty},
  #{$bottom} 0 #{$dot-shadow-mid},
  #{$bottom-left} 0 #{$dot-shadow-full},
  #{$left} 0 #{$dot-shadow-mid},
  #{$top-left} 0 #{$dot-shadow-empty};
  $left-active: #{$top} 0 #{$dot-shadow-empty},
  #{$top-right} 0 #{$dot-shadow-empty},
  #{$right} 0 #{$dot-shadow-empty},
  #{$bottom-right} 0 #{$dot-shadow-empty},
  #{$bottom} 0 #{$dot-shadow-empty},
  #{$bottom-left} 0 #{$dot-shadow-mid},
  #{$left} 0 #{$dot-shadow-full},
  #{$top-left} 0 #{$dot-shadow-mid};
  $top-left-active: #{$top} 0 #{$dot-shadow-mid},
  #{$top-right} 0 #{$dot-shadow-empty},
  #{$right} 0 #{$dot-shadow-empty},
  #{$bottom-right} 0 #{$dot-shadow-empty},
  #{$bottom} 0 #{$dot-shadow-empty},
  #{$bottom-left} 0 #{$dot-shadow-empty},
  #{$left} 0 #{$dot-shadow-mid},
  #{$top-left} 0 #{$dot-shadow-full};

  content: '';
  display: inline-block;
  font-size: $spinner-height / (($outside-edge * 2) + 1); // Everything is in ems, relative to this font-size

  margin: #{$outside-edge * $dot-size}; // give room for the animated dots (the content area determines size of the dot)
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
  z-index: 3;

  color: $color-primary-brand; // box-shadow will inherit this if a color is not provided
  position: relative;
  box-shadow: #{$top-active};
  animation: spinner 1.3s infinite linear;

  @keyframes spinner {
    #{$increment * 1} {
      box-shadow: #{$top-right-active};
    }

    #{$increment * 2} {
      box-shadow: #{$right-active};
    }

    #{$increment * 3} {
      box-shadow: #{$bottom-right-active};
    }

    #{$increment * 4} {
      box-shadow: #{$bottom-active};
    }

    #{$increment * 5} {
      box-shadow: #{$bottom-left-active};
    }

    #{$increment * 6} {
      box-shadow: #{$left-active};
    }

    #{$increment * 7} {
      box-shadow: #{$top-left-active};
    }

    #{$increment * 8} {
      box-shadow: #{$top-active};
    }
  }
}

////////
// Renders ellipsis (…) character in "content"
////////
// @mixin ellipsis($before:true, $spacing: 0) {
// content: "\2026";
// font-size: $font-size-standard;
// display: inline-block;
// vertical-align: middle;
// width: auto;
// line-height: inherit;

// @if($before) {
// margin: 0 $spacing 0 0;
// } @else {
// margin: 0 0 0 $spacing;
// }
// }


//////
// Focus Glow
// @param color = The color of the styles to apply; default is null and applies the primary colors, false will reset them to nothing
//////
@mixin formFocus($color:null) {
  @if($color==null) {
    border-color: $color-black;
    box-shadow: 0 0 5px rgba($color-black, 0.5);
  }

  @else if($color !=false) {
    border-color: $color;
    box-shadow: none;
  }

  @else {
    border-color: transparent;
    box-shadow: none;
  }
}

@mixin iconErrorSuccess($top, $right) {
  font-size: $font-size-smallest;
  position: absolute;
  top: $top;
  right: $right;
}

///////
// transition
// @param $property == the css property to be transitioned; default: all
// @param $speed == the speed of the transition; can be either "animate-fast" or "animate-slow"; default: fast
// @param $ease == the ease of the transition; default: ease-in
///////

@mixin transition($property:null, $speed:null, $ease:null) {
  -webkit-transition: $property $speed $ease;
  -moz-transition: $property $speed $ease;
  -ms-transition: $property $speed $ease;
  -o-transition: $property $speed $ease;
  transition: $property $speed $ease;

  @if($speed==null) {
    transition: $property #{$animate-fast} $ease;
  }

  @else if($property==null) {
    transition: all $speed $ease;
  }

  @else if($ease==null) {
    transition: $property $speed ease-in;
  }

}

///////
// underlineLink
// mixin to apply the underline link effect on various elements
///////
@mixin underlineLink {
  @include transition();
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  background-image: linear-gradient($color-brand-accent-1, $color-brand-accent-1);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  &:hover, &:focus {
    background-size: 100% 2px;
  }
}
