// MIXINS THAT ARE NEEDED FOR VARIABLE CALCULATION

$font-size-base: 18px !default;
$browser-default-font-size: 16px;

//////
// Converts a number to an integer
//////
@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

//////
// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
// https://github.com/zurb/foundation-sites/blob/v6.2.1/scss/util/_unit.scss
//////
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

//////
// Converts one or more pixel values into matching rem values.
// https://github.com/zurb/foundation-sites/blob/v6.2.1/scss/util/_unit.scss
// Dependencies: -zf-to-rem
//////
@function rem-calc($values, $base: $font-size-base) {
  $rem-values: ();
  $count: length($values);

  @if $count==1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

//////
// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value.
// https://github.com/zurb/foundation-sites/blob/v6.2.1/scss/util/_unit.scss
// Dependencies: strip-unit
//////
@function -zf-to-rem($value, $base: $font-size-base) {

  // Check if the value is a number
  @if type-of($value) !='number' {
    @warn inspect($value)+' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Calculate rem if units for $value is not rem
  @if unit($value) !='rem' {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
  }

  // Turn 0rem into 0
  @if $value==0rem {
    $value: 0;
  }

  @return $value;
}

//////
// Converts a rem value to matching px value. *Any* value passed, regardless of unit, is assumed to be a rem value.
// Dependencies: getBase, strip-unit
//////
@function un-rem-calc($value, $base: $font-size-base) {
  @if type-of($value) !='number' {
    @warn inspect($value)+' was passed to un-rem-calc(), which is not a number.';
    @return $value;
  }

  @if unit($value) !='px' {
    $value: strip-unit($value) * strip-unit($base) * 1px;
  }

  // Turn 0rem into 0
  @if $value==0px {
    $value: 0;
  }

  @return $value;
}

////////
// Translate a value to em's
// @param $value - The absolute value to be converted, in rems or px
// @param $base - The base font size to be used for the calcuation, defaults to $browser-default-font-size
// @return {Number} - The value converted to ems
////////
@function em-calc($value, $em-base: $browser-default-font-size, $rem-base: $font-size-base) {
  @if(unit($value)=='rem') {
    $value: un-rem-calc($value, $rem-base);
  }

  @else if(unit($value)=='') {
    $value: $value * 1px;
  }

  @return ($value / $em-base) * 1em;
}

//////
// A whole bunch of math functions
// https://unindented.org/articles/trigonometry-in-sass/
//////
@function fact($number) {
  $value: 1;

  @if $number>0 {
    @for $i from 1 through $number {
      $value: $value * $i;
    }
  }

  @return $value;
}

@function pow($number, $exp) {
  $value: 1;

  @if $exp>0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  }

  @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number;
    }
  }

  @return $value;
}

@function pi() {
  @return 3.14159265359;
}

@function rad($angle) {
  $unit: unit($angle);
  $unitless: $angle / ($angle * 0 + 1);

  // If the angle has 'deg' as unit, convert to radians.
  @if $unit==deg {
    $unitless: $unitless / 180 * pi();
  }

  @return $unitless;
}

@function sin($angle) {
  $sin: 0;
  $angle: rad($angle);

  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
  }

  @return $sin;
}

@function cos($angle) {
  $cos: 0;
  $angle: rad($angle);

  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
  }

  @return $cos;
}

@function tan($angle) {
  @return sin($angle) / cos($angle);
}