$block: "overview-content";
$breakpoint-size: "small";

.#{$block} {
  text-align: center;

  .img-container {
    margin-top: $space;
  }

  .view-detail-cta {
    margin-top: 0;
  }
}
