$block: "featured-items";
$breakpoint-size: "small";

.#{$block} {
  text-align: center;
  position: relative;
  margin: 0 rem-calc(-5);
  padding: $space-larger map-get($grid-column-gutter, $breakpoint-size);


  &__cards {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .featured-card {
      margin: 0 auto map-get($grid-column-gutter, $breakpoint-size);
    }
  }

  &__background,
  &__backgroundFull {
    background: $color-gray-lightest;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
  }

  &__background {
    height: 50%;
    bottom: 0;
  }

  &__backgroundFull {
    top: 0;
    height: 100%;
  }

}
