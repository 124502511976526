// Print Styles
nav,
.hero-breadcrumbs,
footer,
.icon-menu,
.search-icons,
.global_nav__buy-cta,
a[href]:after, /*hide urls inserted after links*/
.cta_button  {
	display: none!important;
}

.global-nav {
	&__search,
	&__tabs,
	hr {
		display: none;
	}

	.grid-x {
		height: auto;
		justify-content: flex-start;
	}

	.header-navigation-logo {
		margin: 0;
	}

	.cell {
		display: block;
	}
}

.hero {
	&--set-height {
		min-height: auto;
	}
}

.hero-image {
	height: auto;
	width: auto;
	max-width: 100%;
	position: static;
	margin: 0 auto;

	picture {
		position: static;
	}
}

.article-detail-page {
	&__content {
		margin-bottom: 0;
		padding: 40px 0;
		transform: none;
	}

	.rte-block {
		.component {
			display: none;
		}
	}

	.component,
	.video-block,
	.featured-items,
	.content-overlay-block,
	.twoX-content-block  {
		display: none;
	}
}
