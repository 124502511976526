$block: "regional-selector";
$breakpoint-size: "large";

.#{$block} {
	&__accordion-btn,
	&__accordion-content {
		padding: 0;
		background-color: transparent;
	}

	&__accordion-btn {
		margin-bottom: rem-calc(17);
		font-size: $font-size-mobile-standard;
		cursor: unset;

		&:after {
			display: none;
		}
	}

	&__accordion-content {
		display: block;

		a {
			margin-bottom: rem-calc(12);
			font-size: $font-size-small;
		}
	}

	&__item {
		break-inside: avoid;
		margin-bottom: rem-calc(40);
		border: 0;
	}

	&__item-wrapper {
		column-count: 3;
		column-gap: rem-calc(15);
		border: 0;
	}

	&__title {
		margin-bottom: rem-calc(50);

		h1 {
			margin-bottom: rem-calc(20);
			font-size: rem-calc(36);
		}

		p {
			font-size: $font-size-small;
		}
	}

	&--menu {
		padding: rem-calc(80) 0 rem-calc(120);

		&:before {
			background-size: 100% auto;
		}
	}
}
