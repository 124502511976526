$block: "post-card";
$breakpoint-size: "small";

.#{$block} {
  display: flex;

  &__image {
    height: rem-calc(140);
    width: rem-calc(140);

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  &__content {
    padding-left: $space3;
    //max-width: rem-calc(320);

    &--cardTag {
      background: $color-gray-lighter-2;
      color: $color-gray-3;
      text-transform: uppercase;
      font-size: rem-calc(10);
      font-family: $font-bold;
      line-height: $line-height;
      padding: $space-smallest $space2;
    }

    a {
      display: block;
      margin: $space2 0;
      color: $color-text;
    }

    p {
      a {
        display: inline-block;
        margin: 0;
      }
    }

    &--title {
      @include underlineLink;
      font-size: $font-size-mobile-standard;
      font-family: $font-bold;
      margin: 0;
      display: inline;
      padding: 0;
    }

    &--meta {
      text-transform: uppercase;
      color: $color-gray-3;
      font-size: rem-calc(10);
      font-family: $font-bold;
    }
  }

  ///////////////////////////////
  // MODIFIER: FEATURED POST CARD
  &--featured {
    flex-direction: column;

    .#{$block} {
      &__image {
		position: relative;
        height: 0;
        width: 100%;
        max-width: none;
        margin: auto;
		padding-top: 9px / 16px * 100%;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          height: rem-calc(300);
        }

		picture {
			position:absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
      }

      &__content {
        max-width: none;
        padding-top: $space;
        padding-left: 0;

        &--title {
          font-size: map-get(map-get($typography, $breakpoint-size), h3);
        }

      }

    }
  }

  //////////////////////////
  // MODIFIER: EVENT CARD
  &--eventCard {
    flex-direction: column;
    padding: $space-large $space5;
    border-top: $border-thinnest solid $color-border;
    max-width: rem-calc(410);
    margin: auto;

    .#{$block} {
      &__image {
        margin: auto;
      }

      &__content {
        max-width: none;
        padding: $space-large 0 $space-mid;

        &--title {
          font-weight: bold;
          padding-top: 0;
        }

        &--details {
          display: flex;
          font-size: $font-size-smaller;
          color: $color-body-text;
          padding-bottom: map-get($grid-column-gutter, $breakpoint-size);

          p:first-child {
            font-weight: bold;
            text-transform: uppercase;
            padding-right: rem-calc(10);
            font-size: rem-calc(11);
            line-height: 2;
          }

          p {
            margin: 0;
          }
        }

        &--cardTag,
        &--description {
          display: none;
        }
      }

      &__cta {
        width: 100%;

        .view-detail-cta {
          width: 100%;
        }
      }
    }
  }
}
