$block: "global-nav";
$breakpoint-size: "large";

.#{$block} {

  &__row {
    max-width: $global-width;
    margin: 0 auto;
  }

  .grid-container {
    width: 100%;
    max-width: $global-width;
  }

  .cell {
    display: inline-block;
    padding: 0 map-get($grid-column-gutter, $breakpoint-size);
  }

  &--full {
    padding: map-get($grid-column-gutter, $breakpoint-size);
  }

  &__links {
    padding-left: map-get($grid-column-gutter, $breakpoint-size) - 1rem; // the minus 1rem is to account for foundation variable placing padding around a dropdown menu link
	padding-right: map-get($grid-column-gutter, $breakpoint-size);
  }

  .dropdown-trigger--supplementary-link {
    padding-right: 0 !important;
  }

  background: $color-white;
  width: 100%;



  &__logo {
    .header-navigation-logo {
      width: rem-calc(117) !important;
      height: rem-calc(55);
    }
  }

  &--full {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  &--full--dropdown {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &--small {
    display: none;
  }


  .cell {
    justify-content: flex-start;
    margin: 0;

    &:first-of-type {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__search {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: rem-calc(835);
    height: rem-calc(45);
    padding-left: 0 !important;

    label {
      font-size: 0;
    }

    .#{$block} {
      &__search-submit {
        position: absolute;
        right: $space;
        top: rem-calc(10);
        padding-top: rem-calc(2);
        color: $color-black;
      }

      &__search-input {
        padding: rem-calc(14 20);
        height: rem-calc(45);
        background: $color-gray-lighter;
        width: 100%;
        margin: 0;
        color: $color-text;
      }
    }
  }

  .symbol-text-cta {
    margin: 0;
    padding-left: 35px;
    padding-right: 35px;
  }

  &__link-categories {
    display: flex;
  }

  hr {
    max-width: 100%;
    margin: 0;
    display: block;
  }

  &__dropdown {
    display: flex;
    position: relative;
    margin: 0 auto;
    padding: 0;

    .cell {
      padding: 0;
    }

    .vertical {
      flex-direction: row;
    }

    .loginBtn {
      display: none;
    }

    .global_nav__buy-cta {
      display: none;
    }

    .link-list {
      display: none;
    }
  }

  &__back {
    display: none !important;
  }

  &__links {
    width: 100%;
    z-index: 100;
    height: rem-calc(72);
    align-items: flex-end;
    flex-wrap: nowrap !important;
  }

  .brands-multi-content-block {
	  .grid-x {
		  justify-content: flex-start;
	  }
  }

  &__tabs {
    display: block;
    width: 100%;
    max-width: $global-width;

    .grid-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__dropdown-menu {
    display: none;
  }

  .showSearch {
    display: none;
  }
}
