$block: "brands-hero";
$breakpoint-size: "small";

.#{$block} {
  position: relative;
  max-height: 280px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
    display: flex;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    min-height: $hero-height;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
      position: relative;
      align-items: center;
      min-height: 600px;
    }

  }

  .hero-image {
    img {
      object-position: 98% 0;
      min-height: 280px;
    }
  }

  &__content {
    padding: $space-larger $space5;
    width: 100%;
    justify-content: center;
    z-index: 2;
  }

  &__column {
    max-width: 90%;
  }

  &__brandName {
    color: $color-white;
    font-family: $font-black;
    font-size: $font-size-smaller;
    text-transform: uppercase;
  }

  &__title {
    color: $color-white;
    font-family: $font-bold;
    font-size: map-get(map-get($typography, $breakpoint-size), h1);
    padding-top: $space-mid;
  }

  // overwriting
  &__cta {
    max-width: rem-calc(500);

    .cta {
      margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
    }
  }

  .rte-block {
    padding: rem-calc(33) 0 map-get($grid-column-gutter, $breakpoint-size);

    p, h1 {
      color: $color-white;
    }

    h1 {
      font-family: $font-bold;
    }
  }

  .brands-hero__subTitle {
    color: $color-white;
    font-family: $font-black;
    font-size: map-get(map-get($typography, $breakpoint-size), h1);
  }
}
