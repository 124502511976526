$block: "success-msg";
$breakpoint-size: "small";

.#{$block} {
  position: relative;
  max-width: 743px;
  margin: auto;

  &__wrapper {
    background: $color-white;
    padding: rem-calc(24);

    .rte-block {
      width: 90%;
      max-width: rem-calc(566);
      padding: 0;

      h2 {
        font-family: $font-bold;
        font-weight: 900;
        color: $color-primary-brand-lighter;
        font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
      }

      p {
        color: $color-body-text;
        font-size: map-get(map-get($typography, $breakpoint-size), h4);
        margin-bottom: $space;
      }
    }

    .cta {
      padding-left: rem-calc(47);
      padding-right: rem-calc(47);
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: rem-calc(24);
    top: rem-calc(24);
    width: rem-calc(17);
    height: rem-calc(17);
    background: $color-gray-light;
    border-radius: 100px;

    span {
      position: relative;


      &:before {
        @include iconStyles;
        content: map-get($icons, icon-close-menu);
        font-size: rem-calc(7);
        position: absolute;
        top: rem-calc(6);
        left:rem-calc(5);
        color: $color-white;
      }
    }
  }
}