$block: "narrow-banner";
$breakpoint-size: "small";

.#{$block} {
  position: relative;

  &__heroImg {
    width: 100%;
    height: rem-calc(275);

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    padding: 0 $space5;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    text-align: center;

    .cta {
      min-width: rem-calc(200);
    }
  }

  &__heading {
    color: $color-white;
    font-family: $font-bold;
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
    margin: 0;
  }

  &__description {
    color: $color-white;
    opacity: .75;
    font-size: map-get(map-get($typography, $breakpoint-size), p);
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
  }

  /////////////////////////////////
  //MODIFIER: ALIGN CONTENT COLUMN
  &--contentColumn {
    .#{$block} {
      &__heroImg {
        height: rem-calc(350);
      }

      &__content {
        text-align: left;
      }
    }
  }
}