$block: "warranty-page";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;

  .hbspt-form {
    .hbspt-form {
      padding: 0;

      fieldset,
      .hs_recaptcha,
      .hs_submit {
        padding: 0 rem-calc(27);
      }
    }
  }

  &__rte {
    h2 {
      font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
      font-weight: bold;
      color: $color-text;
    }

    p {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      color: $color-body-text;
    }
  }

  select,
  input,
  textarea {
    margin-bottom: rem-calc(30);
  }

  select, input {
    border-radius: 6px;
    border: 1px solid #767676;
    padding: 0.75rem 1.25rem;
    height: 3rem;
    font-family: "robotolight", Helvetica, sans-serif;
    color: #545759;
    text-transform: capitalize;
  }

  label {
    display: block;
    margin: 0 0 0.625rem;
    font-size: rem-calc(12);
    font-weight: normal;
    line-height: 1.8;
    color: #0a0a0a;
    font-family: "robotobold", Helvetica, sans-serif;
    text-transform: uppercase;
  }


  textarea {
    border-radius: 6px;
    border: 1px solid #767676;
    padding: 0.75rem 1.25rem;
    font-family: "robotolight", Helvetica, sans-serif;
    color: #545759;
    text-transform: capitalize;
    height: rem-calc(145);
  }

  .actions input {
    cursor: pointer;
    font-family: $font-bold;
    background: $color-primary-brand;
    color: $color-white;
    border: none;
    margin-top: rem-calc(30);
    padding-left: rem-calc(81);
    padding-right: rem-calc(81);
  }

  .hbspt-form .hs_submit {
    text-align: left;
  }
}