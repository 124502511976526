$block: "list-block";
$breakpoint-size: "large";

.#{$block} {
  padding: $space-larger 0 $space-larger $space-larger;

  &__item {
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
    padding-right: map-get($grid-column-gutter, $breakpoint-size);
  }

  &__text {
    &--title {
      font-size: rem-calc(21);
    }

    &__description {
      font-size: $font-size-base;
    }
  }
}