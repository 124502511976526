$block: "region-selector-hero";
$breakpoint-size: "medium";

.#{$block} {
  .hero-image {
    height: calc(100vh - 62px);
  }

  &__wrapper {
    max-width: $global-width-mid;
    margin: auto;
  }

  &__content {
    padding: 0 map-get($grid-column-gutter, $breakpoint-size);
    width: 100%;
    max-width: rem-calc(700);
  }

  &__cta {
    .cta {
      &:first-child {
        margin-right: map-get($grid-column-gutter, $breakpoint-size);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__footer {
    height: rem-calc(62);
    padding-left: map-get($grid-column-gutter, $breakpoint-size);
    padding-right: map-get($grid-column-gutter, $breakpoint-size);

    &--wrapper {
      max-width: $global-width-mid;
    }
  }

  // Tool Tip
  &__toolTip {
    display: flex;
    flex-direction: row;

    &--text {
      padding: rem-calc(15 15) 0 0;
    }

    .tool-tip {
      position: inherit;
      top: 0;

      &__block {
        max-width: rem-calc(310);

        &:before {
          top: rem-calc(8);
          left: rem-calc(-8);
          transform: translate(0);
          border-left: none;
          border-top: 7px solid transparent;
          border-right: 10px solid white;
          border-bottom: 7px solid transparent;
          -webkit-filter: drop-shadow(-3px 3px 2px rgba(0,0,0,0.25));
          filter: drop-shadow(-3px 3px 2px rgba(0,0,0,0.25));
        }
      }
    }
  }
}