$block: "pardot-popup";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;
  position: relative;

  .reveal {
    width: rem-calc(743);
  }

  #pardot-form-container {
    padding-top: 30px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: rem-calc(24);
    top: rem-calc(24);
    width: rem-calc(17);
    height: rem-calc(17);
    background: $color-gray-light;
    border-radius: 100px;

    span {
      position: relative;


      &:before {
        @include iconStyles;
        content: map-get($icons, icon-close-menu);
        font-size: rem-calc(7);
        position: absolute;
        top: rem-calc(6);
        left:rem-calc(5);
        color: $color-white;
      }
    }
  }
}

.sf-iframe-height {
  height: 750px !important;
  margin-top: 40px;
  margin-bottom: 40px;
}

#pardot-form-container {
  .facets__gif-loader {
    position: absolute;
    margin: 0 auto;
    left: 0;
    top: calc(50% - 150px);
  }
}