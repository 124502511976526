$block: "rotating-carousel";
$breakpoint-size: "large";

.#{$block} {
  //padding: 0 0 $space-large;
  position: relative;

  &__contentCard {
    padding: rem-calc(15) 0;
    position: relative;
    transition: all 200ms ease-in;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }

  &__image {
    width: rem-calc(314);
    height: rem-calc(360);
    margin: auto;
  }

  &__text {
    bottom: rem-calc(40);

    &--title {
      font-size: rem-calc(21);
    }
  }

  .slick-list {
    padding-right: 0;
  }
}
