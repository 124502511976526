$block: "content-overlay-block";
$breakpoint-size: "large";

.#{$block} {
  max-width: none;
  width: 100%;

  &--media-align-center {
    .content-block {
      text-align: center !important;
      margin: 0 auto;

      h2 {
        text-align: center !important;
      }

      &__wrapper {
        margin: auto;
      }

      &__content {
        margin: auto;
      }
    }
  }

  &--media-align-right {
    .content-block {
      text-align: right !important;
      align-items: flex-end;

      h2 {
        text-align: right !important;
      }

      &__wrapper {
        margin-right: 0;
      }

      &__content {
        margin-left: auto;
      }
    }
  }
}

// is hero
.#{$block} .content-block,
.content-block--hero {

  max-width: $global-width;
  margin: 0 auto;

  .content-block__content {
    max-width: 50%;
    padding: $space-larger;
  }
}
