$block: "loginBtn";
$breakpoint-size: "large";

.#{$block} {
  display: block;
  height: 100%;
  padding: rem-calc(12) rem-calc(23) rem-calc(12) rem-calc(30);
  width: auto;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: $color-white;
    width: rem-calc(3);
    height: rem-calc(45);
    z-index: 2;
    transform: rotate(160deg) translate3d(rem-calc(-8), 0, 0);
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-right: rem-calc(20) solid transparent;
    z-index: 1;
  }

  .icon {
    display: none;
  }
}