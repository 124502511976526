$block: "product-listing-page";
$breakpoint-size: "large";

.#{$block} {
	background: $color-gray-lightest;

	&__content {
		padding: $space5 0;
	}

  &__content__teaser {
	  max-width: $global-width - (map-get($grid-column-gutter, $breakpoint-size) * 2);
		padding: $space map-get($grid-column-gutter, $breakpoint-size);

		ul, ol {
			margin-left: $space-mid;
		}
  }

  .featured-card {
    max-width: $global-width;
    height: rem-calc(600);
    margin-top: 0;
    margin-bottom: $space5;

    &__content {
      padding: $space5;
    }

    &__text {
      max-width: rem-calc(420);
      margin-right: $space5;
      overflow: inherit;
    }

    &__text--description {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      height: 69px;
      margin: 0 auto;
      font-size: 14px;
      line-height: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      p {
        height: auto;
        font-size: rem-calc(14) !important;
        opacity: .85;
        overflow: inherit;
      }
    }

    &__cta {
      bottom: 0;
      width: unset;
      margin-top: auto;

      .cta {
        min-width: rem-calc(175);
      }
    }

    &__image {
      width: rem-calc(220) !important;
      max-width: rem-calc(220);
      height: rem-calc(220) !important;

      picture, img {
        width: auto;
        max-width: rem-calc(220);
      }
    }
  }

  .featured-card--horizontally {
    height: rem-calc(260);

    .featured-card__text {
      padding-left: $space-large;
    }

    .featured-card__cta {
      bottom: auto;
      margin-top: 0;
    }

  }
}
