$block: "content-block";
$breakpoint-size: "large";

.#{$block} {

  .cta {
    margin-top: $space-mid;
  }

}
