$block: "icon-container";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    color: $color-primary-brand;
    font-size: rem-calc(100); //from comp
  }
}