$block: "submit-btn";
$breakpoint-size: "small";

.#{$block} {
  @include transition;
  cursor: pointer;
  display: inline-block;
  font-family: $font-bold;
  font-size: map-get(map-get($typography, $breakpoint-size), button);
  line-height: $line-height;
  padding: $space3 $space;
  border-radius: $border-radius;
  text-align: center;
  text-transform: uppercase;
  background: $color-primary-brand;
  color: $color-white;

  &:visited {
    color: $color-white;
  }

  &:hover, &:focus {
    color: $color-text;
    background: $color-brand-accent-1;
  }

}
