$block: "overlapping-block";
$breakpoint-size: "small";

.#{$block} {
  position: relative;
  overflow: hidden;

  &__image {
    position: relative;
    top: rem-calc(20);
    z-index: 1;
    width: 90%;
    margin: auto;
    height: 100%;

    img {
      object-fit: contain;
      box-shadow: -10px 10px 0 $color-white;
    }
  }
}
