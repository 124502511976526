$block: "region-selector-block";
$breakpoint-size: "small";

.#{$block} {
  &__wrapper {
    padding: $space5;
    text-align: center;
  }

  &__textWrapper {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    padding: rem-calc(20 22);
    text-align: left;
  }

  &__title {
    margin: 0;
    color: $color-text;
    font-family: $font-bold;
    font-size: $font-size-mobile-large;
    text-align: center;
    text-transform: capitalize;
  }

  &__region-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 rem-calc(20);


    &--title {
      font-size: $font-size-smaller !important;
      line-height: rem-calc(17)
    }

    &--subLink {
      @include underlineLink;
      position: relative;
      display: inline !important;
      margin-bottom: rem-calc(12) !important;
      font-family: $font-medium!important;
      font-size: $font-size-smallest !important;
      text-transform: uppercase;

      &:nth-child(3) {
        position: relative;
        margin-left: 0;

        &:before {
          display: none;
        }
      }
    }

    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 48%;
      margin-bottom: rem-calc(20);
      padding: rem-calc(10);
      border: 1px solid $color-gray-4;
      text-align: center;

      &:last-child {
        border-bottom: 1px solid $color-gray-light;
      }

      &.is-active {
        .region-selector-block__image {
          opacity: .75;
        }

        .region-selector-block__region-nav--title {
          color: $color-primary-brand;
        }
      }
    }

    &--additional-sites {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      margin: rem-calc(10) 0;
      color: $color-secondary-text;
      font-family: $font-bold;
      font-size: $font-size-smallest;

      &-wrapper {
        display: flex;
        flex-direction: column;

      }

    &-wrapper  &-link {
        position: relative;
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: center;
        width: initial !important;
        margin-bottom: rem-calc(15);
        font-family: $font-regular !important;
        font-size: $font-size-smallest-2 !important;
        font-weight: 300;
        line-height: 23px;
        text-transform: uppercase;

        &:nth-child(n+3) {
          position: relative;
          margin-left: 0;

          &:before {
            position: absolute;
            top: rem-calc(5);
            left: -17px;
            display: none;
            width: 1px;
            height: rem-calc(15);
            background: $color-primary-brand;
            content: "";
          }
        }
      }

    }

    &--title {
      display: block;
      margin-top: rem-calc(10);
      margin-bottom: rem-calc(10);
      color: $color-text;
      font-family: $font-bold;
      font-size: $font-size-mobile-large;
    }

  
  }

  &__image {
    min-width: rem-calc(90);
    height: rem-calc(120);
    transition: linear;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      height: 100%;
    }
  }
}

// there's some kind of very powerful drilldown class that I can't seem to find. Overriding.
.drilldown .region-selector-block__region-nav--subLink {
  &:hover, &:focus {
    color: $color-gray-3!important;
  }
}
