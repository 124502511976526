$block: "product-description";
$breakpoint-size: "large";

.#{$block} {
  .rte-block {
    padding: rem-calc(14) 0;
  }

  &__detailsContainer {
    padding-left: rem-calc(142);
  }

  &__name {
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    line-height: $line-height-small;
  }

  &__category {
    font-size: map-get(map-get($typography, $breakpoint-size), h4);
    line-height: 2.7;
  }

  .rte-block {
    padding: 0 0 rem-calc(30);

    p, li {
      line-height: $line-height-large;
    }
  }
}
