$block: "countries-selector";
$breakpoint-size: "small";

.#{$block} {
  background: $color-gray-lighter;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    max-width: $global-width;
    margin: rem-calc(40) auto 0;

  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    margin-bottom: rem-calc(20);
    padding: 0 20px;

    img {
      height: rem-calc(30);
      margin-top: rem-calc(20);
      object-fit: contain;
    }

    &__sites-wrapper {
      margin-top: rem-calc(20);
      
      &--sites-link {
        @include underlineLink;
        display: block;
        font-family: $font-regular !important;
        margin-bottom: rem-calc(20);
        background-color: transparent !important;
        font-size: $font-size-smallest-2 !important;
        margin-bottom: rem-calc(15) !important;
        text-align: center;
        text-transform: uppercase;

        &:nth-child(n+2) {
          position: relative;
  
          &:before {
            position: absolute;
            top: 3px;
            left: -13px;
            display: none;
            width: 1px;
            height: rem-calc(10);
            background: $color-primary-brand;
            content: "";
          }
        }
      }
    }
  }

  &__title,
  &__heading {
    display: none;
    text-align: center;
  }

  &__item {
    display: flex;
    padding: rem-calc(8) $space5;
    border-bottom: $border-thinnest solid $color-border;
    font-size: rem-calc(12);
    list-style: none;

    a {
      color: $color-primary-brand;
      text-transform: capitalize;
    }

    img {
      width: rem-calc(40);
      height: rem-calc(28);
      margin-right: rem-calc(12);
      object-fit: contain;
    }
  }

  &__countryCol {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-weight: 600;
    }
  }

  &__langCol {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    min-width: rem-calc(140);
    margin-left: rem-calc(9);

    a:not(:first-child) {
      position: relative;
      margin-left: 15px;

      &:before {
        position: absolute;
        top: 2px;
        left: -8px;
        width: 1px;
        height: 13px;
        background: $color-gray-light;
        content: "";
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
