$block: "region-selector-hero";
$breakpoint-size: "small";

.#{$block} {
  &__rel {
    position: relative;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  //hero content
  &__content {
    padding: 0 $space5;
    @media screen and (min-width: rem-calc(360)) {
      width: 98%;
    }

    .rte-block {
      padding: map-get($grid-column-gutter, $breakpoint-size) 0;
      color: $color-white;

      h1 {
        font-family: $font-bold;
        font-size: rem-calc(36);
        line-height: 1.2;
      }
    }
  }

  // Brand Logo Image
  &__logoImg {
    height: 100%;
    max-height: rem-calc(60);

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: 0;
    }
  }

  // CTA Buttons
  &__cta {
    display: flex;
    flex-direction: column;

    .cta {
      align-self: flex-start;
      margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
    }

    .secondary-cta {
      background: $color-white;
      color: $color-primary-brand;

      &:hover {
        color: rgba(1, 96, 169, 0.80);
      }
    }
  }

  // Tool Tip
  &__toolTip {
    display: flex;
    flex-direction: column;
    position: relative;

    &--text {
      color: $color-white;
      font-size: rem-calc(14);
      cursor: pointer;
      align-self: flex-start;
      margin: 0;

      &:hover,
      &:focus {
        opacity: .8;
      }
    }

    .tool-tip {
      z-index: 3;
      position: absolute;
      top: rem-calc(25);
      visibility: hidden;
      padding-top: rem-calc(10);

      &.js-showToolTip {
        visibility: visible;
        display: block;
      }

      .tool-tip__text {
        width: 95%;
      }

      &__block {
        max-width: rem-calc(310);

        &:before {
          top: rem-calc(-10);
          left: rem-calc(6);
          transform: translate(0);
          border-top: none;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 10px solid white;
          -webkit-filter: drop-shadow(0 -3px 2px rgba(0,0,0,0.25));
          filter: drop-shadow(0 -3px 2px rgba(0,0,0,0.25));
        }
      }

      .rte-block {
        padding: 0;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  // Footer
  &__footer {
    height: rem-calc(80);
    position: relative;
    background: $color-primary-brand;
    display: flex;
    align-items: center;
    padding: $space5;

    p, a {
      font-size: rem-calc(14);
      color: $color-white;
      opacity: .85;
      margin-bottom: 0;
    }

    a:hover {
      opacity: .75;
    }

    // horizontal rule
    &:before {
      content: "";
      width: 100%;
      height: rem-calc(2);
      background: $color-white;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  // overriding
  .hero-image {
    height: rem-calc(683);

    img,
    picture {
      max-height: none;
      height: 100%;
      object-position: center;
    }
    img {
      object-fit: cover;
    }
  }
}