$block: "hero-image";
$breakpoint-size: "large";

.#{$block} {
  &:before {
    background: linear-gradient(90deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.2) 100%);
  }

  picture, img {
    object-fit: cover;
  }
}
