$block: "careers-open-positions";
$breakpoint-size: "small";

.#{$block} {

  &__content {
    max-width: rem-calc(875);
    padding: map-get($grid-column-gutter, $breakpoint-size);
    margin: 0 auto;
  }

  &__form-frame {
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
  }

  iframe {
    width: 100%;
  }
}