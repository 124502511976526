$block: "quote-block";
$breakpoint-size: "large";

.#{$block} {
  padding: 0;
  width: rem-calc(875);
  margin: auto;

  &__wrapper {
    padding: rem-calc(74 72);
  }

  &__text {
    padding-left: $space-mid;

    &--quote {
      font-size: map-get(map-get($typography, $breakpoint-size), h3);
    }
  }

  ///////////////////////////////
  // MODIFIER: Testimonial Quote
  &--testimonial {
    width: 100%;
    max-width: inherit;

    .#{$block} {
      &__wrapper {
        max-width: $global-width;
        margin: auto;
        padding: rem-calc(67 60);
      }

      &__text {
        max-width: rem-calc(1080);
        padding: 0 rem-calc(74);
        margin: auto;

        &--quote {
          line-height: 2;
        }

        //quotes icons
        &:before,
        &:after {
          top: rem-calc(14);
          font-size: rem-calc(48);
        }

        &:before {
          left: 0;
        }

        &:after {
          right: rem-calc(-44);
        }
      }

      &__cta {
        margin-top: rem-calc(85);
      }
    }
  }
}