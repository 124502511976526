$block: "pdp";
$breakpoint-size: "small";

.#{$block} {
  &__content {
    background-color: $color-gray-lightest;
	padding: 0 0 2px;
  }

  &-section {

    &__name {
      width: 100%;
	  margin-bottom: 0;
      padding: map-get($grid-column-gutter, $breakpoint-size);
      background-color: $color-black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color-white;
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      font-family: $font-bold;
      text-transform: uppercase;
	  border-top: 1px solid $color-gray-3;
    }

    &__wrap {
      display: none;
      background-color: $color-white;
    }

    &__content {
      padding: map-get($grid-column-gutter, $breakpoint-size);
    }

    &__arrow-icon {
      font-size: rem-calc(6);

      &:before {
        display: inline-block;
      }
    }
  }

  .pdp-section.is-active {

    .pdp-section__arrow-icon {
      &:before {
        transform: rotate(180deg);
      }
    }

    .pdp-section__wrap {
      display: block;
    }
  }
}
