$block: "search-box";
$breakpoint-size: "small";

.#{$block} {
  position: relative;

  form {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__input {
    height: rem-calc(45); // Set the height to 45px to match the height of the select element in the form.
    margin: 0;
    padding: $space4 $space-mid $space4 $space4;
    border-radius: $border-radius;
    border: $border-thin solid transparent;
    background: $color-gray-lighter;
    font-size: $font-size-smaller;
    font-family: $font-regular;
    line-height: $line-height-small;
    color: $color-text;

    @include placeholder {
      color: $color-gray-2;
    }
  }

  &__label {
    font-size: 0;
  }

  &__submit {
    position: absolute;
    right: $space3;
    color: $color-black;
    font-size: $font-size-mobile-large;
    cursor: pointer;
  }
}
