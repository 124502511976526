$block: "contact-form";
$breakpoint-size: "large";

.#{$block} {
  &__rte {
    text-align: center;

    h2 {
      font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    }

    p {
      font-size: $font-size-smaller;
    }
  }

  &__group {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;

    .form-elem {
      flex-basis: 48%;

      &--single {
        flex-basis: 100%;
      }
    }
  }

  .form-elem,
  &__submit {
    padding-left: 0;
    padding-right: 0;
  }

  &__submit {
    margin: auto;
    max-width: rem-calc(225);
  }
}