$block: "cookiefirst";
$breakpoint-size: "small";

.#{$block}-root {

    div[data-cookiefirst-widget="banner"] {
        width: 100% !important;
        margin: auto !important;
        border-radius: 0 !important;
        padding: rem-calc(20) rem-calc(40) rem-calc(30);
        background-color: $color-primary-brand !important;
        color: $color-white !important;

            > div {
                display: flex;
                flex-direction: column;

                span:first-child { //hide cookie first logo
                    margin-inline-end: 0 !important;

                    > a {
                        display: none;
                    }
                }

                div {//target banner title
                    &:first-child {
                        div {
                            font-size: rem-calc(18) !important;
                            line-height: 21px;
                        }
                        
                    }
                }
            }

            p,
            p > a,
            p > a strong {
                color: $color-white !important;
                font-size: rem-calc(14) !important;
                line-height: 19px;
            }

            button[data-cookiefirst-action="accept"] {
                --banner-font-family: $font-bold;
                background-color: $color-brand-accent-1 !important;
                border-color: $color-brand-accent-1 !important;
                color: $color-black !important;
                font-family: $font-bold !important;
                text-transform: uppercase;
                min-width: 64px !important;
                height: 44px;
                font-size: rem-calc(13) !important;
                margin-top: 10px;

                span {
                    font-size: rem-calc(13) !important;
                    color: $color-black !important;
                }

                &:hover, &:focus {
                    background-color: transparent !important;
                    border-color: $color-white !important;

                    span {
                        color: $color-white !important;
                    }
                }
            }

            button[data-cookiefirst-action="save"],
            button[data-cookiefirst-action="reject"],
            button[data-cookiefirst-action="adjust"] {
                --banner-font-family: $font-bold;
                background-color: $color-primary-brand !important;
                border-color: $color-white !important;
                color: $color-white !important;
                font-family: $font-bold !important;
                text-transform: uppercase;
                min-width: 64px !important;
                height: 44px;
                font-size: rem-calc(13) !important;
                margin-top: 10px;

                span {
                    font-size: rem-calc(13) !important;
                    color: $color-white !important;
                }

                &:hover, &:focus {
                    background-color: $color-brand-accent-1 !important;
                    border-color: $color-brand-accent-1 !important;

                    span {
                        color: $color-black !important;
                    }
                }
            }

            a[data-cookiefirst-accent-color]{
                color: $color-white !important;
            }

            button[data-cookiefirst-button="secondary"] {
                border-color: $color-white !important;
            }

            button[role="checkbox"] {
                position: relative !important;
                display: block;
                padding: rem-calc(15);
                background-color: $color-primary-brand;
                margin-top: 0;
                width: 64px;
                max-width: 64px;
                height: 26px !important;
                padding: 0 !important;

                &::before {
                    position: relative !important;
                    top: 0 !important;
                    left: 0 !important;
                    display: inline-block !important;
                    width: 64px !important;
                    height: 26px !important;
                    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%) !important;
                    content: '';
                    z-index: 1;
                    pointer-events: none;
                }

                &::after {
                    position: absolute !important;
                    top: 3px !important;
                    width: 21px !important;
                    height: 21px !important;
                    transform: translateX(0%);
                    transition: none;
                    opacity: 1 !important;
                    content: '';
                    z-index: 1;
                    pointer-events: none;
                }

                &[aria-checked="true"] {
                    &::after {
                        right: 3px !important;
                        left: unset !important;
                        background: $color-primary-brand-dark url(#{$filepath-img}/icons/check-solid.svg) no-repeat 4px 4px / 12px !important;
                    }
                }

                &[aria-checked="false"] {
                    &::after {
                        right: unset !important;
                        left: 3px !important;
                        background: $color-gray-light url(#{$filepath-img}/icons/times-solid.svg) no-repeat 5.5px 4px / 9px !important;

                    }
                }
            }
    }

    // Modal
    div[data-cookiefirst-widget="modal"] {
        --banner-bg-color: $color-gray-lighter !important;
        --banner-text-color: $color-black !important;
        --banner-border-radius: 0 !important;
        background-color: $color-gray-lighter !important;
        color: $color-black !important;
        border-radius: 0 !important;
        font-size: rem-calc(14) !important;

        > div > div > div:first-child { //target privacy policy link
            a {
                font-size: rem-calc(14) !important;
                color: $color-gray !important;
                line-height: 16px;
                text-decoration: underline;
                top: rem-calc(-25);
                right: 2rem;
                position: relative;

                &:hover {
                    color: $color-primary-brand !important;
                }
            }
        }

        > div > div > div {//target first row with title and privacy policy link
            margin-top: 10px;
        }
        
        > div > div { //target wrapper element to set outer padding
            padding: 35px 20px;
        }

        > div > div {
            > div:last-of-type { //target last div
                span {
                    font-size: rem-calc(13) !important;
                    color: $color-gray-3 !important;
                }
            }

            > button:last-of-type { //target close button
                height: 2rem;
                width: 2rem;
                top: rem-calc(15);
                right: 1rem;

                span {
                    width: 100%;
                    height: 100%;
                    right: 0 !important;

                    &:after,
                    &:before {
                        top: 5px;
                        left: 20px;
                        font-size: rem-calc(21) !important;
                        color: $color-gray-3 !important;
                    }
                }
            }
        }

        strong, 
        p,
        div[role="tabpanel"] > div,
        div[role="tabpanel"] div p span  {
            font-size: rem-calc(14) !important;
            color: $color-gray-3 !important;
        }

        div[role="tabpanel"] div a {
            font-size: rem-calc(12) !important;
            color: $color-gray !important;
            line-height: 16px;
            text-decoration: underline;
            top: unset;
            right: unset;
            position: unset;
        }

        strong {
            font-family: $font-bold !important;
        }

        h2 {
            padding: 20px 0 10px 0 !important;
        }

        h3 {
            padding: 15px 0 10px 0 !important;
        }

        h4 {
            font-family: $font-bold !important;
            padding: 20px 0 0 0 !important;
        }

        div[role="tabpanel"] span {
            --banner-font-size: rem-calc(18) !important;
            line-height: 21px !important;
            font-size: rem-calc(18) !important;
            color: $color-text !important;
        }

        button[role="tab"] {
            --banner-font-size: rem-calc(13) !important;
            line-height: 15px !important;
            font-size: rem-calc(13) !important;
            padding-bottom: 10px !important;
            font-family: $font-bold !important;
            color: $color-gray !important;
        }

        button[aria-selected=true] {
            color: $color-primary-brand !important;
            border-bottom: 5px solid $color-primary-brand;

            &:after {
                height: 4px;
            }
        }

        button {
            text-transform: uppercase !important;
        }

        div[data-cookiefirst-accent-color] {
            --banner-accent-color: $color-gray-light !important;
            border: 0 !important;
        }

        button[role="checkbox"] {
            position: relative;
            display: block;
            padding: rem-calc(15);
            border: 1px solid $color-black !important;
            background-color: $color-primary-brand;
            margin-top: 0;
            margin-left: rem-calc(20);
            width: 64px !important;
            max-width: 64px !important;
            height: 26px !important;
            padding: 0 !important;

            &::before {
                position: relative !important;
                top: 0 !important;
                left: 0 !important;
                display: inline-block !important;
                width: 64px !important;
                height: 26px !important;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%) !important;
                content: '';
                z-index: 1;
                pointer-events: none;
            }

            &::after {
                position: absolute !important;
                top: 2px !important;
                width: 20px !important;
                height: 20px !important;
                transform: translateX(0%);
                transition: none;
                opacity: 1 !important;
                content: '';
                z-index: 1;
                pointer-events: none;
            }

            &[aria-checked="true"] {
                &::after {
                    right: 2px !important;
                    left: unset !important;
                    background: $color-primary-brand-dark url(#{$filepath-img}/icons/check-solid.svg) no-repeat 4px 4px / 12px !important;
                }
            }

            &[aria-checked="false"] {
                &::after {
                    right: unset !important;
                    left: 2px !important;
                    background: $color-gray-light url(#{$filepath-img}/icons/times-solid.svg) no-repeat 5.5px 4px / 9px !important;

                }
            }
        }
        
        button[data-cookiefirst-action="save"],
        button[data-cookiefirst-action="reject"] {
            --banner-font-size: rem-calc(13) !important;
            line-height: 13px !important;
            font-size: rem-calc(15) !important;
            min-height: 44px;
            border-color: $color-primary-brand !important;
            font-family: $font-bold !important;

            &:hover, &:focus {
                background-color: $color-brand-accent-1 !important;
                border-color: $color-brand-accent-1 !important;
                min-height: 44px;

                span {
                    color: $color-black !important;
                }
            }
        }

        button[data-cookiefirst-action="accept"] {
            --banner-font-size: rem-calc(13) !important;
            line-height: 13px !important;
            font-size: rem-calc(15) !important;
            min-height: 44px;
            background-color: $color-brand-accent-1 !important;
            border-color: $color-brand-accent-1 !important;
            color: $color-black !important;
            font-family: $font-bold !important;

            &:hover, &:focus {
                background-color: $color-primary-brand !important;
                border-color: $color-primary-brand !important;
                min-height: 44px;

                span {
                    color: $color-white !important;
                }
            }
        }

        #cookie-preference-panel-title {
            font-size: rem-calc(21) !important;
            line-height: 24px;
        }
    }

}

@media (min-width: 992px) { //overwrites cookie first breakpoint, keep stacked mobile styles
    .#{$block}-root {

        div[data-cookiefirst-widget="banner"] {
            width: 100% !important;
            margin: auto !important;

                > div {
                    display: flex !important;
                    flex-direction: column !important;

                    > div {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                    }
                }
        }
    }
}

@media (min-width: 1081px) { //overwrites cookie first breakpoint, starts desktop layout
    .#{$block}-root {

        div[data-cookiefirst-widget="banner"] > div {
                display: flex !important;
                flex-direction: row !important;

                div {
                    &:first-child {
                        flex: 0 0 45% !important;

                        div {
                            flex: 0 0 100%!important;
                            flex-basis: 0!important;
                            flex-grow: 1!important;
                            max-width: 100% !important;
                        }
                    }

                    &:last-child {
                        flex: 0 0 55% !important;
                        max-width: 55% !important;

                        div {
                            flex: 0 0 100%!important;
                            flex-basis: 0!important;
                            flex-grow: 1!important;
                            max-width: 100% !important;
                        }
                    }
                }
        }
    }
}
