$block: "product-by-brands";
$breakpoint-size: "large";

.#{$block} {

    &__wrapper {
        display: flex;
        justify-content: center;
       // flex-wrap: nowrap;

    }

    &__product-list {
        flex-basis: 25%;
        align-items: center;
        width: fit-content;
    }

    h4 {
        font-size: map-get(map-get($typography, $breakpoint-size), h4);
        width: 52%;
        align-self: center;
    }

    ul {
        max-width: 183px;
        min-width: 182px;
    }

}