$block: "rotating-carousel-block";
$breakpoint-size: "small";

.#{$block} {

  .overview-text__description {
    padding-bottom: $space;

  }

  &__cta {
    text-align: center;
  }
}
