$block: "content-overlay-block";
$breakpoint-size: "medium";

.#{$block} {}

// is hero
.#{$block} .content-block,
.content-block--hero {

  width: 100%;

  .content-block__wrapper {
    position: relative;
    display: flex;
    max-width: $global-width;
    margin: 0 auto;
    width: 100%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
      min-height: 600px;
      align-items: center;
    }

  }

  .content-block__content {
    max-width: 66.66%;
    padding-left: $space;
    padding-right: $space;
  }
}

.content-block--hero {
  min-height: $hero-height;
  margin: 0;
  justify-content: center;
  padding: $space-larger 0;

  + .hero-image {
    max-height: none;
    &:before {
      background: rgba(0,0,0,0.3);
    }
  }
}
