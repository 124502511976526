$block: "two-col-text";
$breakpoint-size: "medium";

.#{$block} {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h2);
  }

  &__subtitle {
    font-size: map-get(map-get($typography, $breakpoint-size), p);
  }

  .title-text-list-item {
    margin: 0;
    flex-basis: 45%;
  }

  .component__text {
    padding-left: 0;
    padding-right: 0;
  }
}
