$block: "facet-sort";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  flex-direction: column;
  margin-top: $space4;

  #sortBy {
    background-color: $color-gray-lightest;
  }

  &.search {

    .#{$block}__filter {
        flex-basis: initial;
        width: 100%;
      }

      .#{$block}__sort-by {
        justify-content: space-between;
      }
  }

  &.blog {
    .#{$block}__sort-by {
      justify-content: space-between;
    }
  }

  &__label {
    font-size: 0;
  }

  &__sort-by {
    display: flex;
    justify-content: space-between;
    order: 1;
    width: 100%;
    margin-bottom: 0;

    .icon-list-view, .icon-grid-view {
      display: none;

    }

    div {
      display: flex;
      align-items: center;
    }



    select {
      height: rem-calc(45);
      margin-bottom: 0;
      padding-left: rem-calc(15);
      border: 0.5px solid $color-gray-light;
      border-radius: 6px;
      font-family: $font-medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
    }
  }

  &__sort-by__item-position {
    font-size: 14px;
    font-weight: bold;

  }

  &__search {
    display: flex;
    width: 100%;
    padding: $space5 0;
  }

  &__filter {
    background-color: $color-gray-lightest;
  }

  input[readonly] {
    background: $color-white !important;
  }

  input {
    background: $color-white;
  }

  .search-box__input {
    margin-bottom: 0;
    border: 0.5px solid $color-gray-light;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  &__filter-cta {
    margin-top: rem-calc(15);

    .cta {
      position: relative;
      width: 100%;
      height: rem-calc(45);
      background-color: $color-white;

      .icon {
        position: relative;

        &::before {
          position: absolute;
          top: 1px;
          right: 8px;
        }
      }
    }


  }

  &--facets-as-filters {

    select {
      margin-left: map-get($grid-column-gutter, $breakpoint-size);
      background-color: #F3f3f3;
    }

    hr {
      display: none;
    }

    .#{$block} {
      &__select {
        display: none;
      }

      &__sort-by {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .search-box {
          order: 2;
          margin-top: map-get($grid-column-gutter, $breakpoint-size);
        }
      }

      &__select--date {
        label {
          font-size: 0;
        }
      }

      &__filter-cta {
        order: 1;
      }
    }
  }

  &__q {
    position: absolute;
    top: rem-calc(10);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 75%;
    height: rem-calc(30);
    margin-left: rem-calc(8);
    padding-left: rem-calc(10);
    border-radius: 6px;
    background-color: rgba(1, 96, 169, 0.12);
    text-overflow: ellipsis;
    overflow: hidden;

    p {
      display: block;
      max-height: rem-calc(30);
      margin-bottom: 0;
      color: $color-text;
      font-size: 14px;
      line-height: $line-height-large;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .search-box__remove {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: rem-calc(18);
      height: rem-calc(18);
      margin-right: rem-calc(6);
      margin-left: rem-calc(11);
      border-radius: 3px;
      background-color: $color-primary-brand;
      color: $color-white;
      cursor: pointer;

      .icon-close-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3px !important;
      }
    }
  }

  &__reset-all {
    justify-content: center;
    order: 3;
    padding-top: rem-calc(16);
    padding-bottom: rem-calc(2);
    color: #0160A9;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
  }

  &--mobile-panel {
    select {
      margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
      margin-left: 0;
    }

    hr {
      width: 100%;
      margin-top: rem-calc(10);
      margin-bottom: rem-calc(10);
    }

    .#{$block} {
      &__select {
        display: flex;
      }

      &__sort-by {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;

        .search-box {
          display: none;
        }
      }

      &__filter-cta {
        display: none;
      }

      &__reset-all {
        display: none;
      }
    }
  }



}
