$block: "image-block";
$breakpoint-size: "small";

.#{$block} {
  height: 100%;

  &__picture {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  picture {
    height: 100%;
  }

  &__label {
    opacity: 0.8;
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: rem-calc(7 20);
  }
}