$block: "slick-slider";
$breakpoint-size: "small";

.#{$block} {

  padding-bottom: $space-mid;
  margin-bottom: 0;

  .slick-dots {

    bottom: 0;

    button {
      @include transition;
      background: $color-white;
      border: 1px solid $color-body-text;
      border-radius: rem-calc(50%);
      width: rem-calc(10);
      height: rem-calc(10);

      &:before {
        content: "";
      }

      &:hover, &:focus  {
        background: $color-brand-accent-1;
        border: 1px solid $color-brand-accent-1;
      }
    }

    .slick-active button {
      background: $color-text;
      border: 1px solid $color-text;
      cursor: default;
    }
  }

  .slick-slide:focus {
    outline: none;
  }
}
