$block: "nav-tab-panel";
$breakpoint-size: "medium";

.#{$block} {

  &__tab-title {
    margin: map-get($grid-column-gutter, $breakpoint-size)/2 0 !important;
  }

  &__sub-type {
    margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
    padding-right: map-get($grid-column-gutter, $breakpoint-size)/2;
  }

  &__sub-type-title {
    font-size: map-get(map-get($typography, $breakpoint-size), p-large);
  }

  &__sub-type-product {
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
  }

  &__flat-wrapper {
    padding: map-get($grid-column-gutter, $breakpoint-size) !important;
    margin: $space5 map-get($grid-column-gutter, $breakpoint-size) !important;
    width: calc(100% - #{map-get($grid-column-gutter, $breakpoint-size)*4});
  }

  &__tabs-title {
    margin: map-get($grid-column-gutter, $breakpoint-size)/2 0 !important;
    padding: map-get($grid-column-gutter, $breakpoint-size);
    padding-top: map-get($grid-column-gutter, $breakpoint-size)/2;

    a {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }
  }
}