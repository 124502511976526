$block: "facet-sort";
$breakpoint-size: "large";

.#{$block} {
  background: inherit;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: $space4;
  padding: $space3;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cacaca;
  background-color: $color-white;

  #sortBy {
    background-color: $color-white;
    min-width: 100px;
  }

  &__sort-by {
    order: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__filter {
    padding-right: 0;
    order: 2;
    flex-basis: 40%;
    background-color: $color-white;

  }

  &__search {
    flex-basis: 60%;
    padding: 0;
    margin-bottom: 0px !important;
    margin-bottom: map-get($grid-column-gutter, $breakpoint-size)/2;
    order: 1;
    background: none !important;
  }

  &__filter-cta {
    display: none !important;
  }

  &__sort-by__item-position {
    display: flex !important;
    margin-right: rem-calc(20);
  }

  &--facets-as-filters {

    .#{$block} {
      &__select {
        display: flex;
        max-width: rem-calc(200);
        flex-grow: 2;

        select {
          margin-left: rem-calc(20);
        }

        &--date {
          max-width: rem-calc(155);
        }
      }

      input[read-only] {
        background: $color-white;
      }




      &__sort-by {
        display: flex;
        flex-direction: row;

        .search-box {
          order: 0;
          margin-top: 0;
        }
      }

      &__filter-cta {
        order: 2;
      }
    }
  }

  &__reset-all {
    padding-bottom: 0;
    padding-top: 0;
    max-width: rem-calc(180);
    flex-grow: 2;
  }

  &--mobile-panel {
    display: none;
  }

  &__grid-list-icons {
    padding-right: $space;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: rem-calc(45);
      width: rem-calc(45);
      transform: scaleY(-1);
      border-radius: 6px;
      color: #D8D8D8; // From Comps
      cursor: pointer;

      &.active {
        border: 0.5px solid $color-gray;
        color: $color-black;
      }

      &:before {
        font-size:20px;
      }
    }
  }


}
