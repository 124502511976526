$block: "sticky-nav";
$breakpoint-size: "large";

.#{$block} {
  display: block !important;
  // position: relative !important;
  background-color: $color-black !important;
  width: 100%;

  .top-bar {
    padding: 0;
    max-width: $global-width-pdp-comp;
    margin: 0 auto;
    width: 100%;
  }

  &--stuck {
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 3; //needs to go over heros
  }

  .utility-nav {
    background-color: $color-black !important;
    width: 100%;
  }
}
