$block: "featured-items";
$breakpoint-size: "medium";

.#{$block} {
  margin: rem-calc(30) (-$space2) 0;
  padding: rem-calc(30) map-get($grid-column-gutter, $breakpoint-size) $space-larger;

  &__cards {
    .featured-card {
      margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
    }
  }
}
