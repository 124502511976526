$block: "modal";
$breakpoint-size: "small";

.#{$block} {

  &__reveal {
    width: 100%;
    margin: auto;
  }

  &__video {
    margin-top: map-get($grid-column-gutter, $breakpoint-size)*2 !important;
  }

  &__accept-cookie-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: rem-calc(20);
    background: rgba($color-black, .6);
    color: $color-white;
    font-size: rem-calc(22);
    font-weight: bold;
    line-height: $line-height-small;
    text-align: center;
    cursor: pointer;

    &--show {
      display: block;
    }
  }
}