$block: "error-404page";
$breakpoint-size: "large";

.#{$block} {
  .hero-content__text {
    width: rem-calc(640);
  }

  .hero-content__wrapper {
    padding-left: $space-larger;

    .cta {
      width: rem-calc(305);
    }
  }

 .hero-content__text--header {
   font-size: rem-calc(42);
   line-height: 1.43;
 }

  .hero-content__text--description {
    line-height: $line-height-large;
  }

}