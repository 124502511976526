$block: "split-promo-with-media";
$breakpoint-size: "medium";

.#{$block} {
	display: flex;
	align-items: stretch;
	justify-content: space-between;

	&__text-wrapper,
	&__video-wrapper {
		flex: 0 0 percentage(1/2);
	}

	&__text-wrapper {
		max-width: 600px;
		margin: 0 auto;
		padding-top: rem-calc(42);
		padding-bottom: rem-calc(42);
	}

	&__smaller-title {
		font-size: $font-size-standard;
		line-height: 20px;
	}

	&__title {
		max-width: rem-calc(523);
		font-size: $font-size-xlarger;
		line-height: 47px;
	}

	&__desc {
		max-width: rem-calc(552);
		font-size: $font-size-small;
	}
}
