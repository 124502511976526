$block: "cta";
$breakpoint-size: "small";

.#{$block} {
  @include transition;
  cursor: pointer;
  display: inline-block;
  font-family: $font-bold;
  font-weight: 300;
  font-size: map-get(map-get($typography, $breakpoint-size), button);
  line-height: 1.2;
  padding: rem-calc(14 46);
  border-radius: $border-radius;
  border: 1px solid $color-primary-brand;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-link;

  &:hover, &:focus {
    color: $color-text;
    border-color: $color-brand-accent-1;
    background-color: $color-brand-accent-1;
  }
}
