$block: "product-listing-page";
$breakpoint-size: "small";

.#{$block} {
	background: $color-gray-lightest;

  &__content {
    position: relative;
  }

  &__content__teaser {
		max-width: $global-width - (map-get($grid-column-gutter, $breakpoint-size) * 2);
		margin: 0 auto $space5;
		background: $color-white;
		padding: $space5 map-get($grid-column-gutter, $breakpoint-size);

		* {
			font-family: $font-regular!important; //overriding applied inline styles
		}

		h2, h3, h4, h5, h2 span, h3 span, h4 span, h5 span {
			font-family: $font-bold!important; //overriding applied inline styles
			font-weight: 600;
			margin: $space-mid 0 $space4;
		}

		ul, ol {
			margin-left: $space;
			margin-bottom: $space;
		}

		li {
			margin-bottom: $space2;
			font-size: $font-size-smaller;
			color: $color-gray-3;
		}
  }

  .featured-card {
    max-width: none;
    height: rem-calc(400);
    margin: rem-calc(10) 0;
    padding: 0;

    &__image {
      display: flex;
      justify-content: center;
      width: rem-calc(150) !important;
      max-width: 100%;
      height: rem-calc(150) !important;

      picture, img {
        width: auto;
        max-width: rem-calc(150);
      }
    }

    &__text {
      max-height: rem-calc(200);
      overflow: hidden;
    }

    &__text--description {
      overflow: hidden;

      p {
        font-size: rem-calc(11) !important;
        opacity: .75;
        overflow: hidden;
      }
    }

    .featured-card--horizontally {
      max-width: $global-width-mid;
    }

    .featured-card--clickable {
      cursor: pointer;
    }

    .rte-block {
      padding: 0 !important;
    }
  }
}
