$block: "newsroom-block";
$breakpoint-size: "medium";

.#{$block} {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc($global-width-pdp-comp + $grid-gutter-width);
  padding: 0;

  &.component {
    padding-top: rem-calc(53);
    padding-bottom: rem-calc(65);
  }

  .overview-text {
    width: 100%;
  }

  &__articles {
    $articles-space: rem-calc(16);

    display: flex;
    width: 50%;
    flex-flow: column nowrap;
    justify-content: flex-start;

    &:nth-child(2) {
      padding-right: $articles-space;
    }

    &:nth-child(3) {
      padding-left: $articles-space;
    }
  }

  .post-card {
    flex: 0 1 auto;
    margin-bottom: rem-calc(20);
    padding-bottom: 0;
    border-bottom: 0;

    &__content {
      &--cardTag {
        margin-bottom: rem-calc(7);
      }

      &--description {
        margin-bottom: rem-calc(12);
      }
    }

    &__image {
      width: 166px;
      height: 166px;
      border: 1px solid #ccc;
    }


    &--featured {
      margin-bottom: 0;

      .post-card__image {
        width: 100%;
        height: 0;
      }
    }
  }

  &__header {
    margin-bottom: rem-calc(54);
  }

  &__view-all-desktop {
    display: block;
  }

  &__view-all-mobile {
    display: none;
  }

  &__cta {
    margin: auto;
  }
}
