$block: "stats-card";
$breakpoint-size: "large";

.#{$block} {
  padding: 0 map-get($grid-column-gutter, $breakpoint-size);
  max-width: $global-width-pdp-comp;
  margin: 0 auto;

  &__wrapper {
    padding: $space-larger $space-mid $space-largest;
  }

  &__textPrimary {
    font-size: rem-calc(60);
  }

  &__textSecondary {
    font-size: map-get(map-get($typography, $breakpoint-size), h4);
  }

  &__item {
    flex: 0 0 23%;
    padding: 0 3%;
    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // IE 11 Flexbox Fix

     & {  
       flex: 0 0 20%; } /* IE11 */

     }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
