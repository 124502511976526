$block: "our-brands";
$breakpoint-size: "small";

.#{$block} {
	$btn-wrapper-border: rgba($color-gray-3, .25);

	padding: rem-calc(35) 0 rem-calc(25);
	background-color: $color-gray-lighter;

	&__content {
		display: block;
		max-width: rem-calc(1440);
		margin-right: auto;
		margin-left: auto;
		padding-right: rem-calc(20);
		padding-left: rem-calc(20);
	}

	&__btn-wrapper,
	&__logo-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__btn-wrapper {
		flex-wrap: nowrap;
		margin-bottom: rem-calc(27);
		padding-bottom: rem-calc(4);
		border-bottom: 1px solid $btn-wrapper-border;
	}

	&__filter-btn {
		flex: 0 0 percentage(1/4);
		padding: rem-calc(9) rem-calc(6);
		transition: color $transition-med, border $transition-med;
		color: $color-gray;
		font-family: $font-bold;
		font-size: $font-size-mobile-xsmallest;
		line-height: 1.2;
		text-align: center;
		text-transform: uppercase;
		cursor: pointer;

		&:hover,
		&.active {
			color: $color-black;
		}

		&.active {
			border-color: $color-primary-brand;
		}
	}

	&__logo-wrapper {
		justify-content: center;
	}

	&__logo-link {
		display: flex;
		flex: 0 0 percentage(1/3);
		align-items: center;
		justify-content: center;
		margin-bottom: rem-calc(30);
		transition: filter $transition-med;

		img {
			max-height: rem-calc(25);
			transition: opacity $transition-med;
			opacity: 1;
		}

		&--deselected {
			filter: grayscale(100%);

			img {
				opacity: .7;
			}
		}

		&:hover {
			filter: grayscale(0%);

			img {
				opacity: 1;
			}
		}
	}
}
