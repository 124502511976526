//////
// Filepaths
//////
$filepath-img: '../images/';
$filepath-fonts: '../fonts';
$filepath-font-icons: '../fonts/icons/';

//////
// Opacity
/////
$opacity1: 0.5;
$opacity2: 0.7;


//////
// Colors
//////
$color-primary-brand-light: #c7e7ff;
$color-primary-brand-lighter-2: #0884e3;
$color-primary-brand-lighter-1: #a7d9ff;
$color-primary-brand-lighter: #0061aa; //chevrons : Columbus McKinnon page
$color-primary-brand: #0160A9;
$color-primary-brand-dark: #00468a;
$color-primary-brand-darker: #00284f;
$color-primary-brand-darkest: #00162b;
$color-primary-brand-gray: #3c525e;

// Accent Colors
$color-brand-accent-1: #ff9500; // chevrons : Stahl page

// Neutrals
$color-white: #fff;
$color-gray-lightest: #f6f6f6;
$color-gray-lighter: #f3f3f3;
$color-gray-lighter-2: #eaeaea;
$color-gray-light: #a5a5a5;
$color-gray-light-2: #979797;
$color-gray: #767676;
$color-gray-5: #ccc;
$color-gray-4: #d8d8d8;
$color-gray-2: #545759;
$color-gray-3: #40525d;
$color-black: #000;
$color-yellow: #ff9e15;
$color-orange: #bd5800;

////// TODO: REPLACE/CHECK THESE
// Misc Colors
$color-error: #ff2633;
$color-success: #00a04a;
$color-selects: #dc323d;
$color-note: #d0021b;

// Color Roles

$color-background-light: $color-gray-lighter;
$color-background-lighter: $color-background-light;
$color-text-note: $color-gray-light;
$color-border: #eee;
$color-border-input: $color-gray;
$color-placeholder: $color-gray-2;
$color-body-text: $color-gray-3;
$color-body-text-2: $color-gray-3;
$color-secondary-text: #40515c;
$color-link-active: $color-primary-brand;
$color-link: $color-link-active;
$color-btn-link: $color-primary-brand-dark;
$color-background-dark: $color-primary-brand-darkest;
$color-text: #0d0d0d;
$color-text-lighter: #0a0a0a;
$color-stroke: rgba($color-black, .25);

// Social Media Brand Colors
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-vimeo: #1ab7ea;
$color-linkedin: #0077b5;
$color-pinterest: #bd081c;
$color-instagram: #405de6;
$color-youtube: #cd201f;
$color-rss: #f26522;
$color-blog: $color-primary-brand;

//////
// Typography
//////

// Font Families
// See Mixins file to call font styles properly
$font-fallbacks: Helvetica, sans-serif;
$font-regular: 'robotoregular', $font-fallbacks;
$font-italic: 'robotoitalic', $font-fallbacks;
$font-bold: 'robotobold', $font-fallbacks;
$font-light: 'robotolight', $font-fallbacks;
//$font-medium: 'robotobold', $font-fallbacks;
$font-medium: 'robotomedium', $font-fallbacks; // this renders very poorly. commenting out for now.
$font-black: 'robotoblack', $font-fallbacks;

// Line Heights
$line-height-large: 1.75;
$line-height: 1.6;
$line-height-small: 1.35;

// Font Sizes
$font-size-base: 16px;

// Handy mobile sizes
$font-size-mobile-standard: rem-calc(15);
$font-size-mobile-small: rem-calc(13);
$font-size-mobile-smallest: rem-calc(12);
$font-size-mobile-xsmallest: rem-calc(11);
$font-size-mobile-large: rem-calc(18);
$font-size-mobile-largest: rem-calc(24);

// Typical desktop / tablet sizes
$font-size-standard: rem-calc(18);

$font-size-small: rem-calc(16);
$font-size-smaller: rem-calc(14);
$font-size-smallest: rem-calc(12);
$font-size-smallest-2: rem-calc(10);

$font-size-medium: rem-calc(20);
$font-size-medium-2: rem-calc(21);
$font-size-medium-3: rem-calc(22);

$font-size-large: rem-calc(24);
$font-size-larger: rem-calc(28);
$font-size-xlarger: rem-calc(32);
$font-size-largest: rem-calc(36);

////////
// Headings (Consider these "RTE sizes". These may need to be adjusted to be component specific and as more comps are finalized...)
////////
$font-size-headings: ("small": ("h1": $font-size-mobile-largest,
    "h2": rem-calc(21),
    "h3": rem-calc(18),
    "h4": rem-calc(15),
    "h5": rem-calc(13),
    "h6": rem-calc(13)), "medium": ("h1": $font-size-largest,
    "h2": $font-size-larger,
    "h3": $font-size-standard,
    "h4": $font-size-small,
    "h5": $font-size-smaller,
    "h6": $font-size-smaller), "large": ("h1": rem-calc(44),
    "h2": $font-size-larger,
    "h3": $font-size-large,
    "h4": $font-size-standard,
    "h5": $font-size-small,
    "h6": $font-size-small));


$typography: (small: (h1: rem-calc(36),
    h2: rem-calc(21),
    h2-alt: rem-calc(24),
    h3: rem-calc(18),
    h4: rem-calc(16),
    p: rem-calc(14),
    p-large: rem-calc(18),
    p-small: rem-calc(12),
    label: rem-calc(12),
    input: rem-calc(14),
    table: rem-calc(13),
    button: rem-calc(13),
    nav-label: rem-calc(10)),
  medium: (h1: rem-calc(36),
    h2: rem-calc(28),
    h2-alt: rem-calc(28),
    h3: rem-calc(24),
    h4: rem-calc(16),
    p: rem-calc(16),
    p-large: rem-calc(18),
    p-small: rem-calc(14),
    label: rem-calc(16),
    table: rem-calc(15),
    button: rem-calc(13),
    nav-label: rem-calc(10)),
  large: (h1: rem-calc(58),
    h2: rem-calc(28),
    h2-alt: rem-calc(36),
    h3: rem-calc(24),
    h4: rem-calc(18),
    p: rem-calc(18),
    p-large: rem-calc(24),
    p-small: rem-calc(16),
    p-smaller: rem-calc(16),
    label: rem-calc(16),
    table: rem-calc(15),
    button: rem-calc(13),
    nav-label: rem-calc(10)));

//////
// Spacing
//////

// All spacing is relative to the base spacing unit
$space-base-unit: rem-calc(4);

$space-smallest: $space-base-unit; // 4px -- Not used often, so we don't want to label this as "space"
$space2: $space-base-unit * 2; // 8px -- These smaller spaces are easy to calculate by thinking of multiplying the base unit.
$space3: $space-base-unit * 3; // 12px -- ""
$space4: $space-base-unit * 4; // 16px -- ""
$space5: $space-base-unit * 5; // 20px -- ""
$space: $space-base-unit * 6; // 24px -- Consider this the "standard" space. Hence the name.
$space-mid: $space-base-unit * 8; // 32px
$space-large: $space-base-unit * 12; // 44px
$space-larger: $space-base-unit * 15; // 60px
$space-largest: $space-base-unit * 20; // 80px

//////
// Border Thickness
//////
$border-thinnest: 0.5px;
$border-thin: 1px;
$border-med: 4px;

//////
// Foundation overrides
//////

$grid-column-gutter: (small: rem-calc(16),
  medium: rem-calc(24),
  large: rem-calc(37),
);

$nav-left-padding: rem-calc(60);
$component-vertical-margin: rem-calc(60);

//////
// Complicated selectors
//////
$selector-text-inputs: 'input[type="text"], input[type="password"], input[type="url"], input[type="email"], input[type="search"], input[type="number"], input[type="tel"]';
$selector-icon: '[class^="icon-"],[class*=" icon-"]';


//////
// Misc
//////
$border-radius: 6px;
$global-font-size: 16px;
$global-width: rem-calc(1440);
$global-width-mid: rem-calc(1000);
$global-width-small: rem-calc(768);
$xl-width: rem-calc(1680);
$global-width-pdp-comp: rem-calc(1180);
$global-width-article-detail-content: rem-calc(1100);
$hero-height: rem-calc(420);
$arrow-size: 6px; //this has to be in px. rem calc doesn't get it small enough
$transition-med: .3s;

/// BRAND GRID ///
$brand-grid-spacing-sm: rem-calc(5);
$brand-grid-spacing-med: rem-calc(6);
$brand-grid-spacing-lg: rem-calc(8);

// 2. Breakpoints
// --------------

$breakpoints: (small: 0,
  medium: rem-calc(768),
  large: rem-calc(1081),
  xlarge: rem-calc(1281),
  xxlarge: rem-calc(1440));
$breakpoint-ends: (small: rem-calc(767),
  medium: rem-calc(1080),
  large: rem-calc(1280),
  xlarge: rem-calc(1439));
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge);

$font-name-icons: 'cmco-icon';
$animate-fast: 0.25s;
$animate-slow: 0.5s;

// Icons
$icons: (icon-arrow-small: "\e900",
  icon-articles: "\e901",
  icon-document-library: "\e902",
  icon-download-brochure: "\e903",
  icon-download-dimensional: "\e906",
  icon-download-manual: "\e91e",
  icon-download-parts: "\e91f",
  icon-download-wiring: "\e907",
  icon-facebook: "\e934",
  icon-grid-view: "\e909",
  icon-how-to-buy: "\e90a",
  icon-instagram: "\e92e",
  icon-vimeo: "\e939",
  icon-list-view: "\e90d",
  icon-menu: "\e90e",
  icon-newsroom: "\e90f",
  icon-search-content: "\e910",
  icon-search-documents: "\e911",
  icon-search-products: "\e912",
  icon-search: "\e913",
  icon-services-repairs: "\e914",
  icon-tradeshows-events: "\e915",
  icon-twitter: "\e916",
  icon-videos: "\e917",
  icon-warranty: "\e918",
  icon-youtube: "\e933",
  icon-checkmark: "\e92a",
  icon-close-menu: "\e91a",
  icon-linkedin: "\e932",
  icon-quote-right: "\e91c",
  icon-pim-photo: "\e920",
  icon-pim-bro: "\e903",
  icon-pim-cs: "\e904",
  icon-pim-dd: "\e906",
  icon-pim-exp: "\e91f",
  icon-pim-om: "\e91e",
  icon-pim-ps: "\e921",
  icon-pim-tb: "\e922",
  icon-pim-vid: "\e923",
  icon-pim-wir: "\e924",
  icon-directions: "\e91d",
  icon-details: "\e905",
  icon-arrow-left-long: "\e97d"
);


// z-indexing
$z-index-max: 9999;
$z-index-geo-locator-popup: 9998;
$z-index-geo-locator-popup-backdrop: 9997;
$z-index-region-dropdown-content: 9996;
