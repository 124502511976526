$block: "request-quote";
$breakpoint-size: "large";

.#{$block} {
  background: $color-white;
  position: relative;

  &__wrapper {
    padding: 0 rem-calc(37);

    .hbspt-form {
      .hs-richtext.hs-main-font-element {
        text-align: left;

        h2 {
          font-size: rem-calc(32);
        }

        p {
          font-size: $font-size-standard;
        }
      }

      .hs-form-61db3d28-0bb2-47a1-8b53-edc8190ba5e4_2655f0ad-4e9c-42f2-8d8f-0e4795b8d0f1 fieldset  {
        width: 100%;
        max-width: none;
      }
    }
  }
}