$block: "teaser-card";
$breakpoint-size: "large";

.#{$block} {
  &__text {
    padding: map-get($grid-column-gutter, $breakpoint-size);

    &--heading {
      font-size: map-get(map-get($typography, $breakpoint-size), p);
    }

    &--description {
      font-size: $font-size-smaller;
    }
  }
}