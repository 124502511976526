$block: "banner-content";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  flex-direction: column;
  z-index: 2; //needed for when this component is inside a pdp section

  &__wrapper {
    max-width: 90%;
    padding: $space-large $space5;
  }

  &__text {
    color: $color-white;

    &--category {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      font-family: $font-regular;
      text-transform: uppercase;
      padding-bottom: $space4;
      color: $color-white;
    }

    &--title {
      font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
      font-family: $font-bold;
      line-height: $line-height-small;
      margin-bottom: $space4;
    }


    &--description {
      color: $color-white;
      opacity: .9;
      font-size: map-get(map-get($typography, $breakpoint-size), p);
      font-family: $font-regular;
    }

    p {
      margin: 0;
    }
  }

  &__cta {
    padding-top: $space;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .cta {
      margin-bottom: $space;
    }
  }
}
