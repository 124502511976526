$block: "article-detail-page";
$breakpoint-size: "small";

.#{$block} {
  position: relative;
  z-index: 3; //  make sure this goes over hero

  &__content {
    background: $color-white;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: calc(100% - #{map-get($grid-column-gutter, $breakpoint-size)*2});
    padding: $space-mid map-get($grid-column-gutter, $breakpoint-size) 0;
    max-width: 100%;
    height: auto;
    transform: translateY(rem-calc(-100));
    margin-bottom: rem-calc(-100);

    &--fall {
      transform: none;
      margin: 0 auto;
    }
  }

  hr {
    margin-left: 0;
    margin-right: 0;
    border-color: $color-gray-light;
    margin-bottom: $space-mid;
  }

  &__attribution {
    padding-top: map-get($grid-column-gutter, $breakpoint-size);
    text-transform: uppercase;
    font-size: rem-calc(11);
    font-weight: bold;
    color: $color-gray-3;
  }

  .rte-block {
    padding: 0;

    .twoX-content-block {
      overflow: hidden; // Fix for empty content boxes being used by authors for spacing under images on the blog. Fixes white space overlapping text below.

      p {
        color: $color-body-text;
        opacity: .85;
      }

      .image-block {
        height: rem-calc(271);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
