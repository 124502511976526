$block: "pdp-primary-cta";
$breakpoint-size: "small";

.#{$block} {
  @include transition;
  background: $color-primary-brand;
  color: $color-white;
  border: none;

  &:visited {
    color: $color-white;
  }

  .icon {

    &:before {
      font-size: rem-calc(16);
      position: relative;
      top: 2px;
      left: -10px;
    }
  }

  &:hover, &:focus {
    color: $color-text;
    background: $color-brand-accent-1;
  }

  &--request-quote {
    padding-top: $space2;
    padding-bottom: $space2;
    margin-top: $space-smallest;
    margin-bottom: $space-smallest;
    margin-left: map-get($grid-column-gutter, $breakpoint-size);
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
  }
}

//Price Spider Buy Now Button
.pdp-primary-cta.ps-widget {
  float: none !important; //Importants overwrite Price Spider styles
  padding: 14px 46px !important;
  display: inline-block !important;
  margin: 0 0 1rem 0 !important;
  border: 0 !important;
  line-height: 0 !important;
  width: auto;

  &:hover,
  &:focus {
    .pdp-ps-label {
      color: $color-black;
    }
  }

  .ps-button-label {
    display: none;
  }

  .pdp-ps-label {
    color: $color-white;
    font-family: $font-bold;
    font-size: rem-calc(13);
    text-align: center;
    font-weight: 300;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  &.pdp-disabled {
    pointer-events: none;
    opacity: 0.5;

    &.ps-enabled {
      pointer-events: all;
      opacity: 1;
    }
  }
}

