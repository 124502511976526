$block: "our-brands";
$breakpoint-size: "medium";

.#{$block} {
	padding: rem-calc(80) 0 rem-calc(50);

	&__filter-btn {
		font-size: $font-size-mobile-smallest;
	}

	&__logo-link {
		flex-basis: percentage(1/4);
		margin-bottom: rem-calc(45);

		img {
			max-height: rem-calc(30);
		}
	}
}
