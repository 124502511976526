$block: "search-results-nav";
$breakpoint-size: "medium";

.#{$block} {

  &__header {
    border-top: none;
  }

  .link-list--utility-nav {
    max-width: rem-calc(534) !important;
  }
}