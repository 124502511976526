$block: "post-card";
$breakpoint-size: "medium";

.#{$block} {
  
  //////////////////////////
  // MODIFIER: EVENT CARD
  &--eventCard {
    max-width: inherit;
    flex-direction: row;
    align-items: center;

    .#{$block} {
      &__image {
        width: rem-calc(180);
        height: rem-calc(180);
      }

      &__content {
        padding-left: $space-mid;
        padding-right: $space-mid;
      }

      &__cta {
        margin-left: auto;
        width: auto;
      }
    }
  }
}
