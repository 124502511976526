$block: "stats-content-block";
$breakpoint-size: "large";

.#{$block} {
  margin: 0 auto $space-largest;

  &--stats {
    .banner-content {
      left: inherit;
      transform: none;
    }
  }
}
