$block: "page-title";
$breakpoint-size: "small";

.#{$block} {
	h1 {
		text-transform: uppercase;
		margin-bottom: 0;
		padding: $space-large map-get($grid-column-gutter, $breakpoint-size);
	}
}
