$block: "disclaimer";
$breakpoint-size: "medium";

.#{$block} {
  max-width: rem-calc(680);
  margin: auto;

  &__text {
    &--title {
      font-size: map-get(map-get($typography, $breakpoint-size), p-large);
    }
  }

  &__cta {
    .cta {
      padding: $space3 $space-large;
    }
  }
}