$block: "secondary-btn";
$breakpoint-size: "small";

.#{$block} {
  @include transition;
  color: $color-white;
  border: 1px solid $color-white;
  background-color: transparent;

  .icon {
    transition: none; //otherwise there's a double transition due to something on .icon-videos
  }

  &:visited{
    color: $color-white;
  }

  &:hover, &:focus {
    color: $color-text;
    border-color: $color-brand-accent-1;
    background-color: $color-brand-accent-1;
  }
}
