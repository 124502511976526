$block: "hs-eu-cookie-confirmation";
$breakpoint-size: "small";

##{$block} {
    position: fixed !important;
    right: initial !important;
    left:initial !important;
    top: initial !important;
    height: fit-content;
    bottom: 0 !important;
    width: 100% !important;
    box-shadow: none !important;


   #hs-eu-cookie-confirmation-inner
    #hs-en-cookie-confirmation-buttons-area a:hover {
        color: #0D0D0D !important;
        background: #FF9500 !important;
        border-color: #FF9500 !important;
    }

}

