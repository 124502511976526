$block: "hero-breadcrumbs";
$breakpoint-size: "small";

.#{$block} {
  display: none;
  
  & + .visual-cue {
    display: none;
  }
}
