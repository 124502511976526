$block: "rotating-carousel";
$breakpoint-size: "small";

.#{$block} {
  padding: 0;
  overflow: hidden;

  &__contentCard {
    position: relative;

    a {
      height: 100%;
    }
  }

  &__image {
    position: relative;

    img,
    picture {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
    }

    &--background {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    }
  }

  &__text {
    position: absolute;
    bottom: $space;
    width: 100%;
    padding: 0 $space;
    text-align: center;

    &--title {
      color: $color-white;
      font-size: map-get(map-get($typography, $breakpoint-size), h3);
    }
  }

  .slick-list {
    padding-right: 60px;
  }

  .slick-slide {
    padding-right: $space4;
    padding-left: $space4;
    overflow: hidden; //IE fix
  }

  .slick-prev {
    left: 0 !important;
  }

  .slick-next {
    right: 0 !important;
  }
}
