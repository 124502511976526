$block: "newsroom-block";
$breakpoint-size: "large";

.#{$block} {
  &.component {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(95);
  }

  &__articles {
  	$articles-space: rem-calc(20);

    &:nth-child(2) {
      padding-right: $articles-space;
    }

    &:nth-child(3) {
      padding-left: $articles-space;
    }
  }

  .post-card {
    &__content {
      &--cardTag {
        font-size: rem-calc(12.5); // not in scale
        line-height: 13px;
      }

      &--title {
        font-size: $font-size-standard;
        line-height: 26px;
      }
    }

    &--featured {
      .post-card__content--title {
        font-size: $font-size-large;
        line-height: 35px;
      }

      .post-card__content--description {
        margin-bottom: rem-calc(16);

        p {
          font-size: $font-size-small;
          line-height: 24px;
        }
      }
    }
  }
}
