$block: "product-by-brands";
$breakpoint-size: "small";

.#{$block} {

    &.component {
        max-width: rem-calc(1472);
    }

    &__wrapper {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

    }

    h4 {
        font-size: map-get(map-get($typography, $breakpoint-size), h4);
        align-self: flex-start;
    }

    &__product-list {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        width: 200px;
        margin-top: rem-calc(20);

        img {
            width: 227px;
            margin-bottom: rem-calc(20);

        }

        ul {
            list-style: none;
            max-width: 183px;
            min-width: 264px;
        }

        li {
            margin-bottom: rem-calc(10);
            a {
                @include transition;
                background-image: linear-gradient(rgb(255, 149, 0), rgb(255, 149, 0));
                background-position: 0% 76%;
                background-size: 0 2px;
                background-repeat: no-repeat;
        
                &:hover {
                    background-size: 100% 2px;
                }
            }
        }
    }
}
