$block: "resource-listing-page";
$breakpoint-size: "large";

.#{$block} {
	padding: $space5 0;

  .featured-card {
    max-width: $global-width;
    margin-top: 0;

    &__content {
      padding: $space-large;
    }

    &__text {
      max-width: rem-calc(607);
    }

    &__cta {
      width: unset;
      margin-top: 0;
      padding-top: 0;

      .cta {
        width: rem-calc(175);
      }
    }

    &--document  {
      margin: 0;
      padding: rem-calc(11) 0;
    }
  }

  .facet-pagination {
    margin-bottom: 0;
    margin-top: rem-calc(11);
  }
}
