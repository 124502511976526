$block: "quick-links-primary";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;
  z-index: 9;

  &__contact {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .quick-links-secondary {
      width: 76%;
      padding: rem-calc(20.6) rem-calc(18);
      border: 0;
      border: 0;
      background-color: $color-brand-accent-1;

      &__contactDetails {
        top: 30px;
        right: -22px;
        left: initial;
        width: 99vw;
        border-top: 5px solid $color-brand-accent-1;
        background-color: #eee;
      }

      &__contact--link {
        display: flex;
        font-family: $font-regular;
        font-size: rem-calc(14);
        font-weight: 400;
        text-transform: none;
      }


      &__contact--link--desktop {
        display: none;
        border-bottom: none;
        font-size: rem-calc(13);

        &:after {
          right: -25px !important;
        }
      }

      &__contact--link--mobile {
        display: inline-block;
        border-bottom: none;
        font-size: rem-calc(13);

        &:after {
          right: -25px !important;
        }
      }

    }

    .cta {
      width: 80%;
      padding: rem-calc(18 18);
      border-radius: 0;
      white-space: nowrap;
    }

  }

  &__logo {
    display: flex;
    margin: 0 rem-calc(10);
    padding: rem-calc(10) rem-calc(5);
  }

  &__logo--desktop {
    display: none;
  }

  &__logo--mobile {
    display: inline-block;

    img {
      width: 126px;
    }

    picture {
      top: -8px;
    }

    .brand-logo__logo {
      margin-bottom: 0;
    }
  }

  .brand-logo {

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
      display: block;
      width: 107px;
      height: auto;
    }
    width: 50%;
    min-width: 140px;
    height: auto;
    background: transparent;

	&__wrapper {
		background: transparent;
	}

	&__logo {
		min-height: 0;
	}
  }

  &__nav {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-left: rem-calc(6);
    padding-left: rem-calc(20);
    border-left: 1px solid $color-gray-light;

    &:only-child {
      margin-left: 0;
      padding-left: 0;
      border: 0;
    }

    &--link,
    a,
    &--jump-to {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem-calc(13) 0;
      padding-top: 0;
      color: $color-white;
      font-family: $font-bold;
      font-size: $font-size-smaller;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
      &:hover, &:focus {
        color: rgba($color-white, 0.8);
      }
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    .quick-links-secondary {
      width: 100%;

      &__contact--link {
        padding-bottom: rem-calc(12);

        span:not(.icon) {
          padding-left: rem-calc(10);
        }

        .icon:before {
          position: absolute;
          top: 4px;
          left: -10px;
          color: $color-gray-3;
          font-size: rem-calc(10);
        }
      }

      &__contactDetails {
        padding-left: 12px;
        background-color: $color-gray-lighter;
        z-index: 9999;


      li {
        padding-bottom: 0;
        border-top: 0;
        background-color: transparent;

        &:first-of-type {
          padding-top: 0;
        }

        a {
            border-bottom: 0;
            color: $color-gray-3;
            font-family: $font-regular;
            font-weight: initial;
          }
        }
      }

      &__contact--dropdown {
          text-align: left;

        a {
          display: inline-block;
          width: 100%;
          padding-top: 0;
          padding-bottom: rem-calc(5);
          border-bottom: 1px solid $color-primary-brand-gray;
          color: $color-primary-brand;
          text-transform: capitalize;

          &:after {
            right: 0 !important;
            border-color: $color-primary-brand transparent transparent;
          }
        }

        .quick-links-secondary__contact--link {
            width: 100%;
            padding-bottom: rem-calc(15);
        }
      }
    }

    &--jump-to {
      padding-bottom: rem-calc(10);
      color: $color-primary-brand-gray;

      &:hover, &:focus {
        color: $color-primary-brand-gray;
      }
    }

    a {
      padding-top: 0;
      padding-bottom: rem-calc(5);
      color: $color-primary-brand;
      text-transform: capitalize;

      &:hover, &:focus {
        color: rgba($color-primary-brand, 0.8);
      }

      &:first-child {
        font-size: rem-calc(16);
      }

      span {
        font-size: $font-size-smaller;
      }
    }

    &--icon {
      padding-right: rem-calc(10);
      color: $color-white;
      font-size: rem-calc(17);
    }

    &--menu {
      height: 100vh;
      background: $color-white;

      & > li:first-child a {
        background: $color-primary-brand;
        color: $color-white;
      }
    }
  }

  .menu .is-active > a {
    background: $color-white;
  }

  li {
    list-style: none;
    &:first-child {
      border-top: none;
    }
  }


  &--content-landing {
    background-color: $color-primary-brand-dark;
    color: $color-white;

    .quick-links-secondary .accordion-menu .nested.is-accordion-submenu {
      top: 39px;
      left: -30px;
      width: 100vw;
      margin: 0px;
    }

    .quick-links-primary__logo .quick-links-primary__nav {
      padding: rem-calc(5) 0;
      padding-left: 0;
      border: none;

      .quick-links-secondary__contact--link--desktop {
        border-bottom: none;
        background-color: $color-primary-brand-dark;
        color: $color-white;

        &:after {
          border-color: $color-white transparent transparent !important;
        };
      }


      &--jump-to {
        display: none;
        white-space: nowrap;
        width: 30%;
        color: $color-white;
      }
    }
  }

}
