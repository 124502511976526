$block: "nav-tab-panel";
$breakpoint-size: "large";

.#{$block} {
  border: none;
  border-top: rem-calc(.5) solid $medium-gray;

  &__dropdown-pane {
    top: 100% !important;
    margin: 0 map-get($grid-column-gutter, $breakpoint-size) 0 (map-get($grid-column-gutter, $breakpoint-size) - 1rem); /*the -1rem is to account for foundation padding around dropdown item */
  }

  &__dropdown-pane--left {
    left: 0 !important;
    width: 100%;
  }

  .grid-container--tabs {
    padding: 0;
  }

  &__tab-title--active {
    background: $color-primary-brand;

    a {
      color: $color-white !important;
      background: $color-primary-brand;
    }
  }

  &__tab-title {
    padding: 0 !important;
    display: flex;
    flex-direction: row !important;

    a {
      flex-basis: 75%;
      font-weight: 500;
      margin: 0 auto;

      &:hover {
        //background: none;
      }
    }
  }

  &__sub-types {
    display: flex;
    flex-wrap: wrap;
  }

  &__sub-type {
    flex-basis: 25%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 $space3;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex-basis: 21%;
    }

    a {
      margin: $space3 0;
    }
  }

  &__sub-type-title {
    font-family: $font-medium;
    font-weight: 500;

    a {
      @include transition;
      padding: 0;
      color: $color-text;
      line-height: $line-height-small;
      &:hover, &:focus {
        color: $color-primary-brand;
      }
    }
  }

  &__sub-type-product {
    margin: $space2 0;
    //line-height: rem-calc(35);

    a {
      @include underlineLink;
      color: $color-gray-3;
      margin-bottom: rem-calc(15);
      margin-top: rem-calc(15);
      padding: 0;
      display: inline;
      line-height: $line-height-small;
    }
  }

  &__view-all-link {
    font-weight: 900;
  }

  &__vertical-tabs {
    width: 100%;
    border: none;
    background-color: $color-gray-lightest;
    height: 100%;
  }

  &__tabs-content {
    padding: 0;
    border: 0;
  }

  &__tabs-content--flat {
    display: flex;
    flex-direction: row;
    background-color: $color-gray-lightest;
    flex-wrap: wrap;
  }

  &__link-column {
    min-width: 14rem;
    max-width: 12.2vw;
    width: 100vw;
  }

  &__wrapper {
    width: 100%;
  }

  &__flat-wrapper {
    background-color: $color-white;
    height: auto;
    width: 100%; // height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__tabs-title {
    padding: 0 !important;
    display: flex;
    flex-direction: row !important;
    height: auto !important;
    border-bottom: none !important;
    margin: 0!important;

    a {
      @include transition;
      flex-basis: 75%;
      font-weight: 400;
      margin: 0 auto;
      color: $color-black;
      font-family: $font-bold !important;
      padding: $space4;
      line-height: $line-height-small;

      &:hover {
        background: $color-gray-lighter-2;
        color: $color-primary-brand;
      }
    }
  }

  &__tabs-title--active {
    background: $color-primary-brand !important;

    a {
      color: $color-white !important;
      background: $color-primary-brand !important;
    }
  }

  .brands-multi-content-block {
	  padding-top: 0;
  }
}
