$block: "request-quote";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;
  position: relative;

  .reveal {
    width: rem-calc(743);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: rem-calc(24);
    top: rem-calc(24);
    width: rem-calc(17);
    height: rem-calc(17);
    background: $color-gray-light;
    border-radius: 100px;

    span {
      position: relative;


      &:before {
        @include iconStyles;
        content: map-get($icons, icon-close-menu);
        font-size: rem-calc(7);
        position: absolute;
        top: rem-calc(6);
        left:rem-calc(5);
        color: $color-white;
      }
    }
  }

  &__wrapper {
    padding: 0 map-get($grid-column-gutter, $breakpoint-size);

    .hbspt-form {
      .hs-richtext.hs-main-font-element {
        text-align: left;

        h2 {
          font-family: $font-bold;
          color: $color-primary-brand-lighter;
          font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
        }

        p {
          color: $color-body-text;
          font-size: map-get(map-get($typography, $breakpoint-size), p);
        }
      }

      fieldset.form-columns-1 .hs-input:not([type=checkbox]) {
        width: 100% !important;
      }

      .hs-form-booleancheckbox-display {
        display: flex;
        align-items: baseline;
      }

      .legal-consent-container .hs-form-booleancheckbox-display > span {
        margin-left: 5px;
      }

      input[type=checkbox] {
        height: auto;
      }

      .hs_submit {
        text-align: left;
        margin-top: rem-calc(30);
      }
    }
  }
}