$block: "search-results-nav";
$breakpoint-size: "large";

.#{$block} {

  .results-found {
    padding: rem-calc(78) 0 rem-calc(77) $nav-left-padding;
  }


  &__links {
    max-width: rem-calc(604);
    margin: 0;

  }

  &__link {
    font-size: $font-size-smaller;
    line-height: rem-calc(10);
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);

    .icon {
      padding-right: rem-calc(7);
      font-size: $font-size-mobile-standard;
    }

    &.is-active {
      color: $color-white;
      padding-bottom: rem-calc(27);
      border-bottom: rem-calc(4) solid $color-primary-brand; // From Comps
    }
  }

  &__sticky-nav {
    margin-bottom: $space5;
  }

  .utility-nav__wrapper {
    padding-left: 0;
  }
}