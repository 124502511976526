$block: "stats-card";
$breakpoint-size: "small";

.#{$block} {
  padding: 0 map-get($grid-column-gutter, $breakpoint-size);

  &__wrapper {
    background: $color-primary-brand;
    color: $color-white;
    padding: rem-calc(40) $space-large $space-large;
    position: relative;
    box-shadow: 10px -10px 0 $color-white;

    ul {
      text-align: center;
    }
  }

  &__item {
    list-style: none;
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    text-align: center;
    margin: $space auto;
    &:first-child {
      margin-top: 0;
    }
  }

  &__textPrimary,
  &__textSecondary {
    font-family: $font-bold;
  }

  &__textPrimary {
    font-size: rem-calc(48);
  }

  &__textSecondary {
    font-size: map-get(map-get($typography, $breakpoint-size), h4);
    opacity: .85;
  }
}
