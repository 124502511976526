$block: "symbol-text-cta";
$breakpoint-size: "small";

.#{$block} {
  @include transition;
  color: $color-primary-brand;
  background-color: $color-white;
  display: flex;
  align-items: center;
  height: rem-calc(45); //from comps
  line-height: inherit;

  &:visited {
    color: $color-primary-brand;
  }

  &:hover, &:focus {
    color: $color-text;
    border-color: $color-brand-accent-1;
    background-color: $color-brand-accent-1;
  }

  &__icon {
    transition: none; //otherwise, there's a double transition due to something on .icon-videos
    padding: 0 map-get($grid-column-gutter, $breakpoint-size)/2;
    line-height: $line-height;
  }

  &__text {
    white-space: nowrap;
  }

 &--play-video {
    width: auto;
    height: rem-calc(45);
    display: flex;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding: 14px 16px;

    .icon-arrow-small {
      font-size: 7px;
      transform: rotate(270deg);
      position: relative;
      left: 5px;
    }

    .#{$block} {
      &__icon {
        padding: 0;
        line-height: $line-height;
      }

      &__text {
        flex-shrink: 0;
        padding-left: 5px;
      }
    }
  }
}
