$block: "industry-icons";
$breakpoint-size: "large";

.#{$block} {
	$gutter-grid-width: rem-calc(25);
	$gutter-side-width: ($gutter-grid-width / 2);
	$icon-box-size: calc(#{percentage(1/5)});
	
	padding: rem-calc(100) rem-calc(20) rem-calc(95);

	&__content {
		h2 {
			&.blue-border-heading {
				margin-bottom: rem-calc(30);
			}
		}

		p {
			font-size: $font-size-medium;
			line-height: rem-calc(30);
		}
	}

	&__icon-grid {
		width: calc(100% + #{$gutter-grid-width});
		margin-top: rem-calc(45);
		margin-right: -($gutter-grid-width / 2);
		margin-bottom: rem-calc(34);
		margin-left: -($gutter-grid-width / 2);
	}

	&__icon-box {
		flex: 0 0 $icon-box-size;
		padding-top: 0;

		a {
			span {
				font-size: rem-calc(16);
				margin-top: rem-calc(25);
			}
		}
	}
}
