$block: "facets";
$breakpoint-size: "large";

.#{$block} {
  position: relative;
  width: 100%;
  padding: 0;

  &__wrapper {
    position: relative;
    top: initial;
    width: 100%;
    height: auto;
    z-index: 1;
	background-color: $color-white;
  }

  &__options-name {

    .name {
      width: 85%;

    }

  }

  &__applied {
    display: flex;
    flex-direction: column;
    padding: rem-calc(0);
    margin-left: initial;
    margin-right: initial;
    margin-bottom: $space;
    background-color: transparent;
  }

  &__filter-options__title {
    text-transform: uppercase;
    line-height: rem-calc(14);
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    font-family: $font-bold;
  }


  &__category {
    cursor: pointer;
  }

  &__per-page {
    display: flex !important;
  }

  &__filter-cta {
    display: none !important;
  }

  &__filter-options-title {
    display: block;
  }

  &__filters-mobile-header {
    display: none;
  }

  &--as-filters {
    display: none;
  }
}
