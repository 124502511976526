$block: "event-listing-page";
$breakpoint-size: "medium";

.#{$block} {
  .page-title {
    border-top: none;
  }

  .hero-breadcrumbs {
    background: white;
    position: relative;

    li {
      a,
      &:after,
      &:last-of-type {
        color: $color-text!important;
      }
    }
  }
}
