$block: "tool-tip";
$breakpoint-size: "small";

.#{$block} {
  &.hide {
    display: none;
  }

  &__block {
    background: $color-white;
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
    max-width: rem-calc(289);
    padding: rem-calc(16 24);
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: rem-calc(-9);
      transform: translate(0, -50%);
      content: "";
      width: 0;
      height: 0;
      z-index: 1;
      border-top: 7px solid transparent;
      border-right: 10px solid white;
      border-bottom: 7px solid transparent;
      -webkit-filter: drop-shadow(-3px 3px 2px rgba(0,0,0,0.35));
      filter: drop-shadow(-3px 3px 2px rgba(0,0,0,0.35));
    }
  }

  &__text {
    width: 85%;

    .rte-block {
      padding: 0;

      p {
        margin: 0;
        font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      }
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: rem-calc(12);
    top: rem-calc(12);
    width: rem-calc(17);
    height: rem-calc(17);
    background: $color-gray-light;
    border-radius: 100px;
    padding: rem-calc(5);
    z-index: 1;

    span {
      position: relative;

      &:before {
        @include iconStyles;
        content: map-get($icons, icon-close-menu);
        font-size: rem-calc(6);
        position: absolute;
        top: rem-calc(1);
        left: rem-calc(0.5);
        color: $color-white;
      }
    }
  }
}