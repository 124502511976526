$block: "two-col-text";
$breakpoint-size: "small";

.#{$block} {
  &__title {
    margin-bottom: 0;
    font-family: $font-bold;
    font-size: map-get(map-get($typography, $breakpoint-size), h2);
    text-transform: uppercase;
    text-align: center;
  }

  &__subtitle {
    color: $color-gray-3;
    font-size: map-get(map-get($typography, $breakpoint-size), p);
    text-align: center;
  }

  .component__text {
    padding-top: map-get($grid-column-gutter, $breakpoint-size);
  }
}
