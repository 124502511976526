$block: "quote-block";
$breakpoint-size: "small";

.#{$block} {
  @include pageContainer();

  &__wrapper {
    background: $color-primary-brand;
    padding: $space-larger $space-mid;
  }

  &__text {
    color: $color-white;
    font-family: $font-regular;
    padding-left: $space;
    position: relative;

    &--quote,
    h2 {
      font-size: map-get(map-get($typography, $breakpoint-size), h2);
      font-weight: 500;
      line-height: 1.8;
    }

    &--attribution {
      padding-top: map-get($grid-column-gutter, $breakpoint-size);
      text-transform: uppercase;
      font-size: rem-calc(11);
      font-weight: bold;
    }

    &:before {
      content: "";
      width: rem-calc(3);
      height: 100%;
      background: $color-white;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  ///////////////////////////////
  // MODIFIER: Testimonial Quote
  &--testimonial {
    background: $color-primary-brand;
    padding: 0;
    text-align: center;

    .#{$block} {
      &__text {
        text-align: center;
        padding: 0;
        position: relative;

        &--quote {
          font-size: map-get(map-get($typography, $breakpoint-size), p-large);
        }

        //quotes icons
        &:before,
        &:after {
          @include iconStyles;
          content: map-get($icons, icon-quote-right);
          font-size: rem-calc(30);
          opacity: .25;
          position: absolute;
          top: rem-calc(-25);
        }

        &:before {
          width: 0;
          left: rem-calc(15);
          -moz-transform: scale(-1, 1);
          -webkit-transform: scale(-1, 1);
          -o-transform: scale(-1, 1);
          -ms-transform: scale(-1, 1);
          transform: scale(-1, 1);
        }

        &:after {
          right: rem-calc(-15);
        }
      }

      &__logoImg {
        margin: rem-calc(30) auto 0;
        width: 100%;
        max-width: rem-calc(123);

        img {
          object-fit: contain;
        }
      }

      &__cta {
        margin-top: $space-large;

        .secondary-cta {
          background: $color-white;
          color: $color-primary-brand;
        }
      }
    }
  }
}