$block: "utility-nav";
$breakpoint-size: "small";

.#{$block} {
  position: relative;

  &__wrapper {
    display: flex;
    align-items: center;
    min-height: rem-calc(35);
  }

  &__brandLink,
  .link-list a {
    color: $color-white;
  }

  .link-list--utility-nav {
    display: none;
  }

  .utility-nav__brandlink-wrapper {
    min-width: 50%;

    a {
      display: none;
    }
  }

  &__brandLink {
    padding: 7px $space4 7px $space5;
    font-family: $font-bold;
    font-size: rem-calc(10);
    font-weight: 300;
    line-height: $line-height;
    white-space: nowrap;
    opacity: .6;
  }

  //// Theme Color Modifiers
  &__themeColorPrimary {
    background: $color-text;
  }

  &__themeColorSecondary {
    background: $color-primary-brand-dark;
  }

  .js-brandsBlock {
    position: absolute;
    top: rem-calc(35);
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }

  ////  Modifier
  // Region - updated utility nav
  &__reg {
    .utility-nav__brandLink {
      display: none;
    }

    .utility-nav__region {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0;
      line-height: $line-height;

      .dropdown-content {
        position: relative;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: rem-calc(0) rem-calc(60);
        padding-left: rem-calc(20);
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, .3);
        z-index: $z-index-region-dropdown-content;

        &__location {
          font-family: $font-medium;
        }

        &__languages {
          width: 100%;
          border-bottom: 1px solid $color-gray-4;
          list-style-type: none;
        }

        &--open {
          display: flex;
        }

        &__flag {
          img {
            display: inline-block;
            width: 18px;
            height: 13px;
            margin-right: rem-calc(6);
            vertical-align: middle;
          }
        }

        li {
          a {
            @include transition;
            color: $color-body-text;
            font-size: $font-size-smallest;
            text-decoration: none;
            white-space: nowrap;
          }
        }

        &__change-loc {
          position: relative;
          display: inline-block;
          margin: rem-calc(10) 0;
          padding-left: rem-calc(35);
          color: $color-primary-brand;
          font-family: $font-bold;
          font-size: $font-size-smallest;
          text-transform: uppercase;
          white-space: nowrap;

          .icon {
            &:before {
              position: absolute;
              top: -3px;
              left: 3px;
              color: $color-primary-brand;
              font-size: rem-calc(25);
            }
          }
        }
      }

      &--link {
        @include transition;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: rem-calc(15);
        color: $color-white;
        font-family: $font-bold;
        font-size: rem-calc(12);
        font-weight: 300;
        line-height: 1;
        white-space: nowrap;
        background-color: transparent;

        &:hover,
        &:focus {
          color: $color-primary-brand-light;
        }
      }

      img {
        display: inline-block;
        width: 20px;
        height: 15px;
        margin-right: rem-calc(8);
        vertical-align: middle;
      }

      .js-popup-fade-in {
        @include transition(all, $animate-slow, ease-out);
        top: 15px;
        opacity: 1;
      }

      .js-popup-fade-out {
        @include transition(all, $animate-slow, ease-out);
        top: 15px;
        opacity: 0;
      }

      &--popup {
        position: absolute;
        top: 10px;
        left: calc(100% + #{$space4});
        display: none;
        min-width: 343px;
        padding: $space $space-mid $space $space;
        background: $color-primary-brand;
        color: $color-white;
        font-family: $font-bold;
        font-size: rem-calc(12);
        opacity: 0;
        z-index: 999;

        &:before {
          position: absolute;
          top: 0;
          left: -10px;
          width: 0;
          height: 0;
          border-top: 20px solid $color-primary-brand;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          content: "";
        }

        .icon-close-menu {
          @include transition;
          position: absolute;
          top: rem-calc(11);
          top: rem-calc(11);
          right: rem-calc(11);
          right: rem-calc(11);
          padding: rem-calc(5);
          padding: rem-calc(5);
          border: 1px solid rgba($color-white, 0);
          border: 1px solid rgba($color-white, 0);
          border-radius: 50%;
          color: rgba($color-white, .6);
          font-size: rem-calc(12);
          cursor: pointer;

          &:hover,
          &:focus {
            top: rem-calc(6);
            right: rem-calc(6);
            padding: rem-calc(10px);
            border: 1px solid rgba($color-white, .8);
            color: rgba($color-white, 1);
          }
        }

        a {
          @include transition;
          display: block;
          margin-top: $space5;
          color: $color-white;
          text-align: center;
          text-decoration: underline;

          &:hover,
          &:focus {
            color: rgba($color-white, .6);
            text-decoration: none;
          }
        }
      }
    }
  }
}
