$block: "title-text-list-item";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h3);
    font-weight: bold;
    text-transform: uppercase;
  }
}
