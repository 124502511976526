$block: "facet-pagination";
$breakpoint-size: "small";

.#{$block} {
  width: 100%;
  display: flex;
  padding: map-get($grid-column-gutter, $breakpoint-size) 0;
  margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
  margin-left: 0;
  margin-right: 0;

  div {
    display: flex;
    align-items: center;
  }

  select {
    margin-bottom: 0;
    border-radius: 3px;
    width: rem-calc(88);
    margin: 0 map-get($grid-column-gutter, $breakpoint-size);
    border: 0.5px solid $color-gray;
  }

  &__label {
    display: inline-block;
  }

  &__pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 rem-calc(10);

    a {
      color: $color-gray-3;
      opacity: .5;
    }

    &.active {
      a {
        color: $color-text !important;
        font-weight: bold !important;
        opacity: .75;

      }
    }
  }

  .icon-arrow-small {
    font-size: 8px;
    line-height: 8px;
  }

  &__pagination-item--previous, &__pagination-item--next {
    height: rem-calc(45);
    width: rem-calc(45);
    border: 0.5px solid $color-gray;
    border-radius: 6px;
    background-color: $color-white;
    padding: 0;

    &.disabled {
      opacity: .5;
      color: $color-gray-3;
    }


    a {
      color: $color-primary-brand;
      opacity: 1;
    }
  }

  &__pagination-item--previous {
    margin-right: rem-calc(10);

    a {
      transform: rotate(-270deg);
      margin-left: -1px;
    }
  }

  &__pagination-item--next {
    margin-left: rem-calc(10);

    a {
      transform: rotate(-90deg);
      margin-right: -2px;

    }
  }

  &__per-page {
    justify-content: flex-end;
    display: none !important;
  }


  &__first-button, &__last-button {
    display: none;
  }

  &__pagination {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    width: 100%;
  }



}