$block: "featured-product";
$breakpoint-size: "small";

.#{$block} {
	$gird-gutter-width: rem-calc(8);

	padding: rem-calc(45) rem-calc(20);
	background-color: $color-gray-lighter;

	&__content {
		max-width: rem-calc(1440);
		margin-right: auto;
		margin-left: auto;
	}

	&__category {
		margin-top: rem-calc(30);

		h3 {
			margin-bottom: rem-calc(12);
			font-family: $font-bold;
			font-size: $font-size-small;
			line-height: rem-calc(17);
			text-transform: uppercase;
		}
	}

	&__item-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		width: calc(100% + #{$gird-gutter-width});
		margin-right: -($gird-gutter-width / 2);
		margin-left: -($gird-gutter-width / 2);
	}

	&__item {
		position: relative;
		display: flex;
		flex: 0 0 calc(#{percentage(1/3)} - #{$gird-gutter-width});
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		margin-right: ($gird-gutter-width / 2);
		margin-bottom: rem-calc(12);
		margin-left: ($gird-gutter-width / 2);
		padding: rem-calc(4) rem-calc(4) 0;
		transition: border $transition-med, box-shadow $transition-med;
		background-color: $color-white;
		border: 1px solid $color-gray-5;

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: border $transition-med;
			border: 2px solid transparent;
			content: '';
		}

		&:hover,
		&:focus {
			border-color: $color-brand-accent-1;
			box-shadow: 0 2px 7px 2px rgba($color-black, .15);

			&:after {
				border-color: $color-brand-accent-1;
			}
		}

		span {
			margin: rem-calc(18) rem-calc(5);
			color: $color-black;
			font-family: $font-bold;
			font-size: $font-size-mobile-smallest;
			line-height: rem-calc(16);
			text-align: center;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__expand-btn {
		$icon-size: rem-calc(16);

		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem-calc(13) rem-calc(15);
		transition: background-color $transition-med;
		border-radius: 5px;
		background: #e4e4e4;
		color: $color-black;
		font-family: $font-bold;
		font-size: $font-size-smaller;
		line-height: rem-calc(16);
		text-transform: uppercase;
		cursor: pointer;

		&:after {
			display: block;
			width: $icon-size;
			height: $icon-size;
			margin-left: rem-calc(8);
			background: url("../images/icons/icon-circle-plus.svg") no-repeat center / 100% auto;
			content: '';
		}

		&:hover,
		&:focus {
			background-color: transparent;
			box-shadow: 0 2px 0px 0.1px rgba($color-black, .15);
		}

		&--expanded {
			&:after {
				background-image: url("../images/icons/icon-circle-minus.svg");
			}
		}
	}
}
