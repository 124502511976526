$block: "full-bleed-promo";
$breakpoint-size: "medium";

.#{$block} {
	padding: rem-calc(72) 0;

	&__btn-wrapper {
		flex-direction: row;
		align-items: center;
		margin-top: rem-calc(30);
	}

	&__download-btn,
	.learn-more-btn {
		margin-right: rem-calc(24);
		margin-bottom: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	&__content {
		h2 {
			font-size: $font-size-xlarger;
			line-height: 35px;
		}

		p {
			font-size: $font-size-small;
			line-height: 24px;
		}
	}
}
