$block: "info-block";
$breakpoint-size: "small";

.#{$block} {
  max-width: rem-calc(425);
  background-color: $color-gray-lightest;

  &:hover {
    background-color: $color-white;

    .#{$block}__name .number {
      color: $color-black;
      background-color: $color-brand-accent-1;
    }

  }
  
  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: rem-calc(30) $space-large;
    border-bottom: $border-thin solid $color-gray-light;
    cursor: pointer;

    div:last-child {
      align-self: center;
    }

    p {
      margin: 0;
    }

    &--accordion {
      padding: rem-calc(30) $space2;
      flex-direction: column;
      align-items: flex-start;
      border-left: 5px solid $color-black;

      &:hover {
        .info-block__contact--name {
          .icon-plus-small, .icon-minus-small {
            color: $color-black;
            background-color: $color-brand-accent-1;
          }
        }
      }
      
      .info-block__links:last-child {
        margin-top: $space;
        align-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: flex-start;

      }

      .info-block__contact--name {
        display: flex;

        .icon-plus-small, .icon-minus-small {
          margin-right: 0.75rem;
          font-size: 8px;
          line-height: 1.2;
          color: #FFFFFF;
          background-color: #0160A9;
          text-align: center;
          width: 22px;
          border-radius: 50%;
          height: 22px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
        }

        .icon-black-bg {
          background-color: $color-black;
        }

      }

      a {
        color: $color-text;

        &:hover, &:focus {
          color: $color-primary-brand;
        }
      }

      .icon-list-circle {
        position: relative;
        top: 2px;
        margin-right: rem-calc(10);
        font-size: $font-size-smaller;
        text-decoration: none;

      }

      .expand {
        font-size: rem-calc(11);
        text-align: right;
        text-decoration: underline;
      
      }
    }
  }


  .preferred-accordion, .preferred-block {
    border-left: 5px solid $color-primary-brand;
  }

  &__name {
    position: relative;
    font-weight: bold;
    font-size: $font-size-smaller;
    margin-bottom: 0;

    .number {
      font-size: $font-size-smallest;
      position: absolute;
      left: -40px;
      top: -3px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 1px;
      background-color: $color-primary-brand;
      color: $color-white;
      width: 23px;
      height:23px;
      border-radius:  50%;
      
    }

    .number--black-bg {
      background-color: $color-black;

    }
  }

  &__distance {
    font-size: rem-calc(11);
    color: $color-gray;
    padding-bottom: rem-calc(10);
  }

  &__address {
    font-size: $font-size-mobile-smallest;
    padding-bottom: rem-calc(10);
  }

  &__contact {
    width: 60%;

    &--accordion {
      width: 100%;
    }
  }


  &__links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;

    a {
      margin-bottom: rem-calc(10);
     
    }
  }

  &__link {
    font-size: $font-size-mobile-smallest;
    margin-bottom: rem-calc(10);
    color: $color-body-text;
    display: block;

    &:hover, &:focus {
      color: $color-primary-brand;
    }
  }

  &__details,
  &__direction, icon-website-cursor {
    font-size: rem-calc(11);
    display: flex;

    &:hover, &:focus {
      color: $color-primary-brand;
    }

    span {
      align-self: center;
    }
  }

  &__form-link {
    overflow: hidden;
  }

  &__name,
  &__link--website,
  &__details,
  &__direction {
    color: $color-text;
  }

  &__name span {
    font-size: $font-size-smaller;
  }

  &__link--website,
  &__details span:last-child,
  &__direction span:last-child {
    text-decoration: underline;
  }

  &__icon {
    font-size: rem-calc(28);
    padding-right: rem-calc(11);
  }

  .js-brandsList {
    display: none;
  }

  &__brandsLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--image {
      max-width: rem-calc(94);
      -o-object-fit: contain;
      object-fit: contain;
      margin-bottom: $space3;
    }
  }

  &__accordion-blocks {
    border-left: 5px solid $color-black;

    &.preferred{
      border-left: 5px solid $color-primary-brand;

    }
    &.collapsed {
      display: none;
    }

  }
}
