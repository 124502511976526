$block: "article-detail-page";
$breakpoint-size: "large";

.#{$block} {
  &__content {
    padding: map-get($grid-column-gutter, $breakpoint-size)*2 map-get($grid-column-gutter, $breakpoint-size)*3 0;
    transform: translateY(rem-calc(-250));
    margin-bottom: rem-calc(-250);

    &--fall {
      transform: none;
      margin: 0 auto;
    }
  }

  &__attribution {
    padding-top: rem-calc(5);
  }

  hr {
    margin-top: $space-mid;
  }

  .featured-items {
    padding-top: 0;
    padding-bottom: 0;
  }

  .featured-items__cards {
    .featured-card__cta {
      width: rem-calc(175);
      height: rem-calc(45);

      .view-detail-cta {
        width: 100%;
      }
    }
  }

  .rte-block {
    .twoX-content-block {
      .image-block {
        height: rem-calc(345);
      }
    }
  }
}