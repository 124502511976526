$block: "region-language-selector";
$breakpoint-size: "large";

.#{$block} {
  width: 100vw;
  position: inherit;
  top: inherit;

  &.is-active {
    position: absolute;
    height: 100vh;
    overflow: auto;
    background: rgba(0, 0, 0, 0.70);
  }

  &__flat {
    //max-width: $global-width;
    margin: auto;
    display: block;
    background: $color-white;
  }

  .countries-selector--reg {
    padding: $space-large;

    .countries-selector__title {
      margin-bottom: $space-mid;
      font-size: $font-size-medium;
      padding: 0 $space4;
    }

    .countries-selector__column {
      width: 100%;
    }

    .countries-selector__list {
      display: flex;
      width: 100%;
      margin: auto;
    }

    .countries-selector__countryCol {
      width: auto;
      padding-right: rem-calc(16);
    }

    .countries-selector__langCol {
      width: auto;
    }

    .countries-selector__item {
      padding: 0 $space4;
    }
  }

  .countries-selector__title,
  .countries-selector__item {
    border: none;
  }

  .region-selector-block__wrapper {
    text-align: center;
    padding: $space-larger 0;
  }

  &__menu {
    display: none;
  }
}
