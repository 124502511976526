$block: "threeX-content-block";
$breakpoint-size: "large";

.#{$block} {

  .overview-text__heading {
    font-size: rem-calc(18);
    margin: 0 auto;
  }

  &__blocks {
    .overview-text__heading {
      font-size: rem-calc(24);
      margin: 0;
    }

    .content-block {
      &:first-child {
        padding-right: 12px;
      }
  
      &:last-child {
        padding-left: 12px;
      }
    }
  }

  .content-block {
    .image-block,
    .video-block,
    .video-block__video {
      img {
        max-height: 345px !important;
      }
    }
  }
}
