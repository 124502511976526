$block: "brands-hero";
$breakpoint-size: "medium";

.#{$block} {

  &__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &__column {
    width: 66.66%;
  }

  .hero-image {
    picture, img {
      object-fit: cover;
    }
  }

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h1);
  }

  &__cta {
    max-width: inherit;

    .cta {
      margin-right:rem-calc(22);
      margin-bottom: 0;
    }
  }
}
