$block: "product-description";
$breakpoint-size: "small";

.#{$block} {
  padding-left: $space5;
  padding-right: $space5;

  &__name {
    color: $color-text;
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    font-family: $font-bold;
    margin: 0;
  }

  &__category {
    font-family: $font-medium;
    line-height: $line-height-large;
    color: $color-body-text-2;
    font-size: map-get(map-get($typography, $breakpoint-size), h4);
    margin: 0;
  }

  &__detailsContainer {
    button.pdp-primary-cta {
      margin-right: 30px;
    }
  }

  .rte-block {
    padding: 0 0 map-get($grid-column-gutter, $breakpoint-size);

    p,
    li {
      color: $color-body-text;
      font-size: map-get(map-get($typography, $breakpoint-size), p);
    }
  }

  .brand-logo {
    // display: block;
    // padding: 0;
    // margin-bottom: rem-calc(5);
    // height: 100%;
	//
    // &__logo {
    //   text-align: left;
    // }
	//
    // &__wrapper{
    //   align-items:flex-start;
    // }
	//
    // img,
    // picture {
    //   height: rem-calc(76);
    //   width: rem-calc(120);
    // }
	//
    // img {
    //   object-fit: contain;
    //   object-position: 0;
    // }
  }

  //overwrite
  .pdp-primary-cta--request-quote {
    margin: 0;
  }
}
