$block: "visual-cue";
$breakpoint-size: "small";

.#{$block} {
  height: rem-calc(27);
  width: rem-calc(50);
  border-radius: rem-calc(4);
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: rem-calc(-13.5);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  z-index: 2;

  .icon {
    @include transition;
    color: $color-primary-brand;
    font-size: rem-calc(6);
  }

  &:hover, &:focus {
    .icon {
      color: $color-brand-accent-1;
    }
  }
}
