$block: "full-bleed-promo";
$breakpoint-size: "small";

.#{$block} {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: rem-calc(400);
	padding: rem-calc(54) rem-calc(20);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&__background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #003f80;
		background-blend-mode: multiply;

		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			filter: grayscale(100%);
			object-fit: cover;
		}
	}

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#003f80, .9);
		content: '';
	}

	&__content {
		position: relative;
		max-width: rem-calc(800);
		z-index: 1;

		h2,
		p {
			margin-bottom: rem-calc(21);
			color: $color-white;
			font-family: $font-regular;
		}

		h2 {
			font-size: $font-size-medium-3;
			font-weight: bold;
			line-height: 25px;
		}

		p {
			font-size: $font-size-smaller;
			line-height: 22px;
		}
	}

	&__btn-wrapper {
		display: flex;
		flex-direction: column;
	}

	&__download-btn,
	.learn-more-btn {
		width: fit-content;
		margin-bottom: rem-calc(22);

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__download-btn {
		$icon-size: rem-calc(22);

		display: flex;
		align-items: center;
		width: fit-content;
		transition: color .25s, background .25s;
		background-image: linear-gradient($color-brand-accent-1, $color-brand-accent-1);
		background-position: 32px 100%;
		background-repeat: no-repeat;
		background-size: 0 2px;
		color: $color-brand-accent-1;
		font-size: $font-size-mobile-standard;
		font-weight: bold;
		line-height: 17px;

		&:before {
			width: 22.86px;
			height: 16px;
			display: block;
			margin-right: rem-calc(8);
			background: url('../images/icons/icon-cloud-download.svg') no-repeat center / 100% auto;
			content: '';
		}

		&:hover,
		&:focus {
			background-size: 100% 2px;
			color: $color-white;
		}
	}
}
