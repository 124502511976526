$block: "facet-page";
$breakpoint-size: "medium";

.#{$block} {

  &__videos {

    .grid-x {
      .video-listing-page__video-container {
        padding: map-get($grid-column-gutter, $breakpoint-size) 0;
        padding-top: 0;
      }
    }
  }
}
