$block: "content-detail-page";
$breakpoint-size: "large";

.#{$block} {

  &__content {
    //max-width: none;
    & > .component.rte-block {
      max-width: 60rem;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
