$block: "list-block";
$breakpoint-size: "small";

.#{$block} {
  background: $color-background-lighter;
  padding: $space-larger $space5 $space-large;

  &__item {
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: map-get($grid-column-gutter, $breakpoint-size);
    max-width: 80px;
    width: 100%;
    height: 80px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast) {
      max-width: 100px;
      height: auto;
    }

    img {
      -o-object-fit: contain;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    width: 100%; // IE fix

    &--title {
      font-size: map-get(map-get($typography, $breakpoint-size), h3);
    }

    &--description {
      font-size: map-get(map-get($typography, $breakpoint-size), p);
    }
  }
}
