$block: "hbspt-form";
$breakpoint-size: "large";

.#{$block} {
  padding: 0;

  .hs-richtext {
    text-align: center;

    h1 {
      font-size: rem-calc(36);
    }

    p {
      font-size: rem-calc(14);
    }
  }

  .hs_submit {
    input {
      max-width: rem-calc(225);
    }
  }

  .legal-consent-container {
    .hs-richtext {
      text-align: left;

      p {
        font-size: rem-calc(14);
      }
    }

    .hs-form-booleancheckbox label {
      align-items: center;
    }

    span p {
      font-size: rem-calc(14);
    }
  }

  // REQUEST QUOTE CONFIRMATION
  .submitted-message {
      padding: rem-calc(25);

    p:first-child {
      font-size: rem-calc(32);
      line-height: 1.15;
    }

    p:not(:first-child) {
      font-size: $font-size-mobile-large;
      line-height: 1.45;
    }
  }
}