$block: "article-detail-page";
$breakpoint-size: "medium";

.#{$block} {

  &__content {
    max-width: $global-width-article-detail-content;
    padding: map-get($grid-column-gutter, $breakpoint-size)*2 map-get($grid-column-gutter, $breakpoint-size)*3 0;

    &--fall {
      transform: none;
      margin: 0 auto;
    }
  }

  .rte-block {
    .twoX-content-block {
      .image-block {
        height: rem-calc(300);
      }
    }
  }
}
