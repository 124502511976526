$block: "contact-us";
$breakpoint-size: "large";

.#{$block} {
  &__top {
    max-width: $global-width;
    margin: auto;
    padding-bottom: $space-larger;
    display: flex;
    justify-content: space-between;

    .page-title,
    h2 {
      font-size: rem-calc(42);
      padding-top: rem-calc(12);
    }

    &--column {
      max-width: rem-calc(425);

      .form-elem,
      .rte-block {
        padding: 0 0 0 $space-larger;
      }
    }
  }

  &__resetSearch {
    margin-left: $space-larger;
  }

  &__disclaimer {
    padding-bottom: rem-calc(30);
  }

  &__headquarters-details {
    padding: 0 $space-larger;

    .rte-block {
      padding: 0 0 rem-calc(30);

      h4 {
        font-family: $font-bold;
        font-size: map-get(map-get($typography, $breakpoint-size), p-small);
        color: $color-text;
        margin-bottom: 0;
      }

      a,
      p {
        font-size: rem-calc(16);
      }
    }
  }

  &__block {
    padding: 1px 60px;
  }

  &__map-container {
    height: rem-calc(481);
    width: rem-calc(761);
    padding: 0 rem-calc(11);
    background: $color-white;
  }
}