$block: "featured-product";
$breakpoint-size: "large";

.#{$block} {
	$gird-gutter-width: rem-calc(22);

	padding: rem-calc(100) rem-calc(15) rem-calc(95);

	&__category {
		margin-top: rem-calc(55);

		h3 {
			font-size: $font-size-standard;
			line-height: rem-calc(20);
		}
	}

	&__item-wrapper {
		width: calc(100% + #{$gird-gutter-width});
		margin-right: -($gird-gutter-width / 2);
		margin-left: -($gird-gutter-width / 2);
	}

	&__item {
		flex-basis: calc(#{percentage(1/6)} - #{$gird-gutter-width});
		margin-right: ($gird-gutter-width / 2);
		margin-bottom: $gird-gutter-width;
		margin-left: ($gird-gutter-width / 2);
		padding: rem-calc(8) rem-calc(8) 0;
	}

	&__expand-btn {
		margin-top: rem-calc(-8);
	}
}