$block: "nav-item";
$breakpoint-size: "medium";

.#{$block} {
  padding: map-get($grid-column-gutter, $breakpoint-size);
  padding-top: map-get($grid-column-gutter, $breakpoint-size)/2;

  &__link-label {
    span {
      font-size: map-get(map-get($typography, $breakpoint-size), nav-label);
    }
  }
}