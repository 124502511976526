$block: "brand-logo";
$breakpoint-size: "small";

$logo-size: rem-calc(32);

.#{$block} {
	&__wrapper {
		border: 2px solid rgba($color-primary-brand,0);
		padding: 0.7rem 1rem;
		width:100%;
		height: 100%;
		background: $color-white;
		display: flex;
		flex-flow: column nowrap;
		transition: border-color 0.3s;

		&:hover {
			border: 2px solid rgba($color-primary-brand,1);
		}
	}

	&__content {
		text-align: center;
		width: 100%;
	}

	&__logo {
		min-height: $logo-size * 2;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		img {
			max-height: $logo-size;
			max-width: $logo-size * 5;
			object-fit: contain;
			object-position: center;
		}
	}

	&__brand-name {
		margin-bottom: 0;
		line-height: 1.35;
	}

}
