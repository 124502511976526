$block: "loginBtn";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-white;
  text-transform: uppercase;
  line-height: 1.2;
  padding-right: 1rem;
  padding-left: 2rem;
  font-family: $font-bold;
  font-weight: 300;
  font-size: rem-calc(12);
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  height: rem-calc(40);
  width: auto;
  margin-right: 0;
  margin-left: auto;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: $color-white;
    width: rem-calc(3);
    height: rem-calc(45);
    z-index: 2;
    transform: rotate(160deg) translate3d(rem-calc(-8), 0, 0);
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-right: rem-calc(20) solid transparent;
    z-index: 1;
  }

  &:hover,
  &:focus {
    color: rgba(255, 255, 255, 0.8);
  }

  &:visited,
  &:focus {
    color: $color-white;
  }

  .icon {
    padding-right: map-get($grid-column-gutter, $breakpoint-size)/2;
    font-size: map-get(map-get($typography, $breakpoint-size), label);
  }

  /// Theme Color Modifier
  &__themeColorPrimary {
    @include transition;
    background: $color-primary-brand;

    &:hover, &:focus {
      background: $color-brand-accent-1;
      color: $color-text;
    }

    &:after {
      border-bottom: rem-calc(50) solid $color-text;
    }
  }

  &__themeColorSecondary {
    @include transition;
    background: $color-primary-brand-darker;

    &:hover, &:focus {
      background: $color-brand-accent-1;
      color: $color-text;
    }

    &:after {
      border-bottom: rem-calc(50) solid $color-primary-brand-dark;
    }
  }
}
