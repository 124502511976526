$block: "our-brands";
$breakpoint-size: "large";

.#{$block} {
	$btn-grid-gutter-width: rem-calc(26);
	$btn-wrapper-width: calc(70% + #{$btn-grid-gutter-width});

	padding: rem-calc(120) 0 rem-calc(40);

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: rem-calc(55);

		h2 {
			flex: 0 0 30%;
		}
	}

	&__btn-wrapper {
		flex: 0 0 $btn-wrapper-width;
		max-width: $btn-wrapper-width;
		margin-top: 0;
		margin-right: -($btn-grid-gutter-width / 2);
		margin-bottom: 0;
		margin-left: -($btn-grid-gutter-width / 2);
		border-bottom: 0;
	}

	&__filter-btn {
		flex-basis: calc(#{percentage(1/4)} - #{$btn-grid-gutter-width});
		margin-right: ($btn-grid-gutter-width / 2);
		margin-left: ($btn-grid-gutter-width / 2);
		padding: rem-calc(15) rem-calc(30);
		transition: background $transition-med;
		border: 1px solid #afafaf;
		border-radius: 22px;
		color: $color-black;
		font-size: rem-calc(12.5); // not in scale

		&:hover, 
		&.active {
			background: $color-white;
		}
	}

	&__logo-link {
		margin-bottom: rem-calc(58);
		flex-basis: percentage(1/6);

		img {
			max-height: rem-calc(40);
		}
	}
}
