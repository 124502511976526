$block: "brands-hero";
$breakpoint-size: "large";

.#{$block} {
  max-height: 420px;

  &__content {
    max-width: $global-width;
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }

  &__brandName {
    margin-top: rem-calc(60);
  }

  &__column {
    width: 61%;
  }

  .hero-image {
    picture, img {
      object-position: right center;
      object-fit: cover;
    }
  }

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h1);
  }

  .brands-hero__subTitle {
    font-size: rem-calc(42);
  }

  @media screen and (min-width: rem-calc(1428)) {
    .hero-image {
      picture, img {
        object-fit: contain;
      }
    }

  }
}
