$block: "search-results-nav";
$breakpoint-size: "small";

.#{$block} {
  background-color: $color-white;

  .results-found {
    padding: map-get($grid-column-gutter, $breakpoint-size) $space5;
  }

  &__results {
    font-size: 21px;
    margin-top: 35px;
    display: none;
  }

  &__links {
    width: 100%;
    max-width: rem-calc(644);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space rem-calc(3) $space 0;
    height: rem-calc(14);
    font-size: map-get(map-get($typography, $breakpoint-size), nav-label);
    font-weight: bold;
    line-height: rem-calc(11);
    color: rgba(255, 255, 255, 0.45);
    cursor: pointer;

    .icon {
      padding-right: rem-calc(7);
      font-size: map-get(map-get($typography, $breakpoint-size), button);
    }

    &.is-active {
      color: $color-white;
      padding-bottom: $space5;
      border-bottom: rem-calc(4) solid $color-primary-brand; // From Comps
      color: $color-white;
    }
  }

  &__sticky-nav {
    width: 100%;
    background-color: $color-black !important;
    padding: 0 $space5;
  }
}