$block: "pdp";
$breakpoint-size: "medium";

.#{$block} {
  &__content {
	  padding: map-get($grid-column-gutter, $breakpoint-size) 0;

    .component {
      background-color: $color-white;
    }
  }

  &-section {
    // change so that width is specified on .component so as not to restrict full-bleed elements
    //max-width: $global-width-pdp-comp;
    margin: 0 auto;

    &__header {
      display: none;
    }

    &__wrap {
      background-color: inherit;
      display: block;
      margin: 0;
    }
  }
}
