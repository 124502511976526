$block: "region-selector-hero";
$breakpoint-size: "large";

.#{$block} {
  &__wrapper {
    max-width: $global-width;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 rem-calc(60);
  }

  &__content {
    max-width: rem-calc(860);
    padding: 0;

    .rte-block {
      padding-top: rem-calc(50);

      h1 {
        font-size: rem-calc(58);
      }
    }
  }

  &__cta {
    flex-direction: row;

    .cta {
      margin-bottom: 0;
    }
  }

  &__logoImg {
    max-height: rem-calc(88);
    max-width: rem-calc(275);
  }

  &__footer {
    &--wrapper {
      padding-left: rem-calc(60);
      padding-right: rem-calc(60);
      width: 100%;
      max-width: $global-width;
      margin: auto;
    }
  }
}