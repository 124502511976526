$breakpoint-size: "small";

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $font-bold;
}

h1, .h1 {
  font-size: map-get(map-get($typography, $breakpoint-size), h1);
}

h2, .h2 {
  font-size: map-get(map-get($typography, $breakpoint-size), h2);
}

h2 {
  &.blue-border-heading {
    color: $color-black;
    font-size: $font-size-medium-3;
    line-height: 22px;

    &:after {
      display: block;
      width: 90px;
      height: 3px;
      margin-top: rem-calc(16);
      background: $color-primary-brand;
      content: '';
    }
  }
}

h3, .h3 {
  font-size: map-get(map-get($typography, $breakpoint-size), h3);
}

h4, .h4 {
  font-size: map-get(map-get($typography, $breakpoint-size), h5);
}

h5, .h5 {
  font-size: map-get(map-get($typography, $breakpoint-size), h5);
}

h6, .h6 {
  font-size: map-get(map-get($typography, $breakpoint-size), h6);
}
