$block: "contact-us";
$breakpoint-size: "small";

.#{$block} {
  &__top {
    h2 {
      font-size: rem-calc(32);
      padding: $space5 $space5 rem-calc(30);
      margin: 0;
    }
  }

  &__disclaimer .rte-block {
    p {
      font-size: rem-calc(11);
      color: $color-body-text;
    }
  }

  &__headquarters-details {
    padding: 0 $space5;

    .rte-block {
      padding: 0 0 rem-calc(30);

      h4 {
        font-weight: 600;
        font-size: map-get(map-get($typography, $breakpoint-size), h4);
        color: $color-text;
        margin-bottom: 0;
      }

      a, p {
        font-size: rem-calc(16);
      }

      p {
        margin-bottom: rem-calc(5);
      }
    }
  }

  &__resetSearch {
    cursor: pointer;
    color: $color-primary-brand;
    font-family: $font-bold;
    font-size: rem-calc(12);
    padding: $space5 0;
    margin-bottom: $space5;
    margin-left: rem-calc(22);

    &:hover {
      opacity: .8;
    }
  }

  &__showMap-btn {
    padding: rem-calc(22) $space5 rem-calc(11);
    background: $color-background-lighter;

    .cta {
      width: 100%;
      background: $color-white;
    }
  }

  &__map-container {
    height: rem-calc(551);
    width: 100%;
    padding: rem-calc(11) 0;
    background: $color-background-lighter;

    &--hidden {
      height: 0;
      padding: 0;
    }
  }

  &__block {
    padding: rem-calc(11) $space5;
    background: $color-background-lighter;
  }
}