$block: "featured-items";
$breakpoint-size: "large";

.#{$block} {
  margin: rem-calc(40) (-$space2) 0;
  padding: rem-calc(40) map-get($grid-column-gutter, $breakpoint-size) $space-largest;


  .overview-text {

    &__description {
      display: none;
    }
  }

  &__card {
    .featured-card {
      margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
    }
  }

}
