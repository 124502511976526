$block: "overview-content";
$breakpoint-size: "large";

.#{$block} {
  max-width: $global-width-pdp-comp;

  .view-detail-cta {
    margin-top: $space-large;
  }

  &--large-vertical {
    margin-bottom: $space-large;
    padding: 0;

    .img-container {
      margin-top: $space;

      &__wrapper {
        display: flex;
        flex-direction: row;
      }
    }

    .view-detail-cta {
      margin-top: 0;
    }
  }
}
