$block: 'geo-locator-popup';
$breakpoint-size: 'large';

.#{$block} {
	padding: rem-calc(50) rem-calc(40) rem-calc(60);

	&__close {
		top: 7px;
		font-size: rem-calc(30);
	}

	&__content {
		h2 {
			margin-bottom: rem-calc(16);
			font-size: rem-calc(36);
		}

		p {
			margin-bottom: rem-calc(32);
			font-size: rem-calc(16);
		}

		.cta {
			display: inline-block;
			width: fit-content;
			margin: 0 rem-calc(12) 0 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__flag {
		width: 70px;
		height: 52px;
	}

	&__logo {
		width: 120px;
	}
}
