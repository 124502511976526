$block: "full-width-image-block";
$breakpoint-size: "small";

.#{$block} {

  .hero-image {
    position: static;
    height: auto;
    width: auto;
    max-width: 100%;

    &:before {
      display: none;
    }

    picture {
      display: block;
      position: static;
      background-color: $color-white;
    }

    img {
      object-fit: contain;
      background-color: $color-white;
    }
  }

}
