$block: "hero-content";
$breakpoint-size: "large";

.#{$block} {

  &__text {
    max-width: 50%;

    &--company-name {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }

    &--large-header {
      width: 100%;
    }

    &--description {
      p {
        font-size: map-get(map-get($typography, $breakpoint-size), label);
      }
    }
  }

  &__wrapper {
    max-width: $global-width;
    padding: $space-large map-get($grid-column-gutter, $breakpoint-size);
  }

  &--solid-background {
    padding-top: 0;
  }
}
