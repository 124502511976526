$block: "banner-content";
$breakpoint-size: "medium";

.#{$block} {

  &__wrapper {
    max-width: 75%;
    padding: $space-larger $space-mid;
  }

}
