$block: "stats-card";
$breakpoint-size: "medium";

.#{$block} {
  padding: 0 map-get($grid-column-gutter, $breakpoint-size);

  &__wrapper {
    padding: $space-large;
    width: 100%;
    max-width: $global-width-pdp-comp;
    margin: auto;

    ul {
      display: flex;
      justify-content: space-around;
      flex-flow: row wrap;
    }
  }

  &__item {
    margin: 0;
    padding: 0 0 $space4;
  }
}
