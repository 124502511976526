$block: "hero";
$breakpoint-size: "large";

.#{$block} {

  &--set-height {
    min-height: rem-calc(524); // per comps
  }

  // SLIDING HERO
  &.slider-hero {

    .slider-hero__thumbnails {
      li {

        &.tns-nav-active {
          span {
            display: block;
          }
        }

        &:hover {
          background-color: #282828;
          position: relative;

          .thumbnail-progress {
            background-color: transparent;
            width: 100%;
          }

          &.tns-nav-active {
            .thumbnail-progress {
              background-color: $color-primary-brand;
              width: 100%;
            }
          }
    
          .thumbnail-text-mobile {
            display: none;
          }

          .thumbnail-image {
            &::before {
              display: none;
            }

          }
        }

      }

      span {
        position: relative;
        width: 50%;
        padding: 0 rem-calc(17);
      }

      .thumbnail-image {
        width: 50%;
      }

      .thumbnail-text {
        align-self: center;
			}

			.thumbnail-text-mobile {
				display: none;
			}
    }
  }

}
