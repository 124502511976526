$block: "split-promo-with-media";
$breakpoint-size: "small";

.#{$block} {
	background: $color-black;

	&__text-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: rem-calc(48) rem-calc(20);
	}

	&__smaller-title {
		display: block;
		margin-bottom: rem-calc(15);
		color: $color-white;
		font-family: $font-regular;
		font-size: $font-size-small;
		font-weight: bold;
		line-height: 19px;
		text-transform: uppercase;
	}

	&__title {
		margin-bottom: rem-calc(16);
		color: $color-primary-brand-lighter-2;
		font-family: $font-regular;
		font-size: $font-size-medium-3;
		line-height: 32px;
	}

	&__desc {
		margin-bottom: rem-calc(28);
		color: rgba($color-white, .86);
		font-size: $font-size-smaller;
	}
}
