$block: "region-selector-block";
$breakpoint-size: "medium";

.#{$block} {
  max-width: $global-width;
  margin: auto;

  &__textWrapper {
    display: block;
  }

  &__wrapper {
    flex-direction: row;
    padding-top: rem-calc(50);
  }

  &__title {
    font-size: rem-calc(32);
  }

  &__region-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: $space5;
    text-align: left;

    &--title {
      font-size: $font-size-medium-2 !important;
    }

    &--subLink {
      @include underlineLink;
      display: inline !important;
      font-size: $font-size-small !important;
  
      &:nth-child(n+3) {
        position: relative;
        margin-left: 34px;
  
        &:before {
          position: absolute;
          top: rem-calc(4);
          left: -20px;
          display: block !important;
          width: 2px;
          height: rem-calc(14);
          background: $color-primary-brand;
          content: "";
        }
      }
  
    }

    

    &--additional-sites {
      font-size: $font-size-smaller;
      margin-bottom: rem-calc(10);
      margin-top: rem-calc(15);

      &-wrapper {
        display: block;
        max-width: 430px;
      }

      &-wrapper  &-link {
        @include underlineLink;
        margin-bottom: 0;
        font-size: $font-size-smallest !important;

        &:nth-child(n+3) {
          position: relative;
          margin-left: 25px !important;
  
          &:before {
            position: absolute;
            top: 7px;
            left: -15px;
            display: inline-block !important;
            width: 1px;
            height: rem-calc(10);
            background: $color-primary-brand;
            content: "";
          }
        }
      }

      &:before {
        position: relative;
        top: 11px;
        left: -13px;
        display: inline-block;
        width: 100px;
        height: 1px;
        background-color: $color-gray-4;
        content: '';
      }
      &::after {
        position: relative;
        top: 11px;
        right: -13px;
        display: inline-block;
        width: 100px;
        height: 1px;
        background-color: $color-gray-4;
        content: '';
      }
    }

    &--item {
      flex-grow: 1;
      width: 33%;
      margin-bottom: rem-calc(20);
      border: none;

      &:last-child {
        border: none;
      }
    }

    &--link {
      padding-top: rem-calc(23);
      color: $color-gray-3;
      font-size: $font-size-medium;
    }
  }

  &__image {
    height: rem-calc(250);
    margin-bottom: $space4;
  }

  &__textWrapper {
    padding: 0;
    text-align: center;
  }
}

// there's some kind of very powerful drilldown class that I can't seem to find. Overriding.
.drilldown .region-selector-block__region-nav--subLink {
  @include underlineLink;
}