$block: "quote-block";
$breakpoint-size: "medium";

.#{$block} {
  &--testimonial {
    width: 100%;
    max-width: inherit;

    .#{$block} {
      &__wrapper {
        max-width: $global-width-mid;
        margin: auto;
      }

      &__text {
        padding: 0 rem-calc(30);

        &:before,
        &:after {
          top: rem-calc(10);
          font-size: rem-calc(40);
        }

        &:after {
          right: rem-calc(-22);
        }
      }
    }
  }
}