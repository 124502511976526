$block: "link-list";
$breakpoint-size: "medium";

.#{$block} {

  &--pdp-sticky-nav {
    margin-right: map-get($grid-column-gutter, $breakpoint-size);

    li {
      margin: 0 map-get($grid-column-gutter, $breakpoint-size);
    }
  }
}