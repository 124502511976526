$block: "featured-card";
$breakpoint-size: "small";

.#{$block} {
  padding: 0 rem-calc(5);
  max-width: rem-calc(200);

  &__wrapper {
    display: block;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: $color-white;
    border: rem-calc(1) solid $color-border;
    padding: rem-calc(10 15);
    height: 100%;
  }

  &__legacy {
    display: inline-block;
    background-color: $color-gray-4;
    font-size: $font-size-smallest;
    padding: rem-calc(5 10);
    margin-bottom: rem-calc(15);
    font-family: $font-bold;

  }

  &__banner {
    position: absolute;
    display: flex; 
    align-content: center;
    height: 72px;
    width: 80px;
    max-width: 100%;
    margin-top: 15px;
    background-color: $color-white;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 3px 3px 0 0 rgba(0,0,0,0.08);

    img {
      padding: 10px; 
      width: 100%;
      height: auto;
      max-height: 45px;
      align-self: center;
    }
  }

  &__image {
    width: 100%;
    max-width: rem-calc(150);
    height: rem-calc(150);
    margin: 0 auto;

    img, picture {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  &__text {
    text-align: left;
    padding-top: rem-calc(18);
    line-height: $line-height-small;

    &--center {
      text-align: center;
    }

    &--title {
      @include transition;
      text-transform: capitalize;
      font-family: $font-bold;
      font-size: map-get(map-get($typography, $breakpoint-size), p);
      color: $color-text;
      margin: 0;
      &:hover, &:focus {
        color: $color-primary-brand;
      }
    }

    &--subtitle {
      font-family: $font-medium;
      font-weight: 500;
      color: $color-body-text-2;
      font-size: rem-calc(11);
    }

    &--brand,
    &--meta {
      font-family: $font-bold;
      text-transform: uppercase;
      font-size: rem-calc(11);
      color: $color-gray-3;
    }

    &--brand {
      padding-bottom: $space5;
    }

    &--meta {
      padding-top: $space;
    }

    &--icon {
      color: $color-primary-brand;
      padding-right: rem-calc(10);
    }

    &--description {
      font-size: rem-calc(11);

      span {
        color: $color-gray-3;
      }

      p {
        color: $color-body-text;
      }
    }

    p {
      margin: 0 0 rem-calc(10);
    }
  }

  &__cta {
    display: none;
    margin-top: auto;

    .cta {
      padding-left: rem-calc(32);
      padding-right: rem-calc(32);
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: elements aligned horizontally
  &--horizontally {
    max-width: rem-calc(220);
    padding: 0;

    .#{$block} {
      &__content {
        min-height: auto;
        margin: 0;
      }

      &__image {
        width: 100%;
        max-width: rem-calc(180);
        height: rem-calc(180);
        max-height: rem-calc(180);
      }

      &__text {
        &--subtitle {
          color: $color-body-text-2;
        }
      }

      &__cta {
        margin-top: 0;
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: login cards
  &--loginCard {
    max-width: rem-calc(649);
    padding: 0;

    .#{$block} {
      &__content {
        padding: $space-large $space-mid;
        min-height: auto;
        margin: 0;
      }

      &__text {
        padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
        padding-top: 0;

        &--title {
          padding-bottom: rem-calc(10);
          font-size: map-get(map-get($typography, $breakpoint-size), p-large);
        }
      }

      &__cta {
        display: inline-block;
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: Document
  &--document {
    max-width: none;
    margin: map-get($grid-column-gutter, $breakpoint-size) 0;
    padding: 0;

    &:first-of-type {
      margin-top: 0;
    }

    .#{$block} {
      &__content {
        min-height: auto;
        margin: 0;
      }

      &__text {
        &--title {
          word-break: break-word;
          font-size: map-get(map-get($typography, $breakpoint-size), p-large);
        }
      }

      &__cta {
        display: inline-block;
        width: 100%;
        padding-top: map-get($grid-column-gutter, $breakpoint-size);
        margin-right: 0;

        .cta {
          width: 100%;
        }
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: Content
  &--content {
    padding: 0;

    .#{$block} {
      &__text--title {
        color: $color-primary-brand;
      }

      &__cta {
        margin-top: 0;
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: ARTICLE
  &--article {
    .#{$block} {
      &__text {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        &--description {
          flex: 1 1 auto;
        }

        &--meta {
          margin-top: auto;
        }
      }
    }
  }
}
