$block: "pim-block";
$breakpoint-size: "small";

.#{$block} {
  padding-top: rem-calc(30);

  .row {
    justify-content: space-between;
    align-items: flex-start;
  }
  .column {
    max-width: 48%;
  }
}