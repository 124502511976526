$block: "hero-image";
$breakpoint-size: "small";

.#{$block} {
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  margin-left: 0;
  position: absolute;
  z-index: 0;
  &:before {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    position: absolute;
    background: $color-black;
    background: linear-gradient(90deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.65) 80%, rgba(0,0,0,0.5) 100%);
    z-index: 1;
  }

  picture {
    display: inline-block;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  picture, img {
    object-position: 50% 0;
    height: 100%;
    max-width: none;
    object-fit: cover;
    width: 100%;
    background-color: $color-black;
  }
}
