$block: "newsroom-block";
$breakpoint-size: "small";

.#{$block} {
  @include pageContainer();

  &.component {
    margin: 0 auto;
    padding: rem-calc(40) rem-calc(20) rem-calc(60);
  }

  .post-card {
    width: 100%;
    padding-bottom: rem-calc(15);
    margin-bottom: rem-calc(15);
    border-bottom: 1px solid $color-gray-5;

    &__content {
      display: flex;
      flex-direction: column;
      max-width: none;
      padding-top: 0;

      a,
      &--cardTag,
      &--description {
        position: relative;
      }

      a {
        order: 1;
        margin: 0 0 rem-calc(10);
      }

      &--cardTag {
        position: relative;
        display: block;
        order: 3;
        margin: 0;
        padding: 0;
        background: transparent;
        color: $color-gray;
        font-size: $font-size-smallest;
        line-height: 13px;
      }

      &--description {
        display: block;
        order: 2;
        margin: 0 0 rem-calc(10);
        p {
          margin-bottom: 0;
          font-size: $font-size-smaller;
          line-height: 22px;
        }
      }

      &--title {
        font-size: rem-calc(14);
        line-height: 21px;
      }
    }

    &__image {
      width: 112px;
      height: 112px;
      border: 1px solid $color-gray-5;
    }

    &--featured {
      .post-card__content {
        padding-top: rem-calc(12);
      }

      .post-card__content--title {
        font-size: rem-calc(18);
        line-height: 27px;
      }

      .post-card__image {
        width: 100%;
        height: 0;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 100%;
    margin-bottom: rem-calc(29);
  }

  .learn-more-btn {
    border-color: $color-primary-brand;
    color: $color-orange;

    &:after {
      background-image: url('../images/icons/icon-long-arrow-right-blue.svg');
    }

    &:hover,
    &:focus {
      border-color: $color-brand-accent-1;
      color: $color-black;

      &:after {
        filter: brightness(0);
      }
    }
  }

  &__view-all-desktop {
    display: none;
  }

  &__view-all-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem-calc(22);
  }
}
