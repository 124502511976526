$block: "product-by-brands";
$breakpoint-size: "medium";

.#{$block} {

    h4 {
        font-size: map-get(map-get($typography, $breakpoint-size), h4);
        width: 52%;
    }

    &__product-list {
        display: flex;
        flex-direction: column;
        flex-basis: 25%;
        align-items: center;
        margin-top: rem-calc(20);

        img {
            width: 227px;
            margin-bottom: rem-calc(20);

        }

        ul {
            max-width: 243px;
            min-width: 243px;
        }

    }

}