$block: "quick-links-secondary";
$breakpoint-size: "large";

.#{$block} {
  &__large {
    display: none;
    background: $color-white;
    border-bottom: 1px solid $color-border;
	  padding: 0 3.75rem;
  }

  &__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: rem-calc(45);

    &--socialMedia {

      a {
        padding:0 !important;

        .icon:before {
          font-size: rem-calc(18);
          margin-right: rem-calc(5);
        }

      }
    }

    &--social {
      display: flex;
      padding: 0 rem-calc(10);
    }

    &--link {
      .dropdown-arrow {
        &:after {
          display: inline-block;
          @include iconStyles;
          content: map-get($icons, icon-arrow-small);
          font-size: 6px; //using rem-calc here appears to not enable the icon to get small enough, but switching to pixels does
          position: relative;
          left: 5px;
          bottom: 1px;
        }
      }

      &:hover {
        span:nth-of-type(2) {
          color: $color-primary-brand;
        }
      }
    }

    .dropdown-pane {
      transform: translate(-50%, 0);
      top: rem-calc(51) !important;
      border: 1px solid $color-border;
      box-shadow: 0 0 4px 1px $color-gray-light;
      padding: $space4 $space-mid;
      min-width: rem-calc(160);
      width: fit-content;

      div {
        background: $color-white;
        padding: rem-calc(10 16);
      }

      a {
        font-size: rem-calc(14);
        display: block;
        color: $color-body-text;
        padding: rem-calc(7) 0;

        &:hover {
          color: $color-primary-brand;
        }
      }
    }

    li.hover {
      .dropdown-arrow:after {
        transform: rotate(180deg);
      }
    }

    & > li {
      padding: 0 rem-calc(20);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__rte {
    a,
    p {
      padding: 0 $space5;
      display: inline-block;
    }

    a:hover {
      color: $color-primary-brand;
    }
  }

  //MODIFIER Dark
  &--dark {
    .quick-links-secondary__large {
      background: $color-black;
    }

    .quick-links-secondary__contact .dropdown-pane {
      background: $color-black;
      border: 1px solid black;
      box-shadow: 0 0 4px 1px #222;

      a {
        color: $color-white;

        &:hover {
          color: $color-primary-brand;
        }
      }
    }
  }
}
