$block: "form-elem";
$breakpoint-size: "small";

.#{$block} {
  padding: 0 $space5;

  &__label {
    font-family: $font-bold;
    text-transform: uppercase;
    font-size: map-get(map-get($typography, $breakpoint-size), label);
    margin-bottom: rem-calc(10);
  }

  select,
  input,
  textarea {
    border-radius: $border-radius;
    border: $border-thin solid $color-border-input;
    padding: rem-calc(12) $space5;
    height: rem-calc(48);
    font-family: $font-light;
    color: $color-gray-2;
    text-transform: capitalize;

    @include placeholder {
      color: $color-placeholder;
    }

    &:focus {
      border: 1px solid rgba($color-primary-brand, 0.5);
    }
  }

  textarea {
    height: rem-calc(149);
    margin-bottom: rem-calc(26);
  }
}
