$block: "footer";
$breakpoint-size: "large";

.#{$block} {
  &__top,
  &__bottom--wrapper {
    max-width: $global-width;
    margin: auto;
    padding-left: $space-larger;
    padding-right: $space-larger;
  }

  &__bottom  {
    max-width: none;
  }

  &__top {
    padding-top: $space-largest;
    padding-bottom: $space-largest;

    a {
      //padding: rem-calc(5) 0;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    justify-content: space-between;
    flex-basis: 41%;
  }

  &__brandLogo {
    margin: 0 0 $space-large;
  }

  &__socialMedia {
    padding: 0;
  }

  &__text {
    color: $color-white;
    font-family: $font-regular;
    padding-bottom: $space-large;

    &--heading,
    p:first-child {
      font-family: $font-bold;
      font-size: $font-size-smallest;
      text-transform: uppercase;
    }

    &--link,
    p:not(:first-child) {
      opacity: .75;
      font-size: $font-size-smaller;
      line-height: $line-height;
    }

    &--link {
      @include underlineLink;
    }

    p {
      margin: 0;
    }

    li {
      list-style: none;
      margin-bottom: rem-calc(10);
    }
  }

  h5 {
    padding-left: 0;
    color: $color-white;
    font-family: $font-bold;
    font-size: $font-size-smallest;
    text-transform: uppercase;
  }

  &__bottom {
    padding-top: $space5;
    padding-bottom: $space5;

    &--wrapper {
      display: flex;

      p:last-child:before {
        content: "|";
        position: absolute;
        left: rem-calc(-2);
        top: 0;
      }
    }

    &--text,
    &--link,
    &--link a {
      font-size: $font-size-smaller;
    }

    &--link {
      position: relative;
      padding: 0 rem-calc(5);
    }
  }

  // subscribe block
  .subscribeBox {
    margin-right: $space-mid;
    padding: 0 0 $space-large;
    width: rem-calc(330);
  }

  //links hovering

  &__top {
    a:hover {
      color: $color-white;
    }
  }

  &__socialMedia a .icon:before {
    &:hover {
      color: $color-primary-brand;
    }
  }

  &__bottom--link a {
    &:hover {
      opacity: .8;
    }
  }

  &__socialMedia a:hover,
  &__socialMedia a:focus {
    .icon:before {
      color: $color-primary-brand;
    }

    .icon-instagram:before {
      background-image: url('../images/icons/instagram-logo-hover.svg');
    }

    .icon-x:before {
      background-image: url('../images/icons/x-logo-hover.svg');
    }
  }

  .rte-block {
    padding: 0;

    p {
      font-size: $font-size-smaller;
    }
  }
}
