$block: "document-library-product-line";
$breakpoint-size: "large";

.#{$block} {

	&__help {
		flex-direction: row;

		&-info {
			width: 75%;
		}

		&-contact {
			width: 25%;
			border: none;
			margin: 0;
			padding: 0;
			border-left: 1px solid rgba(0,0,0,.25);
			padding-left: 25px;
		}
	}

	//react portion of the page
	.dlpl {
		&.document-library__results {
			.document-library__results-cards.grid-view .document-library__resource-card {
				flex: 0 0 25%;
			}
		}
	}

}
