$block: "location-block";
$breakpoint-size: "small";

.#{$block} {
  &__head {
    background: $color-text;
    padding: rem-calc(7 20);

    h1 {
      margin-bottom: 0;
      color: $color-white;
      font-size: rem-calc(10);
      font-family: $font-bold;
      text-transform: uppercase;
    }
  }
}