$block: "quick-links";
$breakpoint-size: "large";

.#{$block} {
  &.is-stuck {
    .brand-logo {
      visibility: visible;
    }
  }

  //modifier v2
  &--wrapped {
    max-width: 1176px !important;
    margin: auto;

    &.is-stuck {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}