$block: "link-list";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  align-items: center;

  &__item {
    padding: rem-calc(8);
    display: none;

    // dropdown arrow
    .global-nav__dropdown &.dropdown-arrow.js-toggleDropdown:after,
    &.dropdown-arrow:after {
      display: inline-block;
      @include iconStyles;
      content: map-get($icons, icon-arrow-small);
      font-size: $arrow-size;
      position: relative;
      bottom: 1px;
      right: -2px;
      border: 0;
      color: $color-white;
    }

    &.is-active {
      &.dropdown-arrow:after {
        transform: rotate(180deg);
      }
    }

    &.display-small {
      display: inline;
    }
  }

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    list-style: none;

    .dropdown-content {
      display: none;
      width: 100%;
      z-index: 9999;
      position: relative;
      padding: rem-calc(0) rem-calc(60);
      padding-left: 0;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      box-shadow: 0 0 25px 0 rgba(0,0,0,0.3);

      &--open {
        display: flex;

      }
      
      a {
        @include transition;
        color: $color-body-text;
        white-space: nowrap;
        padding: rem-calc(5) 0;
        text-decoration: none;
        background-image: linear-gradient(#FF9500, #FF9500);
        background-position: 0% 76%;
        background-repeat: no-repeat;
        background-size: 0% 2px;
        margin-left: rem-calc(15);
        font-size: $font-size-smaller;
        font-weight: 400;
        font-family: $font-regular;

        &:hover, &:focus {
          color: $color-body-text;
          background-size: 100% 2px;
        }
      }
    }
  }

  &--mobile-dropdown {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: $color-black!important;
    margin-left: 0;
    padding-bottom: $space4!important;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      border-bottom: none !important;
      height: auto !important;


      a {
        @include transition;
        background: $color-black !important;
        color: $color-white !important;
        padding-top: $space3!important;
        padding-bottom: $space3!important;

        &:hover, &:focus {
          color: $color-primary-brand-light!important;
        }
      }
    }

    .#{$block} {
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-white;
        font-weight: bold;
        font-size: map-get(map-get($typography, $breakpoint-size), p);

        img {
          height: rem-calc(12);
          padding-right: map-get($grid-column-gutter, $breakpoint-size)/2;
        }
      }
    }
  }

  &--pdp-sticky-nav {
    background-color: $color-black !important;
    justify-content: space-between;
    font-family: $font-bold;
    overflow: visible !important;

    li {
      margin: 0 map-get($grid-column-gutter, $breakpoint-size);

      &:first-child {
        margin-right: 0;
        margin-left: 0 !important;
      }
    }

    .link-list__item {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      color: $color-gray-light-2;
      text-transform: uppercase;
      padding: rem-calc(15) 0 rem-calc(14) 0;
      display: flex;

      &.is-active {
        color: $color-white;
        border-bottom: rem-calc(4) solid $color-primary-brand; // From Comps
        margin-bottom: rem-calc(-2); // From Comps
      }
    }
  }

  &--utility-nav {
    flex-grow: 1;
    font-family: $font-bold;
    font-weight: 300;
    font-size: rem-calc(12);
    line-height: $line-height;

    li:first-child {
      margin-left: auto;
    }

    img {
      height: rem-calc(12);
      width: rem-calc(20);
      position: relative;
      right: rem-calc(8);
      bottom: rem-calc(1);
    }
  }
}
