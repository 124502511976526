$block: "article-listing-page";
$breakpoint-size: "large";

.#{$block} {
  &__content {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .featured-card {
    max-width: $global-width;
    margin-top: 0;

    &__content {
      padding: $space-large;
    }

    &__text {
      max-width: rem-calc(607);
      padding-left: $space-large;
    }

    &__cta {
      width: auto;
      min-width: rem-calc(175);

      .cta {
        min-width: rem-calc(175);
      }
    }

    &.featured-card--horizontally .featured-card__image {
      width: 100%;
    }
  }

  .facet-pagination {
    margin-top: rem-calc(22);
  }
}