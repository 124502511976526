$block: "featured-slider";
$breakpoint-size: "large";

.#{$block} {

  max-width: $global-width;
  margin: 0 auto;
  padding: 0 map-get($grid-column-gutter, $breakpoint-size);

  .featured-card {
    &__content {
      display: flex;
      flex-direction: column;
    }

    &__cta {
      margin-top: auto;
    }
  }
}
