$block: "brands-multi-content-block";
$breakpoint-size: "small";

.#{$block} {
  	background-color: $color-gray-lighter;
  	width: 100%;
  	padding: $space-mid (map-get($grid-column-gutter, $breakpoint-size) - $brand-grid-spacing-sm) 0;

  &__brand {
    margin: $brand-grid-spacing-sm 0;

	.brand-logo {
		padding: 0 $brand-grid-spacing-sm;
		height: 100%;
	}

    img {
      width: auto; // IE fix
    }
  }
}

.utility-nav {
  .#{$block} {
    padding-top: $space4;
	padding-bottom: $space4;
  }
}
