$block: "contact-us";
$breakpoint-size: "medium";

.#{$block} {
  .hero-breadcrumbs {
    position: relative;

    li {
      a,
      &:after,
      &:last-of-type {
        color: $color-text!important;
      }
    }
  }
}