$block: "video-block";
$breakpoint-size: "large";

.#{$block} {

  picture,
  img {
    width: 100%;
  }

  &__play-btn {
    &:before {
      transform: translateX(3px);
      border-width: 14px 0 14px 24.2px;
    }

    &:after {
      $circle-size: rem-calc(82);
      width: $circle-size;
      height: $circle-size;
    }
  }
}