$block: "facets";
$breakpoint-size: "small";

.#{$block} {
  position: absolute;
  right: 0;
  width: 90%;
  min-height: 100vh;
  background-color: $color-white;
  z-index: 9999;
  padding: $space5;

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width:100vw;
    min-width: 100%;
    height:100%;
    background-color: rgba(0,0,0,0.6);
    overflow: auto;
    z-index: 9990;
    overflow-x: hidden;
  }

  &__checkbox {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: $space3;
    border: 1px solid $color-black;
  }

  hr {
    max-width: none;
  }

  &__applied-option__category {
    padding-right: rem-calc($space-smallest);
    padding-left: rem-calc($space-smallest);
    font-family: $font-medium;

  }

  &__category-header {
	  display: flex;
	  flex-flow: row nowrap;
	  justify-content: space-between;
	  align-items: center;
  }

  &__category {
    padding-left: map-get($grid-column-gutter, $breakpoint-size);

    .show-all {
      padding-top: rem-calc(10);
      color: $color-primary-brand;
      font-family: $font-medium;
      font-size: $font-size-smaller;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .hide {
    display: none;
  }

  &__filter-options-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .close-nav {
      display: inline-block;
      height: 100%;
      background-color: transparent;
      & .icon::before {
        color: $color-black;

      }
    }

    hr{
      display: block;
      width: 100%;

    }
  }

  &__filter-options-title, &__filters-applied {
    color: $color-text;
    font-family: $font-medium;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;

    hr {
      margin-top: rem-calc(10);
    }
  }

  &__filters-applied {
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);

    ul {
      li {
        overflow: hidden;
      }
    }
  }

  &__applied {
    display: flex;
    flex-direction: column;
    margin-bottom: $space;
    padding: map-get($grid-column-gutter, $breakpoint-size);
    padding-top: rem-calc($space4);
    background-color: $color-white;

  }

  &__filter-options__title {
    font-family: $font-bold;
    font-size: $font-size-smaller;
    font-weight: bold;
    line-height: rem-calc(14);
    text-transform: uppercase;
    overflow: hidden;
  }

  &__category {
    display: none;
    padding-left: 0;

    &.active {
      display: block;
    }

  }

  .icon-arrow-small {
    display: flex;
    justify-content: flex-end;
    height: rem-calc(8);
    color: $color-primary-brand;
	padding-right: $space2;

    &:before {
      font-size: 6px;
    }

    &.active {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

 &__applied-option {
   margin-bottom: $space3;

 }

  &__option, &__applied-option {
    color: $color-gray-3;
    font-size: 14px;
    opacity: 85%;
  }

  &__option {
    padding-top: map-get($grid-column-gutter, $breakpoint-size);
    overflow: hidden;

    .icon-checkmark{
     display: none;
    }
  }

  &__options-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .name {
      width: 95%;

      span {
        margin-left: rem-calc($space-smallest);
      }
    }

  }

  &__option.facets__selected {
    position: relative;
    color: $color-primary-brand;

    .icon-checkmark{
      position: relative;
      display: inline;
      vertical-align: top;
      top: -1px;
      left: 1px;

      &:before {
        font-size: 9px;
        vertical-align: middle;

      }

    }

  }

  &__applied-option {
    display: flex;
    align-items: flex-start;
    height: auto;
    margin-top: 2px;
    padding-top: 1px;
    color: $color-text;
    white-space: wrap;


    &__title {
      overflow: hidden;
    }
  }

  &__applied-option--clear-all {
    margin-bottom: map-get($grid-column-gutter, $breakpoint-size);

    .facets__applied-option__title {
      padding-left: rem-calc($space-smallest);
      color: $color-gray-2;
    }

    .icon-close-menu {
      background-color: $color-brand-accent-1 !important;
    }
  }

  ul {
    list-style-type: none;
  }

  &__close {
    display: flex;
    justify-content: center;
    width: auto;
    margin-top: rem-calc(3);
    cursor: pointer;

    .icon-close-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      height: 17px;
      border-radius: 3px;
      background-color: $color-primary-brand;
      color: $color-white;

      &:before {
        margin-left: 1px;
        font-size: 7.66px;
      }
    }
  }

  &__filters-mobile-header {
    .icon-close-menu {
      display: flex;
      justify-content: center;
      width: rem-calc(17);
      margin-bottom: map-get($grid-column-gutter, $breakpoint-size);
      cursor: pointer;
    }
  }

  &__filters-mobile-title {
    color: $color-text;
    font-size: 18px;
    font-weight: 500;
  }

  &__gif-loader {
    width: 100%;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &--as-filters {
    .facets__applied {
      display: none !important;
    }

    .facet-sort {
      margin: 0;
    }

    .#{$block} {
      &__applied {
        display: none;
      }

      &__group {
        display: none;
      }

    }

  }
}
