$block: "career-page";
$breakpoint-size: "large";

.#{$block} {
  max-width: $global-width;
  margin: 0 auto;

  &__body-rte {
    width: rem-calc(873);
    margin: rem-calc(23) auto;
  }

  .media-block {
    .media-block__media {
      padding: 0;
    }
  }

  .quote-block {
    margin-bottom: 0 !important;
  }
}