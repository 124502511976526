$block: "icon-card";
$breakpoint-size: "large";

.#{$block} {
  padding: 0 rem-calc(11);

  &__wrapper {
    padding: $space-larger rem-calc(26) rem-calc(48);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  a {
    @media screen and (min-width: rem-calc(1440)) {
      max-width: rem-calc(313);
      min-height: rem-calc(345);
      margin: auto;
    }
  }

  &__icon {
    margin: 0;
    width: rem-calc(58);
    height: rem-calc(58);
  }

  .text-container {
    h3 {
      font-size: rem-calc(21);
    }

    p {
      font-size: $font-size-smaller;
    }
  }

  .component__text {
    padding: rem-calc(56) 0 0;
  }
}