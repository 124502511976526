$block: "hbspt-form";
$breakpoint-size: "medium";

.#{$block} {
  fieldset .input {
    margin-right: 0 !important;
  }

  .form-columns-2 .hs-form-field {
    width: 50% !important;

    &:first-child {
      padding-right: rem-calc(11);
    }

    &:last-child {
      padding-left: rem-calc(11);
    }
  }

  .form-columns-3 .hs-form-field {
    width: 33.3% !important;

    &:first-child {
      padding-right: rem-calc(11);
    }

    &:nth-child(2) {
      padding: 0 rem-calc(11);
    }

    &:last-child {
      padding-left: rem-calc(11);
    }
  }
}