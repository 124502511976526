$block: "countries-selector";
$breakpoint-size: "large";

.#{$block} {
  width: 100%;
  max-width: 100%;
  max-height: rem-calc(860);
  z-index: 101;

  &__title,
  &__heading {
    display: block;
    font-size: $font-size-xlarger !important;
    font-weight: 400 !important;
  }

  &__block {
    flex-basis: 25%;

    img {
      height: 46px;
    }

    &__sites-wrapper {
      width: 85%;
      text-align: center;

      &--sites-link {
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        font-size: $font-size-smallest !important;

        &:nth-child(n+2) {
          margin-left: 17px;
  
          &:before {
            display: inline-block;
          }
        }
      }
    }  

  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: $global-width;
    margin: rem-calc(40) auto 0;

  }

  &__column {
    display: flex;
    width: 30%;
    padding-bottom: $space;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__list:nth-child(2) {
    margin-top: 33px;
  }

  &__title {
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
    font-size: rem-calc(32);
    font-weight: bold;
    text-align: center;
  }

  &__heading {
    padding-left: rem-calc(20);
    font-size: map-get(map-get($typography, $breakpoint-size), h3);
    font-weight: 500;
  }


  &__item {
    border-bottom: 0;
    font-size: rem-calc(12);
  }

  &__langCol {
    margin-left: 0;
  }
}
