$block: "article-listing-page";
$breakpoint-size: "small";

.#{$block} {
  &__content {
    background: $color-background-lighter;
    position: relative;
  }

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    font-weight: bold;
    padding: $space-large $space5;
  }

  &__articles {
    padding: 0 $space5 $space5;
  }

  .featured-card {
    max-width: $global-width-mid;
    width: 100%;
    margin-top: $space5;

    &__image {
      img {
        object-fit: cover;
      }
    }

    &__cta {
      margin-top: $space-mid;
      display: block;
      width: 100%;
      text-align: center;

      .cta {
        width: 100%;
        max-width: rem-calc(295);
        height: rem-calc(45);
      }
    }
  }
}