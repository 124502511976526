$block: "multi-row-block";
$breakpoint-size: "large";

.#{$block} {
  .overview-text {
    padding-bottom: rem-calc(36);
  }

  .list-block__text--description {
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
  }
}