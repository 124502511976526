$block: "event-listing-page";
$breakpoint-size: "large";

.#{$block} {
  .post-card__image {
    height: rem-calc(220);
    width: rem-calc(220);
  }

  .post-card__content--title {
    font-size: rem-calc(21)
  }
}