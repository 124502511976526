$block: "search-results-page";
$breakpoint-size: "small";

.#{$block} {
  background: $color-background-lighter !important;

  &__header {
    background-color: $color-white;

    &__inner {
      margin: 0 auto;
      max-width: $global-width-mid;
      font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
      padding: rem-calc(78) 0 rem-calc(20) $nav-left-padding;
      font-weight: 900;
      line-height: rem-calc(36);
      border-top: .5px solid $color-gray-light;
      background-color: $color-white;

      h3 {
        margin-bottom: rem-calc(30);
      }
    }
  }

  .search-results-nav {
    padding-top: rem-calc(20);

  }

  &__content {
    background: $color-background-lighter !important;

  }

  &__no-results {
    display: none;
    background-color: $color-white;

    li,
    p{
      font-size: $font-size-small;
      color: $color-body-text;
    }

    ul {
      list-style: none;
      margin-left: rem-calc(58);
      margin-bottom: rem-calc(16);

      li {
        display: flex;
        align-items: center;

        &::before {
          content: "\2022";  
          color: $color-primary-brand; 
          font-size: rem-calc(20);
          font-weight: bold; 
          display: inline-block; 
          left: -9px;
          width: rem-calc(20); 
          margin-left: -1em;
          position: relative;
        }
      }
    }
  }

  &__no-results__inner, &__brands {
    max-width: $global-width-mid;
    padding: 0 $nav-left-padding;
    padding-bottom: rem-calc(40);
    margin: 0 auto;
  }

  .brands-multi-content-block {
    padding-top: 20px;
  }

  &__brands {
    display: none;
    text-align: center;
    padding-top: 20px;
    
    h3 {
      font-size: rem-calc(36);
    }
  }
}
