$block: "global-nav";
$breakpoint-size: "medium";
.#{$block} {

  &__search {
    margin: 0 map-get($grid-column-gutter, $breakpoint-size);
  }

  &__search--small {
    margin: 0;
  }

  &--full {
    padding: map-get($grid-column-gutter, $breakpoint-size);
  }

  &--small {
	.header-navigation-logo {
  		width: rem-calc(90);
  	}
  }

  &__search {
    .#{$block} {
      font-size: map-get(map-get($typography, $breakpoint-size), p);
    }
  }

  &__back {
    display: none !important;
  }

  &__links {
    padding-left: map-get($grid-column-gutter, $breakpoint-size);
  }

  &__dropdown {

    .submenu.is-active {
      top: 113px;
    }

    .dropdown.menu {
      li {
        .header-navigation-logo-container {
          padding: 1rem;
        }
      }
    }
  }

  &__top-bar {
    .header-navigation-logo {
      width: rem-calc(90);
    }
  }
}
