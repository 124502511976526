$block: "footer";
$breakpoint-size: "small";

.#{$block} {
  &__themePrimary {
    background: $color-text;
  }

  &__themeSecondary {
    background: $color-primary-brand-darkest;
  }

  &__top {
    padding-top: $space-large;
    padding-bottom: $space-large;
  }

  &__cta {
   padding: 0 rem-calc(20);
    text-align: center;

    a {
      width: 100%;
      padding: 15px;
      background: $color-primary-brand;
      border-color: $color-primary-brand;
      color: $color-white;
    }

    .icon-how-to-buy {
      padding-right: 5px;
    }

  }

  &__menu {
    padding: $space-large 0;

    ul:first-child {
      border-top: $border-thinnest solid $color-gray-light;
    }
  }

  &__menuList {
    & > li {
      padding: rem-calc(15) rem-calc(20);
      border-bottom: $border-thinnest solid $color-gray-light;
    }

    &--heading {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      text-transform: uppercase;
      font-family: $font-bold;
    }
  }

  &__contentSmall {
    text-align: center;
  }

  //logo
  &__brandLogo {
    max-width: rem-calc(122);
    height: rem-calc(54);
    margin: 0 auto rem-calc(15);

    img {
      object-fit: cover;
      object-position: center;
    }
  }

  &__socialMedia {
    $icon-padding: rem-calc(7);

    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + #{$icon-padding * 2});
    margin-right: -$icon-padding;
    margin-left: -$icon-padding;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $icon-padding;
    }

    .icon:before {
      font-size: rem-calc(22);
      color: $color-white;
    }

    .icon-x {
      &:before {
        width: rem-calc(20);
        height: rem-calc(20);
        background-image: url('../images/icons/x-logo.svg');
      }
    }
  }

  &__agreementsLink {
    font-family: $font-regular;
    font-size: map-get(map-get($typography, $breakpoint-size), p);
  }

  &__bottom {
    padding: map-get($grid-column-gutter, $breakpoint-size) $space-larger;
    position: relative;

    &--themeColorPrimary {
      background: $color-primary-brand;
    }

    &--themeColorSecondary {
      background: $color-primary-brand-dark
    }

    &--wrapper {
      text-align: center;
    }

    &--text,
    &--link,
    &--link a {
      margin: 0;
      line-height: $line-height;
      color: $color-white;
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }

    &:before {
      content: "";
      width: 100%;
      height: rem-calc(2);
      background: $color-white;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  /// component global styles
  a,
  p {
    color: $color-white;
  }

  // subscribe block
  .subscribeBox {
    padding: $space-large $space5 0;
    max-width: rem-calc(355);
    margin: auto;
  }

  // overwriting
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    border-color: $color-white transparent transparent !important;
  }

  .accordion-menu a {
    padding: rem-calc(10) 0;
  }

  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    border: inset 5px;
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #FFFFFF transparent transparent !important;
  }

  .accordion-menu .nested.is-accordion-submenu {
    margin-top: 10px;
    margin-left: 0;
  }

  .rte-block {
    padding: 0;

    p {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      color: $color-white;
      opacity: .75;
    }
  }
}
