$block: "threeX-content-block";
$breakpoint-size: "small";

.#{$block} {

  &__blocks {
    display: flex;
    flex-wrap: wrap;

    .video-block {
      .video-block__trigger {
        a.cta {
          margin: auto;
        }
      }
    }

    &--secondary {

      .content-block__content {
        background-color: $color-gray-lightest;

        a {
          color: $color-black;
        }

        .rte-block  {
          color: $color-black;

          p,
          li {
            color: $color-black;
          }

          ul, ol {
            list-style-type: none;

            li:before {
              top: -4px;
            }
          }
        }
      }

      .content-block__display-category {
        color: $color-black;
      }

      .cta.pdp-primary-cta {
        padding: .75rem 3rem;
        background: $color-white;
        display: block;
        color: $color-primary-brand;
        font-family: $font-bold;
        border: 1px solid $color-primary-brand;
        text-align: center;

        &:hover,
        &:focus {
          color: $color-black;
          border: 1px solid $color-brand-accent-1;
          background: $color-brand-accent-1;
        }
      }
    }

  }

  &__title {
    text-align: center;
    margin-bottom: rem-calc(40);
  }

  .content-block {
    margin: 10px 0;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  //maintain 16:9 aspect ratio for video and image block images
  .content-block__wrapper {
    .image-block,
    .video-block,
    .video-block__video {
      height: auto;
      max-height: 345px !important;
      margin: 0;

      &__picture {
        max-width: 100%;
        min-height: unset !important;

        picture {
          min-height: unset !important;
        }

        a {
          display: block;
          position: relative;
          padding-bottom: 56.2%;

          img {
            position: absolute;
            width: 100% !important;
            height: 100% !important;
            min-height: unset !important;
          }
        }
      }
    }

    .video-block__video {
      .image-block {
        a {
          height: 100%;
        }
      }
    }
  }

}
