$block: "region-selector-block";
$breakpoint-size: "medium";

.#{$block} {
  &__region-nav {

    &--link {
      color: $color-gray-3;
    }
  }
}