$block: "page-title";
$breakpoint-size: "medium";

.#{$block} {
	h1 {
		max-width: $global-width;
		margin: 0 auto;
		padding: $space-large map-get($grid-column-gutter, $breakpoint-size);
	}
}
