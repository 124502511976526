$block: "video-listing-page";
$breakpoint-size: "large";

.#{$block} {
  padding: $space5 0;

  .video-block__details {
    padding: $space5;
    height: rem-calc(220);
    overflow: hidden;
  }

  .video-block__title {
    font-size: rem-calc(21); //per comps
  }

  .video-block__description {
    padding-top: rem-calc(5);
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 115px;
    margin: 0 auto;
    font-size: 14px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      font-size: rem-calc(13); //per comps
      overflow: visible;
      line-height: 2;
    }
  }

  .video-block {
    picture, img {
      height: rem-calc(170);
    }
  }
}
