$block: "stats-content-block";
$breakpoint-size: "small";
$stats-overlap: rem-calc(12);

.#{$block} {
  margin: 0 0 $space-larger;

  &__wrapper {
    display: flex;
    position: relative;
    height: $hero-height;
  }

  .banner-content {
    justify-content: center;
  }

  // if has stats card
  &--stats {
    &__wrapper {
      position: relative;
    }

    .stats-card {
      margin-top: -$stats-overlap;
    }

    .banner-content {

      &__text {
        text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
      }
    }

  }

  .cta {
    min-width: rem-calc(175);
  }
}
