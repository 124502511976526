$block: "event-listing-page";
$breakpoint-size: "small";

.#{$block} {

  background: $color-background-light;

  .page-title {
    background: $color-white;
    border-top: 1px solid $color-border;
  }

  &__content {
    position: relative;
  }

  .post-card__image {
    height: rem-calc(180);
    width: rem-calc(180);
  }

  .post-card__content--title {
    font-size: rem-calc(18)
  }
}
