$block: "cookies-disclaimer";
$breakpoint-size: "medium";

.#{$block} {
  $shadow-size: 10px;
  &--init {
    bottom: $space;
    right: calc(#{$space} + #{$shadow-size});
    left: auto;
    width: rem-calc(380);
  }
}
