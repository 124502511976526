$block: "hero-content";
$breakpoint-size: "medium";

.#{$block} {
  width: 100%;

  &__text {
    max-width: 66.66%;

    &--company-name {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }

    &--description {
      p  {
        font-size: map-get(map-get($typography, $breakpoint-size), label);
      }
    }
  }

  &__cta {
    margin-top: $space-mid;
  }

  &__wrapper {
    padding: $space-large map-get($grid-column-gutter, $breakpoint-size);
  }

  &--solid-background {
    .#{$block}__wrapper {
      padding-top: $space-largest;
      padding-bottom: $space-larger;
    }

    .#{$block}__text {
      max-width: 60rem;
    }

    .#{$block}__text--header {
      font-size: rem-calc(42);
    }

  }
}
