$block: "icon-card";
$breakpoint-size: "small";

.#{$block} {
  padding: 0 $space-smallest;

  a {
    display: block;
    height: 100%;
    background: $color-white;
  }

  &__wrapper {
    padding: $space-large rem-calc(14) rem-calc(28);
  }

  &__icon,
  &__image {
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__icon {
    color: $color-text;
    width: rem-calc(40);
    height: rem-calc(40);
  }

  &__image {
    max-height: rem-calc(140);
    max-width: rem-calc(140);

    img {
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  .text-container {
    text-align: center;
    padding-top: $space-mid;

    h3 {
      color: $color-text;
      font-size: map-get(map-get($typography, $breakpoint-size), p);
    }

    p {
      color: $color-body-text;
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      margin: 0;
    }
  }
}