$block: "document-library";
$breakpoint-size: "small";

.facets--disabled-document-library {
	pointer-events: none !important;
	opacity: 0.5 !important;
}

.#{$block} {
	$button-offset: 3px;
	$card-spacing: $space2;

  background-color: #F3F3F3;

  select {
    border-radius: rem-calc(6);
    color: $color-body-text-2;
		font-size: $font-size-smaller;
  }

  select:not([disabled]) {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%2810, 96, 169%29"></polygon></svg>');
  }

  &.white-bg {
    background-color: $color-white;
  }

  &__resource-card-container {
	position: relative;
  }

  &__finder-tools,
  &__product-type {
    margin: 0 auto;
    max-width: rem-calc(950);
  }

  &__inner.component {
    max-width: 90rem;
		padding-top: $space-smallest;
		padding-bottom: $space-large;
		margin-bottom: 0;

    h3 {
      margin-bottom: $space5;
    }
  }

  &__or {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $color-stroke;
    margin: $space-mid 0;
	  @include font('bold');
	  font-size: $font-size-smaller;
	  line-height: 0.1em;

    span {
        background:$color-background-light;
        padding:0 $space-mid;
    }
  }

  &__label {
	font-size: 0;
  }

  &__search {
    position: relative;

    .icon-search {
      position: absolute;
      top: 12px;
      left: 13px;
    }

    &-input {
      padding-top: $space5;
      padding-bottom: $space5;
      padding-left: rem-calc(40);
      border-radius: rem-calc(6);
    }

    &-submit {
      position: absolute;
      right: $button-offset;
      top: $button-offset;
		  padding-top: 0;
		  padding-bottom: 0;
		  height: calc(100% - #{$button-offset} * 2);
		  border-radius: rem-calc(4);
    }
  }

  .brands-multi-content-block {
    padding: 0;
    background-color: transparent;

    .grid-x {
      margin-right: -$brand-grid-spacing-sm;
	  	margin-left: -$brand-grid-spacing-sm;
    }

		.brand-logo {
			&__brand-name {
				display: none;
			}
		}
  }

  &__select-container {
	  display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: $color-white;
    padding: $space5 $space3;

	  select {
      border-radius: rem-calc(6);
      border: 1px solid $color-stroke;
      opacity: 0.75;
      color: $color-placeholder;
    }

		.brand-logo__logo {
			width: 100%;
		}

		img {
			margin: $space5 auto;
		}
  }

	&__select {
		display: flex;
		flex-flow: column nowrap;
		margin: $space4 0;

		.pdp-primary-cta {
			align-self: center;
		}
	}

	&__helper-container {
		text-align: center;
		margin-top: $space;

		p {
			margin-bottom: 0;

			button {
				color: $color-link;
				transition: color 0.3s;

				&:hover, &:focus {
					color: $color-text;
					cursor: pointer;
				}
			}
		}
	}

	&__back {
		button {
			color: $color-link;
			transition: color 0.3s;

			&:hover, &:focus {
				color: $color-text;
				cursor: pointer;
			}
		}

		span {
			margin-right: $space2;
		}
	}

	&__filter-button {
		margin: $space4 0;
		order: 3;

		button {
			width: 100%;
		}
	}

  &__results {
    display: flex;
    flex-direction: row;

		h4 {
			font-size: $font-size-smaller;
		}

    &__leading-text {
			display: flex;
			flex-flow: column nowrap;
    }

		&-cards {
			margin-top: $space4;
		}

    .close-nav {
      background-color: transparent;
      text-align: right;

      .icon:before {
				font-size: $font-size-small;
        color: $color-text;
      }
    }

    .facets {
      	background-color: $color-background-lighter;
				right: 0;
				transition: right 0.5s;
				display: flex;
				flex-flow: column nowrap;
				padding-top: $space-mid;

		&__wrapper {
			opacity: 1;
			left: 0;
			transition: opacity 0.5s;
		}

		&__filter-options {
			&__title {
				padding: $space3 0;
			}
		}

		&__category-header {
			padding: $space2 0;
		}

		.facets__container {
			padding: $space4 0;

			select {
				margin-bottom: $space3;
			}

			&--new-search {
				order: 3;
			}

			&--wizard {
				order: 1;
				border-bottom: 1px solid $color-stroke;
			}

			&--narrow-results {
				order: 2;
				margin-top: $space4;
			}

			.facets__group {
				border-bottom: 1px solid $color-stroke;
			}
		}

		.close-nav {
			position: absolute;
			top: $space5;
			right: $space5;
		}

		&__option {
			padding-top: 0;
			padding-bottom: $space4;
		}

		&__options-name {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			order: 2;
			padding-bottom: $space2;
			color: $color-gray-3;
			font-size: $font-size-smaller;
			cursor: pointer;

			.name {
				width: 95%;

				span {
				margin-left: rem-calc($space-smallest);
				}
			}

			&.selected {
				color: $color-primary-brand;
			}

		}

		&__checkbox {
			display: inline-block;
			width: 15px;
			height: 15px;
			margin-right: $space3;
			border: 1px solid $color-body-text;

			.icon-checkmark {
				display: none;
				position: relative;
				vertical-align: top;
				top: -1px;
				left: 1px;

				&.selected {
					display: inline;
				}

				&:before {
					font-size: 9px;
					vertical-align: middle;
				}
			}

			input {
				opacity: 0;
				cursor: pointer;
			}
		}
	}

		.search-box {
			&__label {
				font-size: 0;
			}

			&__input {
				background-color: $color-white;
				border-color: $color-stroke;
			}

			&__submit {
				right: $space4;
			}
		}

		.facet-page {
			&__filters--hidden-mobile {
				display: block;
				position: absolute;
				min-width: 200px;
				flex-basis: 25%;
				flex-shrink: 0;

				.facets {
					right: -100%;
					transition: right 0.5s;

					&__wrapper {
						opacity: 0;
						left: 100%;
						transition: opacity 0.3s;
					}
				}
			}

			&__items {
				display: flex;
				flex-direction: column;
				//justify-content: space-between;
				flex-wrap: wrap;
				padding-left: 0;
				padding-right: 0;
				width: 100%;

				&__no-results {
					display: flex;
					align-self: flex-start;
					flex: 1;
					margin-top: $space-mid;

					.search-results-page__no-results__inner {
						margin-left: 0;
						padding-left: 0;

						h5 {
							font-family: $font-regular;
							font-weight: normal;
							margin-bottom: $space4;
						}

						ul {
							margin-bottom: $space5;
							margin-left: $space-mid;
						}

						li {
							color: $color-body-text;
						}
					}
				}
			}
		}

    &-cards {
      display: flex;

			.#{$block}__resource-card {
				margin-bottom: $space4;

				img {
		      width: 100%;
		    }

				h4 {
					a {
						color: $color-text;
						transition: color 0.3s;

						&:hover, &:focus {
							color: $color-link;
						}
					}
				}

				&-container {
					display: flex;
					flex-flow: column nowrap;
					border: 1px solid $color-stroke;
					height: 100%;
				}

		    &-btns {
		      display: flex;
		      flex-flow: row wrap;
		    }

				.cta {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: center;
					flex-grow: 1;
		      padding: $space3 $space2;
					border: 1px solid $color-stroke;
		      border-radius: 0;
					font-size: rem-calc(11);
		      text-transform: uppercase;
		      text-decoration: none;
		      color: $color-white;
		      background-color: $color-primary-brand;

					.icon:before {
						top: 0;
						left: 0;
						margin-right: $space2;
					}

					&:hover, &:focus {
						background-color: $color-brand-accent-1;
						color: $color-text;
					}
		  	}

		    &-content {
		      background-color: $color-background-lighter;
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-between;
					flex-grow: 1;

		      .content-title {
		        padding: $space3 $space2;
		      }

		      .content-archived {
						display: inline-block;
						margin-bottom: 8px;
						padding: 4px 6px;
						background: $color-brand-accent-1;
						font-size: rem-calc(11);
						font-weight: bold;
						line-height: 1;
						text-transform: uppercase;
		      }

					.content-metadata {
						display: flex;
						flex-flow: row wrap;
						font-size: rem-calc(11);
						color: $color-gray-3;

						> span {
							margin-right: rem-calc(6);
							padding-right: rem-calc(6);
							border-right: 1px solid $color-stroke;
						}
					}
					.content-file-data {
						span {
							display: inline;
							padding-right: $space-base-unit;
						}
					}
		    }
		  }

      &.list-view {
        flex-flow: column nowrap;

        .#{$block}__resource-card {
          flex-direction: row;
          display: flex;
          flex-wrap: nowrap;

					&-container {
						flex-flow: row nowrap;
						width: 100%;
						align-items: stretch;
					}

					&-content {
						.content-title {
							padding-right: $space4;
							padding-left: $space4;
						}
					}

          img {
            max-width: 256px;
          }
        }
      }

			&.grid-view {
				flex-flow: row wrap;
	      justify-content: flex-start;
				margin-left: -$card-spacing;
				margin-right: -$card-spacing;

				.#{$block}__resource-card {
					flex: 0 0 50%;
					padding: 0 $card-spacing;

					&-container {
						flex-flow: column nowrap;
						height: 100%;
					}
				}
			}
    }
  }

	&__layout {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		.facet-sort {
			&__grid-list-icons {
				display: flex;
				flex-flow: row nowrap;
				border: 1px solid $color-stroke;
				border-radius: $border-radius;

				label {
					font-size: 0;
					line-height: 1;
				}

				input[type="radio"] {
					appearance: none;
  				background-color: $color-gray-lighter-2;
					margin: 0;
					padding: rem-calc(10);
					transition: background-color 0.3s;

					&.icon-grid {
						border-radius: $border-radius 0 0 $border-radius;
					}

					&.icon-list2 {
						border-radius: 0 $border-radius $border-radius 0;
					}

					&.active {
						background-color: $color-white;
					}
				}
			}
		}
	}

	&__items-per-page {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding-left: $space5;
		flex-grow: 1;
		justify-content: flex-end;

		select {
			margin-bottom: 0;
		}

		.facet-pagination__per-page {
			display: flex!important;
			flex-basis: rem-calc(80);

			label {
				font-size: 0;
			}
		}
	}

  &__sort {
	order: -1;
    background-color: $color-background-lighter;
    padding: $space3 $space4;
    margin: -1.75rem -1rem $space; /* in mobile, pull this out of .component .document-library__inner */
	display: flex;
	flex-flow: row nowrap;
	//justify-content: space-between;

    label {
      margin: 0;
    }

		p {
			margin-right: $space2;
			margin-bottom: 0;
			text-align: right;
			line-height: $line-height-small;
		}
  }

	&__archived-checkbox {
		color: $color-gray-3;
		padding-bottom: 0!important;
	}

	&__search-term-list {
		order: 1;

		span {
			&:after {
				content: "/";
				margin: 0 $space2;
			}

			&:first-child {
				margin-left: $space3;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}

	&__clear-filters {
		width: 100%;
		order: 4;
		display: flex;
		flex-flow: row wrap;

		&__label {
			cursor: pointer;
			margin: $space-base-unit $space3 $space-base-unit 0;
			padding: $space3;
			border: 1px solid $color-stroke;
			border-radius: $border-radius;
			color: $color-gray-3;
			font-size: $font-size-smaller;
			line-height: 1;

			.icon {
				color: $color-primary-brand;
				font-size: 12px;
				margin: 0 0 0 $space3;
			}

			&.clear-all {
				background-color: $color-primary-brand;
				border-color: $color-primary-brand;
				color: $color-white;
				text-transform: uppercase;
				font-family: $font-bold;
				font-weight: bold;
				font-size: rem-calc(12);
			}
		}
	}

}
