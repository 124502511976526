$block: "document-library-product-line"; //"document library product line"
$breakpoint-size: "small";

.#{$block} {

	&__help {
		display: flex;
		flex-direction: column;
		margin: $space-large 0;
		padding: $space-mid $space5;
		background-color: $color-background-lighter;

		h2 {
			font-size: $font-size-medium-2;
		}

		p {
			font-size: $font-size-standard;
			color: $color-text-lighter;
		}

		&-info {
			width: 100%;

			ul {
				list-style-position: outside;
				list-style-type: none;
				margin-left: 40px;

				li {
					padding: 2px 0;
					position: relative;

					&:after {
						content:"·";
						position: absolute;
						font-size: 30px;
						left: -20px;
						top: -10px;
					}
				}

				a {
					text-decoration: underline;
				}
			}
		}

		&-contact {
			width: 100%;
			margin-top: 30px;
			border-top: 1px solid rgba(0,0,0,.25);
			padding-top: 25px;

			.button {
				text-transform: uppercase;
				border-radius: $border-radius;
				font-family: $font-bold;
				margin-top: 15px;
			}
		}
	}

	//react portion of the page
	.dlpl {

		&.document-library__results {
				flex-flow: column nowrap;
		}

		&__archived-resources {
			background: $color-background-lighter;
			padding: $space3;
			margin-bottom: $space;
		}

		.document-library__archived-checkbox {
			padding-left: 0;
		}

		&__category {
			margin: $space5 0;
		}
	}

}
