$block: "disclaimer";
$breakpoint-size: "small";

.#{$block} {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
  background: $color-white;
  padding: $space-large;

  &__text {
    padding-bottom: map-get($grid-column-gutter, $breakpoint-size);

    &--title {
      font-weight: bold;
      color: $color-text;
      font-size: map-get(map-get($typography, $breakpoint-size), p-large);
    }

    &--description {
      color: $color-body-text;
      font-size: $font-size-smaller;
      line-height: 1.8;
    }
  }

  &__cta {
    &--cancelBtn {
      color: $color-primary-brand!important;
      margin-right: map-get($grid-column-gutter, $breakpoint-size);

      &:hover {
        opacity: .7;
      }
    }
  }
}