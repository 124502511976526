$block: "service-repair-page";
$breakpoint-size: "large";

.#{$block} {
  height: 100%;

  &__content {
    margin: 0 auto;
  }

  .map-container {
    position: relative;
    min-height: rem-calc(675);
    height: 60vh;
    width: 100%;

    .icon-how-to-buy {
      font-size: rem-calc(30);
      cursor: pointer;
    }
  }

  &__overlay {
    max-width: $global-width;
    padding-left: $nav-left-padding;
    margin: 0 auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__search-wrapper {
    width: rem-calc(425);
    height: 90%;
    max-height: rem-calc(835);
    position: absolute;
    top: 5%;
    background: $color-white;
    z-index: 1;
    overflow: auto;

  }

  &__search, &__results {}

  &__results {
    height: 90%;
    max-height: 90%;
    // overflow: auto;
  }



}