$block: "content-overlay-block";
$breakpoint-size: "small";

.#{$block} {
  position: relative;

  .hero-image {
    &:before {
      // remove gradient background on heros that have content overlay blocks and replace with solid semi-transparent overlay per comps
      background: rgba(0,0,0,0.3);
    }
  }
}

.#{$block} .content-block,
.content-block--hero {

  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0;
  min-height: $hero-height;
  z-index: 1;
  -ms-flex-pack: center;
}

.content-block--hero {
  $category-hero-height: rem-calc(355);
  min-height: auto;
  margin: calc(#{$category-hero-height} - 60px) auto 0;
  justify-content: flex-start;

  + .hero-image {
    max-height: $category-hero-height;
    &:before {
      background: none;
    }
  }

  .visual-cue {
    display: none;
  }
}
