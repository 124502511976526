$block: "featured-product";
$breakpoint-size: "medium";

.#{$block} {
	$gird-gutter-width: rem-calc(10);

	padding-top: rem-calc(55);
	padding-bottom: rem-calc(52);

	&__category {
		margin-top: rem-calc(45);

		h3 {
			margin-bottom: rem-calc(22);
		}
	}

	&__item-wrapper {
		width: calc(100% + #{$gird-gutter-width});
		margin-right: -($gird-gutter-width / 2);
		margin-left: -($gird-gutter-width / 2);
	}

	&__item {
		flex-basis: calc(#{percentage(1/5)} - #{$gird-gutter-width});
		margin-right: ($gird-gutter-width / 2);
		margin-left: ($gird-gutter-width / 2);

		span {
			font-size: $font-size-smaller;
			line-height: rem-calc(18);
		}
	}
}