$block: "media-block";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;
  padding-top: rem-calc(20);
  margin-bottom: 0;

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    font-family: $font-bold;
    color: $color-text;
  }

  & > div:first-child {
    margin-bottom: $space-large;
  }

  .image-block__picture {
    margin-top: rem-calc(20);
  }

  .social-media-icons {
    margin-top: rem-calc(30);
    margin-right: auto;
  }

  .rte-block {
    padding-left: 0;
  }

  .cta.pdp-primary-cta {
    margin-top: rem-calc(20);
  }
}
