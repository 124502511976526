$block: "info-brands";
$breakpoint-size: "small";

.#{$block} {
  display: none;

  &__heading {
    background: $color-background-lighter;
    padding: $space2 $space;

    h5 {
      font-size: rem-calc(10);
      font-weight: bold;
      text-transform: uppercase;
      color: $color-gray;
      margin-bottom: 0;
      line-height: $line-height;
    }
  }

  &__list {
    padding: $space5 $space;

    &--item {
      list-style: none;
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      margin-top: rem-calc(10) !important;
      padding-right: rem-calc(5);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        color: $color-gray-3;
      }
    }
  }
}