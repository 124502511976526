$block: "four-pillar-hero";
$breakpoint-size: "medium";

.#{$block} {
	&__videos {
		flex-direction: row;
		flex-wrap: wrap;
	}

	&__video {
		$border-color: #5d5d5d;

		flex: 0 0 percentage(1/2);
		width: percentage(1/2);
		height: rem-calc(350);
		border-right: 1px solid $border-color;
		border-bottom: 1px solid $border-color;

		&:nth-child(3),
		&:nth-child(4) {
			border-bottom: 0;
		}

		&-name {
			justify-content: center;
			height: 100%;
			margin-top: auto;
			margin-bottom: auto;

			a {
				min-width: fit-content;
				max-width: rem-calc(360);
				padding: rem-calc(16) rem-calc(18) 0;
				transition: all $transition-med;
				border: 3px solid transparent;
				border-radius: 6px;
				background: none;
				font-size: $font-size-medium;
				text-shadow: $color-black 0 2px 12px;

				&:after {
					display: block;
					width: rem-calc(54);
					height: rem-calc(3);
					margin: rem-calc(11) auto 0;
					background: $color-brand-accent-1;
					content: '';
				}
			}
		}

		&:hover,
		&:focus {
			.#{$block}__video-name {
				a {
					border-color: $color-brand-accent-1;
					background: rgba($color-black, .55);
					text-shadow: none;

					&:after {
						margin-top: rem-calc(16);
						margin-bottom: rem-calc(-2);
						width: 0;
						height: 0;
						visibility: hidden;
					}
				}
			}
		}

		video {
			display: none;
		}
	}
}
