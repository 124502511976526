$block: "generic-iframe-modal";
$breakpoint-size: "large";

.#{$block} {
  background: $color-white;
  position: relative;

  &__wrapper {
    padding: 0 rem-calc(37);

    .hbspt-form {
      .hs-richtext.hs-main-font-element {
        text-align: left;

        h2 {
          font-size: rem-calc(32);
        }

        p {
          font-size: $font-size-standard;
        }
      }
    }
  }
}