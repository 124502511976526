$block: "twoX-content-block";
$breakpoint-size: "medium";

.#{$block} {
  display: flex;

  .content-block {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 50%;

    &:first-child {
      padding-right: map-get($grid-column-gutter, $breakpoint-size)/3;
      margin-bottom: 0;
    }

    &:last-child {
      padding-left: map-get($grid-column-gutter, $breakpoint-size)/3;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
        flex: auto;
      }

      .rte-block {
        margin-bottom: $space;
      }

      .ie & {
        height: auto;
      }

      .cta {
        align-self: flex-start;
        margin-top: auto;
        margin-bottom: 0;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
        flex: auto;
      }
    }

    .image-block {
      .ie & {
        height: rem-calc(354);
      }
    }
  }
}
