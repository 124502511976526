$block: "facet-page";
$breakpoint-size: "small";

.#{$block} {
  &__content {
    position: relative;
  }

  &__filters {
    background: $color-white;
    padding-top: rem-calc($space);
    width: auto;
  }

  &__filters--hidden-mobile {
    display: none;

    .facet-sort--mobile-panel {
      display: none;
    }
  }

  &__no-results {
    width: 100%;
    display: flex;
    background: $color-white;
    padding: $space5;
    margin-bottom: rem-calc(10);
    font-family: $font-bold;
    font-size: $font-size-smaller;

    p {
      font-family: $font-bold;
      font-size: $font-size-smaller;
      margin: 0;
    }
  }

  &__resetSearch {
    cursor: pointer;
    font-family: $font-bold;
    color: $color-primary-brand;
    font-size: $font-size-smaller;
    padding-left: rem-calc(5);

    &:hover {
      opacity: .8;
      text-decoration: underline;
    }
  }

  &__items {
	  padding-right: map-get($grid-column-gutter, $breakpoint-size);
	  padding-left: map-get($grid-column-gutter, $breakpoint-size);

    .grid-x {
      .video-listing-page__video-container {
        padding: 0  0 rem-calc(24);
      }
    }

    .video-block__title {
      font-size: 14px;
    }

    .video-block__description {
      p {
        font-size: 11px; //per comps
      }
    }
  }
}
