$block: "overlay-block";

.#{$block} {
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}