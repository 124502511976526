$block: "contact-location";
$breakpoint-size: "small";

.#{$block} {
  display: flex;
  padding: rem-calc(22 28);

  .rte-block {
    padding: 0;

    h4 {
      font-family: $font-bold;
    }

    p {
      color: $color-body-text;
      margin-bottom: rem-calc(5);
    }

    a {
      color: $color-primary-brand;
    }

    a, p, h4 {
      font-size: map-get(map-get($typography, $breakpoint-size), h4);
    }
  }

  .map-icon {
    display: none;
  }
}