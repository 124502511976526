$block: "global-nav";
$breakpoint-size: "small";

.#{$block} {
  z-index: 15;
  background: $color-white;
  width: 100%;
  position: relative;

  &--full {
    display: none;
  }

  &--small {
    padding: 0;

	.header-navigation-logo {
		width: rem-calc(75);
		margin: auto;
	}

    .icon-menu {
      font-size: map-get(map-get($typography, $breakpoint-size), h2);
    }

    .cell:first-child,
    .cell:last-child {
      @include transition;
      width: auto;
      padding: map-get($grid-column-gutter, $breakpoint-size) $space5;
      color: $color-black;
      &:hover, &:focus {
        color: $color-link;
      }
    }
  }

  .grid-container {
    width: 100%;
  }

  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: map-get($grid-column-gutter, $breakpoint-size);
  }

  &--expanded {
    position: absolute;
    top: 0;

    .#{$block} {
      &__dropdown {
        display: block;
      }
    }
  }

 &__dropdown {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.6);
    overflow: auto;
    overflow-x: hidden;
   -webkit-overflow-scrolling: touch;

   &::-webkit-scrollbar {
     display: none;
   }

    .cell {
      padding: 0;
    }

    .global_nav__buy-cta {
      margin: map-get($grid-column-gutter, $breakpoint-size);
    }

    .is-drilldown {
      max-width: inherit !important;
      min-height: 0 !important;
    }

    .drilldown {
      height: 100%;
      background-color: $color-white;

      .loginBtn {
        @include transition;
        display: flex;
        justify-content: flex-end;
        padding-left: 2rem;
        width: auto;
        margin-right: 0;
        margin-left: auto;
        background-color: $color-primary-brand;
        &:hover, &:focus {
          background-color: $color-brand-accent-1;
        }
      }

      .drilldown-top-list-nav > a::before {
          content: '';
          position: absolute;
          left: rem-calc(28);
          border: inset 5px;
          border-left-width: 0;
          border-right-style: solid;
          border-color: transparent $color-primary-brand transparent transparent;
          margin-top: 3px;
      }
    }

    .submenu.is-active {
      height: auto;
      top: 100px;
      overflow: hidden;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      li > ul {
        top: 0px;
        border-top: 0;
      }

      .is-submenu-parent-item {
        display: none;
      }
    }

    .dropdown.menu {
      li {
        height: auto;
        min-height: rem-calc(60);
        display: flex;
        align-items: center;
        border-bottom: rem-calc(.5) solid $color-gray-light;
        width: 100%;

        a {
          width: 100%;
          color: $color-primary-brand-dark;
          font-family: $font-medium;
          font-size: map-get(map-get($typography, $breakpoint-size), p);
          line-height: rem-calc(16);
          padding: rem-calc(20) rem-calc(35) rem-calc(20) rem-calc(20);

          &::after {
            color: $color-primary-brand;
            margin-top: rem-calc(-6); //from Foundation
            right: rem-calc(22);
            border: inset 5px;
            border-right-width: 0;
            border-left-style: solid;
            border-color: transparent transparent transparent $color-primary-brand;
          }
        }

        a[data-label] {
          color: $color-black;
        }

        .header-navigation-logo-container {
          padding: 1rem;
          background-color: transparent;
        }
      }
    }

    li {
      &[data-is-parent-link] {
        background: $color-gray-lightest;
        color: $color-black;
        cursor: default;

        a {
          background: $color-gray-lightest;
          color: $color-black !important;
          cursor: default;
        }
      }
    }
 }

  .grid-x {
    height: 100%;
    justify-content: space-between;
  }

  .symbol-text-cta {
    justify-content: center;
  }

  &__link-categories {
    margin: 0;
    padding: 0;
    background: $color-white;
    width: 100%;
    height: 100%;
  }

  .display {
    display: block;
  }

  hr {
    display: none;
  }

  .submenu.search-open {
    top: 250px;

  }

  .mobile-nav-see-all {
      float: right;
      margin: -41px 20px 0 0;
      display: inline-block;
      white-space: nowrap;
      margin-right: rem-calc(20);
      font-family: $font-medium;
      color: $color-primary-brand;
      font-size: rem-calc(14);

      &:hover {
        cursor: pointer;
      }
  }

  .drilldown-top-list-nav {
    background: $color-gray-lightest;
    color: $color-black;
    border-bottom: none !important;

    a {
      display:block;
      width: 80% !important;
      background-color: $color-gray-lightest;
      color: $color-black !important;
      padding-left: rem-calc(68) !important;

      &:before {
        margin-right: rem-calc(50);
        border-color: transparent $color-primary-brand transparent transparent;
      }
    }
  }

  &--sub {
    .loginBtn {
      display: none;
    }

    .global-nav {
      &__dropdown {
        display: block;
      }

      &__back {
        display: flex;
      }
    }
  }

  .submenu {
    width: 100%;
    background: $color-white;
  }

  &__search-submit {
    cursor: pointer;
  }

  .search {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.25rem;
  }

  .icon-search {
    cursor: pointer;
    font-size: rem-calc(21);
    &:hover, &:focus {
      color: $color-link;
    }
  }

  .search-icons {
    .icon-close-menu {
      display: none;
      font-size: rem-calc(20);
    }
  }

  &__search--small {
    display: none;
    position: absolute;
    bottom: rem-calc(-140);
    left: 0;
    right: 0;
    padding: $space5;
    height: rem-calc(140);
    border-top: 0.5px solid $color-gray-light;
    z-index: 10;
    background-color: $color-white;

    label {
      font-size: 0;
    }

    .#{$block}__search-submit {
      height: rem-calc(45);
      width: 100%;
      padding: 0;
      border-radius: rem-calc(6);
      background-color: $color-primary-brand;
      color: $color-white;
      font-size: rem-calc(13);
      font-weight: bold;
      line-height: rem-calc(15);
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .showSearch {
    display: block;
  }

  &__search-input {
    height: rem-calc(45);
    border: none;
    border-radius: rem-calc(6);
    background-color: $color-background-light;
    font-size: $font-size-smaller;
    box-shadow: none;
    padding: rem-calc(14 18);
    color: $color-text;

    @include placeholder {
      color: $color-placeholder;
    }
  }

  &__mobile-login {
    display: flex;
    align-items: center;

    a {
      padding-top: rem-calc(20) !important;
      background: $color-primary-brand;
      color: $color-white !important;
      font-family: $font-bold !important;
      font-size: map-get(map-get($typography, $breakpoint-size), label) !important;
    }

    &:hover,
    &:focus {
      a {
        color: rgba(255, 255, 255, 0.8)!important;
      }
    }
  }

&__top-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $color-white;
  padding: 0;

  .header-navigation-logo {
		width: rem-calc(75);
		margin: auto;
	}

  .close-nav {
    @include transition;
    display: flex;
    width: auto;
    background-color: transparent;
    padding: 1rem 1.25rem;
    

    & .icon:before {
      color: $color-black;
      font-size: 21px;
      display: block;
      width: 23.42px; //match width of hamburger btn so everything doesn't jiggle when open/close
    }

    &:hover, &:focus {
      .icon:before {
        color: $color-link;
      }
    }

  }
}

&__dropdown-menu {
  overflow: visible;

  .global-nav__search {
    position: relative;
    width: 100%;
    bottom: 0;
    border-top: 0;

    .cta {
      margin-top: rem-calc(10);

    }

  }

  .link-list--mobile-dropdown {
    padding: 0;

  }
}

  &__mobile-howToBuy {
    width: 100%;
    border-bottom: none !important;
    padding: $space 0 $space4;
    background-color: $color-black;

    a {
      @include transition;
      display: flex;
      align-items: center;
      color: $color-white !important;
      font-size: map-get(map-get($typography, $breakpoint-size), button) !important;
      line-height: $line-height !important;
      width: 90% !important;
      margin: auto;
      background-color: $color-primary-brand;

      &:hover {
        background-color: $color-brand-accent-1;
        color: $color-black!important;
      }
    }
  }

  .link-list {
    background: $color-text;

    li {
      min-height: rem-calc(40) !important;
    }
  }
}
