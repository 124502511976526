$block: "industry-icons";
$breakpoint-size: "small";

.#{$block} {
	$gutter-grid-width: rem-calc(10);
	$gutter-side-width: ($gutter-grid-width / 2);
	$icon-box-size: calc(#{percentage(1/2)});

	padding: rem-calc(40) rem-calc(20) rem-calc(60);

	&__content {
		max-width: rem-calc(1440);
		margin: 0 auto;

		h2.blue-border-heading,
		p {
			max-width: rem-calc(850);
			margin-right: auto;
			margin-left: auto;
			text-align: center;
		}

		h2 {
			&.blue-border-heading {
				margin-bottom: rem-calc(18);

				&:after {
					margin: 20px auto 0;
					margin-right: auto;
					margin-left: auto;
				}
			}
		}

		p {
			margin-bottom: 0;
			color: $color-gray-3;
			font-size: $font-size-smaller;
			line-height: rem-calc(22);
		}
	}

	&__icon-grid {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + #{$gutter-grid-width});
		align-items: center;
		margin-top: rem-calc(25);
		margin-right: -$gutter-side-width;
		margin-bottom: rem-calc(18);
		margin-left: -$gutter-side-width;
	}

	&__icon-box {
		flex: 0 0 $icon-box-size;
		width: $icon-box-size;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
		padding-top: 0;
		justify-content: center;

		a {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 4%;
			transition: all $transition-med;
			border: 1px solid $color-gray-5;
			overflow: hidden; //stop svg padding overflow

			svg {
				display: flex;
				padding: 2.5rem 50% 6rem; //overshoot l/r padding to trigger hover animations
				width: 100%;
				height: 100%;
				fill: $color-primary-brand;
				max-width: 6rem;
				box-sizing: content-box;
			}

			span {
				position: absolute;
				bottom: 5px;
				font-family: sans-serif;
				font-size: rem-calc(14);
				font-weight: bold;
				line-height: 1.35;
				text-align: center;
				width: 90%;
				color: #222222; //one-off
				z-index: -1;

				&:after {
					content: '';
					display: block;
					transition: all $transition-med;
					margin: 8px auto 16px;
					opacity: 0;
					width: 0;
					height: 3px;
					background: $color-brand-accent-1;
				}
			}

			&:hover,
			&:focus {
				cursor: pointer;
				border-color: transparent;
				box-shadow: 0 2px 22px rgba(203, 203, 203, .6);

				span:after {
					opacity: 1;
					width: 33%;
				}
				
			}

		}
	}

	&__link-btn {
		$icon-size: rem-calc(16);

		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		margin: 0 auto;
		padding: rem-calc(13) rem-calc(30);
		transition: all $transition-med;
		border: 1px solid $color-primary-brand;
		border-radius: 5px;
		color: $color-orange;
		font-family: $font-bold;
		font-size: $font-size-mobile-standard;
		line-height: rem-calc(18);
		text-transform: uppercase;
		cursor: pointer;

		&:after {
			display: block;
			margin-left: 11px;
			width: 39px;
			height: 13px;
			transition: transform .3s, filter .3s;
			background: url('../images/icons/icon-long-arrow-right-blue.svg') no-repeat center / 100% auto;
			content: '';
		}

		&:hover,
		&:focus {
			border-color: $color-brand-accent-1;
			background-color: $color-brand-accent-1;
			color: $color-black;

			&:after {
				filter: brightness(0);
			}
		}
	}
}
