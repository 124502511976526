$block: "page-title";
$breakpoint-size: "large";

.#{$block} {
	h1 {
		max-width: $global-width;
		margin: 0 auto;
		padding: $space-large map-get($grid-column-gutter, $breakpoint-size);
		font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
	}
}
