$block: "facet-page";
$breakpoint-size: "large";

.#{$block} {
  &__content {
    max-width: $global-width;
    padding: 0 map-get($grid-column-gutter, $breakpoint-size);
    margin: 0 auto;
  }

  &__filters {
    margin-top: 0;
	margin-bottom: $space-mid;
	padding-right: $space5!important;
	padding-left: $space5!important;
  }

  &__filters--hidden-mobile {
    display: block;
  }

  &__filters--no-facets {
    display: none;
  }

  &--as-filters {
    display: none;
  }

  &__items {
	  padding-right: 0!important;
  }

}
