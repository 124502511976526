$block: "login-block";
$breakpoint-size: "large";

.#{$block} {
  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-left: $space-larger;
    padding-right: $space-larger;

  }

  .featured-card,
  .featured-card:last-child {
    margin: 0 0 rem-calc(22);
    width: 49%;
  }

  .featured-card:only-child {
    width: 100%;
  }
}