//////// Block Grid Overrides ////////

@for $col from 1 through $grid-column-count {
	// Flexbox
	@if($col <= $block-grid-max) {
		.small-up-#{$col} { // e.g. small-4 means that there's 4 columns per row (25% wide)
			display: flex;
			flex-wrap: wrap;
			min-width: 100%;

			// No clearfix on the the flex
			@include removeClearfix;

			> .column {
				float: none;
				flex: 0 0 (100% / $col);
				max-width: (100% / $col); // https://stackoverflow.com/questions/19316449/ie10-flexbox-widths-include-padding-causing-overflow-box-sizing-border-box-do

				// Add space between columns when they wrap
				margin-top: rem-calc(10);

				// The first row shouldn't have a margin on top
				&:nth-child(-n + #{$col}) {
					margin-top: 0;
				}
			}
		}

		// when block grid is composed as a list, ensure no bullet points
		ul.small-up-#{$col} li:before {
			content: none;
		}
	}
}

/////// End Block Grid ////////