$block: "featured-card";
$breakpoint-size: "medium";

.#{$block} {
  max-width: rem-calc(314);
  padding: 0 $space2;

  &__content {
    padding: map-get($grid-column-gutter, $breakpoint-size);
  }

  ///////////////////////////////////////////
  // MODIFIER: elements aligned horizontally
  &--horizontally {
    max-width: rem-calc(873);
    width: 100%;
    padding: 0;

    .#{$block} {
      &__wrapper {
        display: block;
      }

      &__content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: rem-calc(260);
      }

      &__text {
        padding: 0 rem-calc(10) 0 $space5;
        margin-right: auto;

        &--meta {
          padding-top: rem-calc(10);
        }
      }

      &__image {
        margin: 0;
        width: 100%;
        height: rem-calc(200);
        max-width: rem-calc(200);
        max-height: inherit;
      }

      &__cta {
        display: block;
        margin-left: auto;
        text-align: right;
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: login cards
  &--loginCard {
    max-width: rem-calc(649);
    padding: 0;
  }

  ///////////////////////////////////////////
  // MODIFIER: Document
  &--document {
    max-width: none;

    .featured-card__content {
      flex-direction: column;
    }

    .featured-card__text {
      padding-left: 0;
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: ARTICLE
  &--article {
    .#{$block} {
    }
  }
}