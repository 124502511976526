$block: "search-location";
$breakpoint-size: "small";

.#{$block} {
  padding: rem-calc(30 20);

  label {
    color: $color-text;
    font-family: $font-bold;
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    line-height: $line-height;
    padding-bottom: rem-calc(10);
    text-transform: uppercase;
  }

  input {
    padding: 12px 20px;
    margin-bottom: rem-calc(40);

    @include placeholder {
      font-family: $font-light;
      color: $color-gray-2;
    }
  }

  select {
    padding: rem-calc(12 20);
    font-family: $font-light;
    color: $color-gray-2;
    margin-bottom: rem-calc(40);
  }

  .search-input {
    position: relative;
    margin-bottom: rem-calc(40);
    input {
      margin-bottom: 0;
    }
  }
  
  .locator-suggestions {
    font-size: $font-size-smallest;
    background-color: white;
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.27);
    position: absolute;
    min-width: 100%;
    max-width: 100%;

    .location {
      padding: $space2 rem-calc($space-base-unit);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; 
      width: 100%;
      white-space: nowrap;
      border-bottom: 1px solid $color-gray-light;
      .icon-how-to-buy {
        display: inline;
        margin: 0 rem-calc(10);
        font-size: $font-size-smaller;
      }

      &.active {
        background-color: $color-gray-lighter-2;
      }
      &:focus {
        background-color: $color-gray-lighter-2;
      }

      &:hover{
        background-color: $color-gray-lighter-2;
        cursor: pointer;
      }
    }
  }

  &__select-input {
    &:last-of-type {
      select {
        margin-bottom: rem-calc(16);
      }
    }
  }

  input,
  select {
    height: rem-calc(48);
    border-radius: $border-radius;
  }

  //overwriting
  .pdp-primary-cta {
    margin-top: rem-calc(4);
    width: 100%;

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.65;
      filter: alpha(opacity=65);
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }



}