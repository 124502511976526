$block: "twoX-content-block-media";
$breakpoint-size: "small";

.#{$block} {

  .media-block__media {
    flex-basis: initial;
    width: 100%;
    max-width: 100%;
    align-self: initial;
    padding-bottom: 0;

    .video-block {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      &__video {
        justify-content: center;
      }
    }

    .video-block__trigger {
      justify-content: center;
      align-items: center;

      a {
        margin: 0;
      }
    }
  }
  
}
