$block: "media-block";
$breakpoint-size: "medium";

.#{$block} {
  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), h3);
  }

  &__content,
  &__media {
    padding: $space5 0;
  }

  &__media {
    .image-block {
      min-height: rem-calc(346);
      picture {
        min-height: rem-calc(346); //IE fix

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
          img {
            width: 100% !important;
            height: auto !important;
            left: 0 !important;
            margin-left: 0 !important;
            max-width: unset !important;
          }
        }
      }
    }
  }
}