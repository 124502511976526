$block: "login-block";
$breakpoint-size: "small";

.#{$block} {
  background: $color-background-lighter;

  .featured-card {
    margin-bottom: map-get($grid-column-gutter, $breakpoint-size);

    &:last-child {
      margin-bottom: 0;
    }
  }
}