$block: "featured-slider";
$breakpoint-size: "small";

.#{$block} {

  .js-featuredSlider {
    justify-content: center;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      > div {
        height: 100%;
      }

      .featured-card {
        height: 100%;

        &__content {
          display: flex;
          flex-direction: column;
        }

        &__cta {
          margin-top: auto;
        }
      }
    }
  }
}
