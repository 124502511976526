$block: "newsroom-article";
$breakpoint-size: "medium";

.#{$block} {
    flex-direction: row;

    &__content {
        flex: 0 0 80%;
        padding-right: rem-calc(140);
    }
}
