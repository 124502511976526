$block: "nav-item";
$breakpoint-size: "large";

.#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__link-label {
    span {
      color: $color-gray;
      font-family: $font-bold;
      text-transform: uppercase;
    }

    .is-active {
      color: $color-gray-lightest !important;
    }
  }

  &--isCountry {
    display: none;
  }
}