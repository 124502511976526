$block: "career-page";
$breakpoint-size: "small";

.#{$block} {
  &__body-rte {
    p {
      line-height: $line-height-large;
    }
  }
}
