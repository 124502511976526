$block: "search-results-page";
$breakpoint-size: "large";

.#{$block} {

  &__header {

    &__inner {
      max-width: $global-width;
      font-size: rem-calc(42);
      font-weight: 900;
      border-top: 0;
      line-height: rem-calc(60);
      font-family: $font-black;
    }
  }

  .resource-listing-page {
    padding: 0;
  }

  &__no-results__inner, &__brands {
    max-width: $global-width;
    padding: 0 0 rem-calc(77) $nav-left-padding;
  }

  &__brands {
    padding-top: 50px;
  }
}