$block: "pdp";
$breakpoint-size: "large";

.#{$block} {
  &__content {
    .component {
      max-width: $global-width-pdp-comp;
    }
  }
}
