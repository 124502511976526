$block: "events-block";
$breakpoint-size: "small";

.#{$block} {
  background: $color-background-lighter;
  padding: $space5;
  margin-top: 0;
  margin-bottom: 0;

  &__list {
    background: $color-white;
    padding-top: map-get($grid-column-gutter, $breakpoint-size);
    margin-bottom: $space5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__heading {
    text-align: center;
    font-weight: bold;
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    line-height: $line-height;
  }
}
