$block: "interact-block";
$breakpoint-size: "small";

.#{$block} {
	display: inline-flex;
	width: 100%;
	margin-top: rem-calc(20);

	@media print {
		display: none;
	}

	&__share,
	&__print {
		width: 50%;
		display: flex;
	}

	&__share {
		display: inline-flex;

		> span:first-child {
			color: $color-gray-3;
			font-family: $font-bold;
			font-size: rem-calc(11);
			line-height: rem-calc(13);
			text-transform: uppercase;
			padding-right: rem-calc(5);
			align-self: center;
		}

		a {
			padding: 0 rem-calc(5);
			
			span {
				vertical-align: middle;
				font-size: rem-calc(16);
			}
		}
	}

	&__print {
		justify-content: flex-end;

		a {
			color: $color-gray-3;
			font-family: $font-bold;
			font-size: rem-calc(11);
			line-height: rem-calc(13);
			text-transform: uppercase;
			align-self: center;

			span {
				vertical-align: middle;
				font-size: rem-calc(16);
				color: $color-link;
				padding-left: rem-calc(5);
			}

			&:hover, &:focus {
				span {
					color: $color-gray-3;
				}
			}
		}
	}
}