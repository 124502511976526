// Global Styles

ul {
  margin: 0;
}

a, input, button, select, textarea, [tabindex]:not([tabindex^="-"]) {
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

body {
  font-family: $font-regular;
  //fix for IE
  .ie & {
    overflow-x: hidden;
  }
}

.body--mobile-nav-expanded {
  position: fixed;
  height: 100vh;
}

.body-fixed {
  overflow: hidden;
}

p {
  font-size: $font-size-smaller;
  color: $color-gray-3;
  font-family: $font-regular;

  a {
    @include underlineLink;
  }
}

a {
  color: $color-link;

  &:hover, &:focus {
    color: $color-gray-3;
  }
}
