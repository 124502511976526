$block: "resource-listing-page";
$breakpoint-size: "small";

.#{$block} {
  background: $color-gray-lightest;

  .search-box__input {
    background: $color-white;
  }

  .featured-card {
    height: 100%;

    &__cta {
      display: block;
      width: 100%;
      text-align: center;

      .cta {
        width: 100%;
        height: rem-calc(45);
      }
    }

    &.featured-card--document {
      margin: 0;
      padding: rem-calc(10) 0;
    }
  }
}