$block: "banner-content";
$breakpoint-size: "large";

.#{$block} {
  width: 100%;
  max-width: $global-width;
  margin: auto;

  &__text {
    &--category {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }

    &--title {
      font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    }


    &--description {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }
  }

  &__cta {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: $space-mid;

    .cta {
      max-width: inherit;
    }

    .pdp-primary-cta {
     margin-right: rem-calc(10);
    }
  }

  &__wrapper {
   max-width: 50%;
  }

  // Alignment Classes
  &.contentCenter {
    .#{$block}__wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.contentRight {
    .#{$block}__wrapper {
      margin-left: auto;
    }
    + .hero-image {
      &:before {
        background: linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.8) 100%);
      }
    }
  }
}
