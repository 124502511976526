$block: "locator-results-block";
$breakpoint-size: "small";

.#{$block} {
  line-height: rem-calc(16);


  &__edit-location-title {
    padding: rem-calc(20);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem-calc(21);
    color: #0D0D0D;
    font-size: rem-calc(14);


    .icon {
      padding-right: rem-calc(9);
      font-size: rem-calc(15) !important;

      .icon-count-text {
        color: $color-white !important;
      }
    }
  }

  &__edit-location-link {
    color: $color-primary-brand;
    font-size: rem-calc(12);
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  &__distributor-type-heading {
    padding-left: rem-calc(20);
    height: rem-calc(35);
    background-color: $color-primary-brand;
    color: $color-white;
    font-size: rem-calc(10);
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__distributor-type-heading--black-bg {
    background-color: $color-black;

  }

  .info-block--show-details {
    border-top: 0.5px solid $color-gray-light;
    background-color: $color-white;

    &:hover{
      background-color: $color-white;
    }

    .number{
      background-color: $color-brand-accent-1;
      color: $color-black;
    }

    .js-infoBrands {
      display: block;
    }

    .js-hideDetailsBlock {
      display: none;
    }

    .js-brandsList {
      display: block;
    }
  }

  .info-block--hide-all {
    display: none !important;
  }

}