$block: "location-block";
$breakpoint-size: "large";

.#{$block} {
  &__head {
    h1 {
      color: $color-white;
      font-size: rem-calc(12);
    }
  }
}