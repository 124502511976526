$block: "regional-selector";
$breakpoint-size: "medium";

.#{$block} {
	&__title {
		margin-bottom: rem-calc(40);

		h1 {
			font-size: rem-calc(25); // not in scale
		}

		p {
			font-size: $font-size-mobile-standard;
		}
	}

	&--menu {
		&:before {
			background-size: 130% auto;
		}
	}
}