$block: "dropdown-trigger";
$breakpoint-size: "large";

.#{$block} {
  border-bottom: none !important;
  height: 100%;
  display: flex;
  flex-shrink: 0; // IE fix

  &--active {
    z-index: 100;
  }

  &__link {
    @include transition;
    height: rem-calc(73);
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    font-family: $font-regular;
    padding-right: 0;
    color: $color-black !important;
    position: relative;

    &:hover, &:focus {
      color: $color-primary-brand !important;
    }
  }

  &__label {
    color: $color-gray;
    font-family: $font-bold;
    text-transform: uppercase;
    position: absolute;
    top: rem-calc(15);
  }

  &__category {
    display: flex;
    align-items: center;
    align-content: center;
    font-weight: 400;
    font-family: $font-bold;
    height: rem-calc(21);
    padding: $space4 0;
  }

  &__icon {
    color: $color-primary-brand;
    font-size: $arrow-size;
    padding: 0 $space2;
    padding-right: $space3;
    //margin-top: rem-calc(3);

    &:before {
      display: inline-block;
    }
  }

  &__link--active {
    background-color: $color-gray-lightest;

    .dropdown-trigger__icon {
      margin-top: rem-calc(1);

      &:before {
        transform: rotate(180deg);
      }
    }
  }

  &--supplementary-link {
    display: flex;
    justify-content: flex-end;
    flex-grow: 2;

    .dropdown-trigger__link {
      text-align: right;
      padding-right: 0 !important;
    }
  }

  &--direct {

    .dropdown-trigger__icon {
      display: none;
    }
  }
}
