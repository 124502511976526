$block: "regional-selector";
$breakpoint-size: "small";

.#{$block} {
	&__accordion-btn {
		$triangle-width: 4px;
		$triangle-height: 6px;

		position: relative;
		padding: rem-calc(16) rem-calc(17) rem-calc(14);
		transition: border $transition-med;
		background-color: $color-gray-lightest;
		color: $color-black;
		font-size: $font-size-mobile-small;
		font-weight: bold;
		line-height: 1;
		text-transform: uppercase;
		cursor: pointer;

		&:after {
			position: absolute;
			top: 0;
			right: 17px;
			bottom: 0;
			left: auto;
			width: 0;
			height: 0;
			margin-top: auto;
			margin-bottom: auto;
			border-style: solid;
			border-width: $triangle-height $triangle-width 0 $triangle-width;
			border-color: $color-link transparent transparent;
			content: '';
		}

		&.active {
			background-color: $color-white;

			&:after {
				border-width: 0 $triangle-width $triangle-height $triangle-width;
				border-color: transparent transparent $color-link;
			}
		}
	}

	&__accordion-content {
		display: none;
		padding: rem-calc(7) rem-calc(17) rem-calc(14);
		background-color: $color-white;

		a {
			display: flex;
			align-items: center;
			margin-bottom: rem-calc(15);
			font-size: $font-size-smaller;
			text-decoration: underline;

			&:last-child {
				margin-bottom: rem-calc(8);
			}
		}

		&--show {
			display: block;
		}
	}

	&__content {
		position: relative;
		max-width: 990px;
		margin-right: auto;
		margin-left: auto;
		padding-right: rem-calc(15);
		padding-left: rem-calc(15);
		z-index: 1;
	}

	&__flag {
		img {
			display: inline-block;
			width: 20px;
			height: 15px;
			margin-right: rem-calc(9);
			vertical-align: middle;
		}
	}

	&__head {
		background: $color-black;
		padding: $space5 0;

		.brand-logo {
			background: transparent;
			height: rem-calc(40);
		}
	}

	&__item {
		border-bottom: 1px solid $color-gray-4;

		&:last-child {
			border-bottom: 0;
		}
	}

	&__item-wrapper {
		border: 1px solid $color-gray-4;
	}

	&__title {
		margin-bottom: rem-calc(30);
		text-align: center;

		h1 {
			margin-bottom: rem-calc(17);
			color: $color-black;
			font-size: $font-size-large;
		}

		p {
			margin-bottom: 0;
			font-size: $font-size-smaller;
		}
	}

	&--menu {
		position: relative;
		padding: rem-calc(40) 0 rem-calc(50);
		background: radial-gradient(circle, rgba($color-white, .1), $color-border);
	
		&:before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url('#{$filepath-img}bg-world-map.svg') no-repeat top center / 170% auto;
			content: '';
		}
	}
}
