$block: "brands-multi-content-block";
$breakpoint-size: "large";

.#{$block} {
  	padding: $space-mid (map-get($grid-column-gutter, $breakpoint-size) - $brand-grid-spacing-lg) 0;

	.grid-x {
		max-width: $global-width;
		margin: 0 auto;
	}

  &__brand {
    margin: $brand-grid-spacing-lg 0;

	.brand-logo {
		padding: 0 $brand-grid-spacing-lg;
	}
  }
}
