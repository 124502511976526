$block: "generic-iframe-modal";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;
  position: relative;

  &.button {
    @include transition;
    background: $color-primary-brand;
    color: $color-white;
    border: none;
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    
    .utility-nav--pdp-sticky-nav & {
      padding-top: $space2;
      padding-bottom: $space2;
      margin-top: $space-smallest;
      margin-bottom: $space-smallest;
      margin-left: map-get($grid-column-gutter, $breakpoint-size);
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }
  
    &:visited {
      color: $color-white;
    }
  
    .icon {
  
      &:before {
        font-size: rem-calc(16);
        position: relative;
        top: 2px;
        left: -10px;
      }
    }
  
    &:hover, &:focus {
      color: $color-text;
      background: $color-brand-accent-1;
    }
  }

  .reveal {
    width: rem-calc(743);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: rem-calc(24);
    top: rem-calc(24);
    width: rem-calc(17);
    height: rem-calc(17);
    background: $color-gray-light;
    border-radius: 100px;

    span {
      position: relative;


      &:before {
        @include iconStyles;
        content: map-get($icons, icon-close-menu);
        font-size: rem-calc(7);
        position: absolute;
        top: rem-calc(5);
        left:rem-calc(5);
        color: $color-white;
      }
    }
  }

  &__wrapper {
    margin-top: 40px;
    padding: 0 map-get($grid-column-gutter, $breakpoint-size);
  }
}