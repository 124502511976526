$block: "subscribeBox";
$breakpoint-size: "small";

.#{$block} {
  font-family: $font-regular;
  width: 100%;

  &__message {
    text-transform: uppercase;
    line-height: $line-height;
    color: $color-white;
    font-size: $font-size-smallest;
  }

  &__label {
    margin-top: $space3;
    position: relative;
  }

  &__input {
    color: $color-text;
    border: none;
    border-radius: $border-radius;
    padding: rem-calc(14);
    font-size: map-get(map-get($typography, $breakpoint-size), label);

    @include placeholder {
      color: $color-gray-2;
    }

    &:focus {
      border: none;
      @include placeholder {
        opacity: .2;
      }
    }
  }

  &__button {
    cursor: pointer;
    display: inline-block;
    font-family: $font-bold;
    font-size: map-get(map-get($typography, $breakpoint-size), button);
    line-height: $line-height;
    text-transform: uppercase;
    color: $color-primary-brand-dark;
    padding: rem-calc(9) $space5;
    position: absolute;
    top:0;
    right: 0;
    z-index: 1;
    background: $color-white;
    border-radius: $border-radius;
  }
}