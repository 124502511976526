$block: "utility-nav";
$breakpoint-size: "large";

.#{$block} {
  &__region {
    display: block;

    .dropdown-content {
      position: absolute;
      top: 40px;
      width: fit-content;
      padding: rem-calc(15) rem-calc(17);
      background-color: #eee;

      a {
        &:hover,
        &:focus {
          background-size: 0% 0;
          color: $color-primary-brand;
        }
      }

      &__languages {
        margin: rem-calc(10) 0;
        padding-bottom: rem-calc(5);

        li {
          margin-bottom: rem-calc(15);

          a {
            position: relative;

            span {
              position: relative;
              left: -6px;
              margin-right: rem-calc(6);
              color: $color-gray;
              font-family: $font-bold;
              font-size: 12px;

              &::before {
                position: absolute;
                top: -4px;
                left: -6px;
                width: 26px;
                height: 26px;
                border: 1px solid $color-gray-light;
                border-radius: 50%;
                content: "";
                z-index: -1;
              }
            }

            &.dropdown-content__selected {
              span {
                color: $color-white;

                &::before {
                  background-color: $color-primary-brand;
                }
              }
            }
          }
        }
      }
    }

    &--popup {
      display: block;
    }
  }

  &__reg {
    .#{$block}__region {
      padding: rem-calc(10) 0;
    }
  }

  &__wrapper {
    margin: 0 auto;
    padding-left: $space-mid;
  }

  &__brandLink,
  .link-list a {
    @include transition;
    color: $color-white;
    &:hover,
    &:focus {
      color: $color-primary-brand-light;
    }
  }

  .link-list--utility-nav {
    display: flex;
  }

  &__brandlink-wrapper {
    display: inline;
    min-width: auto;
    a {
      display: inline-block;
    }
  }

  &__brandLink {
    padding: 7px 0 7px $space5;
    font-family: $font-bold;
    font-size: rem-calc(12);
    font-weight: 300;
    line-height: $line-height;
    white-space: nowrap;
    opacity: .6;
  }

  .js-brandsBlock {
    .component {
      margin-top: 0 !important;
    }
  }

  .loginBtn {
    display: inline;
  }

  /////////////////////////////////
  // PDP Navigation
  &--pdp-sticky-nav {
    min-height: rem-calc(50);

    .utility-nav__wrapper {
      justify-content: center;
      padding-left: 0;

      .link-list--utility-nav {
        flex-grow: inherit;
      }
    }

    .link-list__item {
      @include transition;
      opacity: .75;

      &.is-active,
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  .js-brandsBlock {
    top: rem-calc(38);
  }

  ////  Modifier
  // Region - updated utility nav
  &__reg {
    .#{$block}__region {
      padding-left: 0;
    }

    .utility-nav__region {
      display: flex;
      font-size: rem-calc(12);
    }
  }
}
