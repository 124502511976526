$block: "four-pillar-hero";
$breakpoint-size: "small";

.#{$block} {
	$gradient-shadow: linear-gradient(to bottom, $color-black, transparent);

	position: relative;
	border-top: 5px solid $color-primary-brand;
	border-bottom: 10px solid $color-black;

	&:after {
		position: absolute;
		right: 0;
		bottom: -30px;
		left: 0;
		width: 0;
		height: 0;
		margin-right: auto;
		margin-left: auto;
		border-style: solid;
		border-width: 20px 16px 0 16px;
		border-color: $color-black transparent transparent;
		content: '';
	}

	&__title {
		$lede-color: #a7a7a7;

		padding: rem-calc(36) rem-calc(26) 0;
		background-color: $color-black;

		h1 {
			position: relative;
			margin-bottom: 0;
			color: $color-white;
			font-family: $font-bold;
			font-size: $font-size-larger;
			line-height: 1.3em;
			text-align: center;
			z-index: 2;

			span {
				display: block;
				margin-bottom: rem-calc(6);
				color: $lede-color;
				font-family: $font-regular;
				font-size: $font-size-small;
				line-height: 1.2em;
			}
		}

		&:after {
			position: absolute;
			top: 95px;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 150px;
			background: $gradient-shadow;
			content: '';
			z-index: 1;
			pointer-events: none;
		}
	}

	&__videos {
		display: flex;
		flex-direction: column;
	}

	&__video {
		position: relative;
		width: 100%;
		height: rem-calc(180);
		border-bottom: 2px solid $color-black;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		figure,
		figure picture,
		figure picture source,
		figure picture img,
		video,
		&-name {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		figure,
		figure picture,
		figure picture source,
		figure picture img,
		video {
			object-fit: cover;
		}

		&-name {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			z-index: 2;

			a {
				min-width: rem-calc(220);
				margin: 0;
				padding: rem-calc(17) rem-calc(35) rem-calc(16);
				border: 1px solid rgba($color-yellow, .6);
				background: rgba($color-black, .55);
				color: $color-white;
				font-family: $font-bold;
				font-size: $font-size-small;
				line-height: 1.2em;
				text-align: center;
				text-shadow: $color-black 0 2px 12px;
				text-transform: uppercase;
			}
		}

		&:first-child {
			border-top: 1px solid $color-gray-4;
		}

		&:last-child {
			border-bottom: 0;
		}

		// Hide Play button + controls on iOS
		video::-webkit-media-controls {
			display:none !important;
		}

		video {
			display: none;
		}
	}
}
