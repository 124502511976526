$block: "quick-menu";
$breakpoint-size: "small";

.#{$block} {
  z-index: 10;
  width: 100vw;

  &.quick-menu-expanded {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  &__small {
    background: rgba(0, 0, 0, 0.7);

    nav {
      display: flex;
      height: 100vh;
    }

    .is-drilldown {
      max-width: inherit !important;
      height: 100vh !important;
      width: 85%;
    }

    .menu {
      height: 100vh;
      overflow: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .quick-menu__navMenu {
      height: 100%;
      background: $color-white;

      li {
        width: 99%;
        min-height: rem-calc(60);
        font-size: map-get(map-get($typography, $breakpoint-size), p);
      }
    }

    .quick-menu__link a {
      border-bottom: 1px solid $color-border;
      color: $color-gray-3;
      padding: rem-calc(20 22);
    }

    .quick-menu__name a {
      font-family: $font-medium;
      border-bottom: 1px solid $color-border;
      padding: rem-calc(20 22);
      background: $color-primary-brand;
      color: $color-white;
    }

    .close-menu {
      cursor: pointer;
      background: $color-black;
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem-calc(54);
      width: 15%;

      .icon {
        color: $color-white;
        font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
      }
    }

    .js-drilldown-back a {
      background: $color-black;
      color: $color-white;
    }
  }
}