$block: "teaser-column-block";
$breakpoint-size: "large";

.#{$block} {
  padding: 0;

  .teaser-card {
    margin: 0 0 $space5;
  }

  &__twoCol,
  &__threeCol {
    display: flex;
    flex-wrap: wrap;
    max-width: $global-width;
    margin: 0 auto;
    padding: $space-largest map-get($grid-column-gutter, $breakpoint-size);
  }

  &__twoCol {
    justify-content: space-between;
    .teaser-card {
      width: 49%;
    }
  }

  &__threeCol {
    justify-content: flex-start;
    .teaser-card {
      width: 31%;
      margin-left: 1%;
      margin-right: 1%;
    }
  }
}
