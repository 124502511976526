$block: "dropdown-trigger";
$breakpoint-size: "medium";

.#{$block} {
  // margin-left: - map-get($grid-column-gutter, $breakpoint-size) !important;
  // padding-right: map-get($grid-column-gutter, $breakpoint-size) !important;

  &__link {
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    //padding-left: map-get($grid-column-gutter, $breakpoint-size) !important;
  }

  &__label {
    font-size: map-get(map-get($typography, $breakpoint-size), nav-label);
  }
}
