$block: "industry-icons";
$breakpoint-size: "medium";

.#{$block} {
	$gutter-grid-width: rem-calc(18);
	$gutter-side-width: ($gutter-grid-width / 2);
	$icon-box-size: calc(#{percentage(1/3)});

	padding: rem-calc(53) rem-calc(20) rem-calc(65);

	&__icon-grid {
		width: calc(100% + #{$gutter-grid-width});
		margin-top: rem-calc(35);
		margin-right: -($gutter-grid-width / 2);
		margin-bottom: rem-calc(25);
		margin-left: -($gutter-grid-width / 2);
	}

	&__icon-box {
		flex: 0 0 $icon-box-size;
		width: $icon-box-size;
		padding-top: 0;

		a {
			span {
				margin-top: rem-calc(20);
			}
		}
	}
}
