$block: "rte-block";
$breakpoint-size: "small";

.#{$block} {
  font-family: $font-regular;
  //padding: map-get($grid-column-gutter, $breakpoint-size);

  h1 {
    font-size: map-get(map-get($typography, $breakpoint-size), h1);
  }

  h2 {
    font-size: map-get(map-get($typography, $breakpoint-size), h2);
  }

  h3 {
    font-size: map-get(map-get($typography, $breakpoint-size), h3);
  }

  h4 {
    font-size: map-get(map-get($typography, $breakpoint-size), h5);
  }

  h5 {
    font-size: map-get(map-get($typography, $breakpoint-size), h5);
  }

  h6 {
    font-size: map-get(map-get($typography, $breakpoint-size), h6);
  }

  img[style="float: left;"] {
    padding-right: map-get($grid-column-gutter, $breakpoint-size);
  }

  img[style="float: right;"] {
    padding-left: map-get($grid-column-gutter, $breakpoint-size);
  }

  ul {
    margin-left: rem-calc(10);
    list-style: none;
  }

  li {
    position: relative;
    margin-left: rem-calc(6);
    padding: 0;
    color: $color-gray-3;
    font-family: $font-regular;
    font-size: map-get(map-get($typography, $breakpoint-size), p);
  }

  ul li::before {
    position: absolute;
    top: -2px;
    left: -14px;
    display: inline-block;
    width: 1em;
    color: $color-primary-brand;
    font-size: rem-calc(19);
    content: "\2022";
  }

  &--flex {
    display: flex;
    align-items: center;
  }

  .content-block {
    background-color: inherit;
    color: $color-gray-3;

    .rte-block {
      color: $color-gray-3;

      p {
        color: $color-gray-3;
      }
    }
  }

  .twoX-content-block {
    padding-right: 0;
    padding-left: 0;

    &.component {
     padding: 0 !important;
    }

    .content-block__content {
      padding: map-get($grid-column-gutter, $breakpoint-size)*2 map-get($grid-column-gutter, $breakpoint-size) map-get($grid-column-gutter, $breakpoint-size)*2 0;
      background-color: $color-white;
    }
  }
}
