$block: "img-container";
$breakpoint-size: "medium";

.#{$block} {
  //max-width: rem-calc(1136);
  width: 100%;
  margin: auto;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  a {
    //height: rem-calc(50)!important;
  }

  &__image {
    margin: auto;
    //height: 100%;
  }

  .js-featuredBrandsSlider {
	  .slick-slide > div {
		  width: 100%;
		  padding-top: 40%;
	  }

	  a {
		  padding: 0 rem-calc(20);
	  }
  }
}
