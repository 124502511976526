$block: "success-msg";
$breakpoint-size: "large";

.#{$block} {
  &__wrapper {
    .rte-block {
      h2 {
        font-size: rem-calc(32);
      }

      p {
        font-size: $font-size-mobile-large;
      }
    }
  }
}