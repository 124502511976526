$block: "video-listing-page";
$breakpoint-size: "small";

.#{$block} {
  background: $color-gray-lightest;

  .search-box__input {
    background: $color-white;
  }

  .video-block {
    height: auto;

    picture, img {
      height: rem-calc(162);
    }

    &__video {
      height: auto;
    }

    &__details {
      height: rem-calc(250);
      overflow: hidden;
      padding: map-get($grid-column-gutter, $breakpoint-size)/2;
    }

    &__title {
      padding-bottom: 0;
    }

    &__description {

      p {
        overflow: hidden;
        margin-bottom: rem-calc(8);
      }
    }
  }

}