$block: "social-media-icons";
$breakpoint-size: "small";

.#{$block} {

    &__title {
        font-family: $font-bold;
    }

    &__icons {
        margin-top: rem-calc(15);
    }

    & .icon-instagram::before {
        position: relative;
        bottom: -4px;
        width: 30px;
        height: 30px;
    }

    a {
        margin-right: rem-calc(20);
        
        span {
            font-size: rem-calc(27);
        }
    }
}