$block: "vertical-slider";
$breakpoint-size: "medium";

.#{$block} {
  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__sliderNav {
    width: rem-calc(135);
    text-align: center;

    .vertical-slider__img {
      border: $border-thinnest solid $color-border;
      border-radius: $border-radius;
      margin-top: $space5;
      height: rem-calc(50);
      width: rem-calc(50)!important;

      img {
        // -o-object-fit: cover;
        // object-fit: cover;
        border-radius: $border-radius;
      }
    }

    .slick-current {
      .vertical-slider__img {
        border: $border-thin solid $color-primary-brand;
        border-radius: $border-radius;
        transform: scale(1.2);
      }
    }
  }

  &__mainImage {
    width: rem-calc(339);

    .vertical-slider__img {
      width: 100%;
      height: rem-calc(530);
    }
  }
}