$block: "image-block";
$breakpoint-size: "large";

.#{$block} {
  .slick-list {
    margin: 0 rem-calc(40);
  }

  .slick-prev {
    left: 0 !important;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }

  .slick-next {
    right: 0 !important;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
}