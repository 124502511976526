$block: "featured-card";
$breakpoint-size: "large";

.#{$block} {
  max-width: rem-calc(314);

  &__banner {
    width: 100px;
  }

  &__content {
    padding: rem-calc(19);
  }

  &__image {
    width: 100%;
    max-width: rem-calc(237);
    height: rem-calc(237);

    a {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    padding: rem-calc(30) 0 $space5;

    &--center {
      text-align: center;

      .featured-card__text--title {
        font-size: map-get(map-get($typography, $breakpoint-size), p);
      }
    }

    &--title {
      font-size: rem-calc(21);
    }

    &--subtitle {
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    }

    &__description {
      font-size: rem-calc(14);
    }
  }

  &__cta {
    display: block;
    min-width: rem-calc(147);
    text-align: left;
  }

  ///////////////////////////////////////////
  // MODIFIER: elements aligned horizontally
  &--horizontally {
    max-width: rem-calc(873);

    .#{$block} {
      &__text {
        max-width: rem-calc(390);
      }

      &__image {
        margin: 0;
        width: rem-calc(220);
        height: rem-calc(220);
        max-width: rem-calc(220);
        max-height: inherit;
      }

      &__cta {
        margin-right: $space5;
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: login cards
  &--loginCard {
    max-width: inherit;

    .#{$block} {
      &__text {
        &--title {
          font-size: map-get(map-get($typography, $breakpoint-size), p-large);
        }
      }

      &__content {
        height: 100%;
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: Document
  &--document {
    max-width: none;

    .#{$block} {
      &__text {
        &--title {
          font-size: rem-calc(21);
        }
      }

      &__content {
        flex-direction: row;
      }

      &__cta {
        width: inherit;
        padding-top: map-get($grid-column-gutter, $breakpoint-size);
        margin-right: 0;
        margin-top: 0;

        .cta {
          width: rem-calc(175);
        }
      }

      &__text {
        max-width: 70%;
      }
    }
  }

  ///////////////////////////////////////////
  // MODIFIER: ARTICLE
  &--article {
    .#{$block} {
    }
  }
}
