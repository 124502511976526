$block: "rte-block";
$breakpoint-size: "large";

.#{$block} {

  h1 {
    font-size: map-get(map-get($typography, $breakpoint-size), h1);
  }

  h2 {
    font-size: map-get(map-get($typography, $breakpoint-size), h2);
  }

  h3 {
    font-size: map-get(map-get($typography, $breakpoint-size), h3);
  }

  h4 {
    font-size: map-get(map-get($typography, $breakpoint-size), h5);
  }

  h5 {
    font-size: map-get(map-get($typography, $breakpoint-size), h5);
  }

  h6 {
    font-size: map-get(map-get($typography, $breakpoint-size), h6);
  }

  p, li {
    font-family: $font-regular;
    color: $color-gray-3;
    font-size: $font-size-small;
    line-height: $line-height-large;
  }

  .twoX-content-block {
    &.component {
      margin: 0 !important;
    }

    .content-block__content {
      padding-bottom: 0;
      height: auto;
    }
  }
}
