$block: "learn-more-btn";
$breakpoint-size: "small";

.#{$block} {
	display: flex;
	align-items: center;
	width: fit-content;
	padding: rem-calc(13) rem-calc(29) rem-calc(13) rem-calc(31);
	border-radius: 5px;
	border-color: rgba($color-white, .5);
	color: $color-brand-accent-1;
	font-size: $font-size-mobile-standard;
	font-weight: bold;
	line-height: 17px;

	&:after {
		display: block;
		margin-left: 11px;
		width: 39px;
		height: 13px;
		transition: transform .3s, filter .3s;
		background: url('../images/icons/icon-long-arrow-right.svg') no-repeat center / 100% auto;
		content: '';
	}

	&:hover,
	&:focus {
		&:after {
			transform: translateX(5px);
			filter: brightness(0);
		}
	}
}
