$block: "hbspt-form";
$breakpoint-size: "small";

.#{$block} {
  padding: 0 $space5;

  .hs-richtext {
    h1 {
      font-family: $font-bold;
      line-height: $line-height-large;
      color: $color-text;
      font-size: rem-calc(24);
      margin: 0;
    }

    p {
      font-size: rem-calc(11);
      color: $color-body-text;
      padding-bottom: map-get($grid-column-gutter, $breakpoint-size);
    }
  }

  fieldset {
    max-width: none !important;
  }

  li {
    list-style: none;

    .hs-error-msg {
      font-size: rem-calc(11);
    }
  }

  label {
    font-family: $font-bold;
    text-transform: uppercase;
    font-size: map-get(map-get($typography, $breakpoint-size), label);
    margin-bottom: rem-calc(10);
  }

  select,
  input,
  textarea {
    width: 100% !important;
    border-radius: $border-radius;
    border: $border-thin solid $color-border-input;
    padding: rem-calc(12) $space5;
    height: rem-calc(48);
    font-family: $font-light;
    color: $color-gray-2;
    text-transform: capitalize;

    @include placeholder {
      color: $color-placeholder;
    }

    &.error {
      box-shadow: 0 0 6px 0 $color-border-input;
      margin-bottom: rem-calc(10);
    }
  }

  input[type="checkbox"] {
    width: initial !important;
    height: initial !important;
  }

  textarea {
    height: rem-calc(149);
    margin-bottom: rem-calc(26);
    text-transform: none;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  label.hs-error-msg {
    margin-bottom: rem-calc(30);
  }

  fieldset .input {
    margin: 0 !important;
  }

  .form-columns-2 .hs-form-field,
  .form-columns-3 .hs-form-field {
    width: 100% !important;
  }

  .hs_submit {
    text-align: center;

    input {
      cursor: pointer;
      border: none;
      font-family: $font-bold;
      font-size: rem-calc(13);
      background: $color-primary-brand;
      color: $color-white;
      width: 100%;
      max-width: rem-calc(295);
      text-transform: uppercase;

      &:hover {
        opacity: .9;
      }
    }
  }

  .legal-consent-container {
    background: $color-background-lighter;
    padding: rem-calc(15 27) 0;
    margin-bottom: rem-calc(24);

    .hs-richtext {
      p {
        font-size: rem-calc(12);
      }
    }

    input[type=checkbox] {
      width: auto !important;
      height: auto;
      margin-bottom: 0;
    }

    span {
      text-transform: none;
      color: rgba(0,0,0,0.85);
      margin-left: 5px !important;

      p {
        font-size: rem-calc(12);
      }
    }

    .hs-form-booleancheckbox label {
      display: flex;
      align-items: baseline;
    }
  }

  .hs_recaptcha {
    .grecaptcha-badge {

    }
  }

  // REQUEST QUOTE CONFIRMATION
  .submitted-message {
    p:first-child {
      font-family: $font-black;
      font-weight: 900;
      color: $color-primary-brand-lighter;
      font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    }

    p:not(:first-child) {
      color: $color-body-text;
      font-size: map-get(map-get($typography, $breakpoint-size), h4);
      margin-bottom: $space;
    }
  }
}