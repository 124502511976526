$block: "teaser-card";
$breakpoint-size: "small";

.#{$block} {
  background: $color-white;
  border: $border-thinnest solid $color-border;

  &--blue-bg {
    background-color: $color-primary-brand;

    .#{$block}__text {
      background-color: $color-primary-brand;
    
      &--description, p {
        color: $color-white;
      }

      &--heading {
        color: $color-white;

        a {
          color: $color-white;

          &:hover {
            color: $color-brand-accent-1;
          }
        }
      }
    }
  }

  &__download-icon {
    position: relative;
    &:before {
      font-family: 'cmco-icon' !important;
      content:  "\e93a";
      color: $color-gray;
      position: absolute;
      display: block;
      font-size: 80px;
      top: 50%;  
      left: 50%;
      transition: color .2s ease-in-out;
      transform: translate(-50%, -50%);
    }

    &:hover {
      &:before {
        color: $color-brand-accent-1;

      }
    }
  }

  &__image {
    width: 100%;
    height: rem-calc(295);

    img,
    picture {
      -o-object-fit: cover;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    a {
      height: 100%;
      width: 100%;
    }
  }


  &__text {
    padding: map-get($grid-column-gutter, $breakpoint-size);
    text-align: center;

    &--heading {
      color: $color-text;
      font-weight: bold;
      font-size: map-get(map-get($typography, $breakpoint-size), p);
      a {
        @include transition;
        color: $color-text;
        &:hover, &:focus {
          color: $color-primary-brand;
        }
      }
    }

    &--description {
      color: $color-body-text;
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      margin: 0;
    }
  }

  &__textCenter {
    text-align: center;
  }
}
