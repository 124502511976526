$block: "article-listing-page";
$breakpoint-size: "medium";

.#{$block} {
  &__title {
    padding: $space-large $space-larger;
    max-width: $global-width;
    margin: auto;
  }

  .hero-breadcrumbs {
    position: relative;

    li {

      a,
      &:after,
      &:last-of-type {
        color: $color-text !important;
      }
    }
  }

  .featured-card {
    margin-top: 0;

    &__cta {
      min-width: rem-calc(175);
      width: auto;
      margin-top: 0;


      .cta {
        min-width: rem-calc(175);
      }
    }

  }
}