$block: "twoX-content-block";
$breakpoint-size: "small";

.#{$block} {

  .content-block {

    &:first-child {
      margin-bottom: $space;
    }

    .image-block {

      &__picture {
        min-height: rem-calc(100);
        picture {
          min-height: rem-calc(100);
        }
      }

      img {
        object-fit: cover;
      }
    }
  }
}
