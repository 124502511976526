$block: "overview-text";
$breakpoint-size: "large";

.#{$block} {
  margin-bottom: $space-large;

  h1 {
    text-align: left;
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    margin-bottom: 1rem;
  }

  .rte-block {
    margin-top: $space;
  }

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    margin-bottom: $space4;
    line-height: $line-height;
  }

  &__heading {
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    margin-bottom: 0;
  }

  &__sub-heading,
  &__description,
  .rte-block p {
    max-width: 60rem;
    font-size: map-get(map-get($typography, $breakpoint-size), p);
  }

  &--article-detail {

    .overview-text {
      padding: 0;

      &__heading-h1,
      &__heading {
        font-size: rem-calc(42);
      }

      &__sub-heading {
        font-size: rem-calc(21);
        margin-bottom: 0;
      }
    }
  }
}
