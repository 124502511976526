$block: "newsroom";
$breakpoint-size: "small";

.#{$block} {
  background-color: $color-gray-lightest;

  &__wrapper {
    padding: rem-calc(30) 0 0;
    max-width: 83rem;
    margin: 0 auto;

  }

  .facet-pagination {
    width: initial;
    display: flex;
    align-items: center;
    min-width: 247px;

    &.grid-x {
      .cell.small-1.large-2 {
        display: none;
      }

      .large-4 {
        margin: 0 auto;
    
      }
    }

    &__per-page.cell.small-0.large-6 {
      display: none !important; // Overriding some random stiff styles coming from a library.
    }

    &__per-page {
      display: block !important;
    }
  }

  .facet-sort {
    flex-direction: row;
    width: 100%;
    margin: auto;

    &__sort-by {
      order: initial;
    }

    &__select {
      width: initial;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: rem-calc(20) rem-calc(20);
    background-color: $color-white;

  }

  &__filter {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    
    select {
      min-width: 110px;
      margin-bottom: 0;
      padding-left: rem-calc(18);
      border-radius: 4px;
    }

    span {
      min-width: 64px;
      margin-left: rem-calc(15);
    }

    label {
      margin-right: rem-calc(20);
      font-family: $font-medium;
      font-size: $font-size-smaller;
    }
  }

  &__news-list {
    margin-top: rem-calc(20);

    .newsroom-article {
      margin-top: rem-calc(3);
      padding: rem-calc(50) rem-calc(70);
      background-color: $color-white;
    }
  }

  &__press-contacts {
    margin-top: rem-calc(40);
    padding: rem-calc(70) rem-calc(20);
    padding-bottom: rem-calc(100);
    background-color: $color-white;

    &__contact {
      width: 100%;
      margin-top: rem-calc(30);
      margin-right: rem-calc(140);

      h6 {
        text-transform: uppercase;
      }
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1321px;
      margin: 0 auto;

    }


    &__title {
      width: 100%;

      h3 {
        font-size: rem-calc(36);
      }
    }
  }
}
