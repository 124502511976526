$block: "contact-location";
$breakpoint-size: "large";

.#{$block} {
  .map-icon {
    display: block;
  }

  .rte-block {
    padding-left: $space5;
  }
}