$block: "hero-breadcrumbs";
$breakpoint-size: "medium";

.#{$block} {
  display: block;
  position: absolute;
  top: 0;
  margin-top: $space5;
  left: 0;
  right: 0;
  z-index: 2;

  .breadcrumbs {
    padding: 0 map-get($grid-column-gutter, $breakpoint-size);
    margin: 0 auto;

    li {
      text-transform: none;
      font-size: $font-size-smaller;

      a,
      &:after {
        color: $color-white;
        opacity: 0.75;
      }


      &:last-of-type {
        color: $color-white;
      }
    }
  }

  //modifier Dark Text
  &--dark {
    .breadcrumbs {
      li {
        a,
        &:after {
          color: $color-text;
        }

        &:last-of-type {
          color: $color-text;
        }
      }
    }
  }
}

// Not ideal to use sibling selector, but need to target this way to avoid integration changes
.hero-image + .#{$block},
.brands-hero .#{$block} {
  margin-top: 0;
  padding: $space5 0 $space-larger;
  background: rgba(0,0,0,0.5);
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.75) 100%);
}
