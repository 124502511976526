$block: "video-block";
$breakpoint-size: "small";

.#{$block} {
  max-height: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__video {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .reveal {
    $flex-video-padding-top: rem-calc(25);
    $flex-video-padding-bottom: 67.5%;
    $flex-video-margin-bottom: rem-calc(16);
  }

  picture, img {
    object-position: 50% 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
  }

  &__trigger {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__cta {
    margin: 0;
  }

  &__details {
    background: $color-white;
    padding: $space 0 0;

  }

  &__title {
    padding-bottom: $space4;
    font-size: map-get(map-get($typography, $breakpoint-size), h2);
    font-family: $font-bold;
    word-break: break-word;
    a {
      @include underlineLink;
      color: $color-text;
    }
  }

  &__play-btn {
    $transition-time: .25s;

    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    transition: background $transition-time;
    border: 0;
    background: transparent;
    z-index: 1;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transition: background-color $transition-time, border-color $transition-time;
      content: '';
    }

    &:before { // triangle
      width: 0;
      height: 0;
      transform: translateX(2px);
      border-style: solid;
      border-width: 9.5px 0 9.5px 16.5px;
      border-color: transparent transparent transparent $color-brand-accent-1;
      z-index: 3;
    }

    &:after { // circle
      $circle-size: rem-calc(55);

      width: $circle-size;
      height: $circle-size;
      border-radius: 100%;
      background-color: $color-white;
      z-index: 2;
      box-shadow: 0 0 30px rgba($color-black, .5);
    }

    &:hover,
    &:focus {
      transition: background $transition-time;
      background: radial-gradient(circle, rgba($color-black, .2), rgba($color-black, .05));

      &:before {
        border-color: transparent transparent transparent $color-white;
      }

      &:after {
        background-color: $color-brand-accent-1;
      }
    }
  }
}
