$block: "footer-list";
$breakpoint-size: "small";

.#{$block} {
  color: $color-white;

  &__detailsLink {
    display: inline-block;
  }

  &.our-company,
  &.support {
    .#{$block}__heading,
    li p:first-child {
      display: inline-flex;
      padding: rem-calc(10) 0;
      font-family: $font-bold;
      font-size: rem-calc(12);
      margin-bottom: 0;
    }

    .#{$block}__details,
    .#{$block}__detailsLink,
    li p:not(:first-child) {
      font-family: $font-regular;
      font-size: map-get(map-get($typography, $breakpoint-size), p-small);
      opacity: .85;
    }

    li p {
      padding: 0 $space5;
    }

  }

  &.get-in-touch {
   .#{$block}__heading,
    li p:first-child {
      display: inline-flex;
      padding: rem-calc(10) 0;
      font-family: $font-bold;
      font-size: rem-calc(12);
      margin-bottom: 0;
    }

    .telephone {
      display: flex;
      padding-left: 10px;
      width: 100%;
      justify-content: flex-start;
    }

    .#{$block}__tel {
      width: 20%;
    }

    .#{$block}__address {
      margin-top: 15px;
    }

    .#{$block}__details,
    .#{$block}__detailsLink {
      position: relative;
      display: inline-flex !important;
      width: 70%;
      padding: 0 !important;
      color: #c7e7ff !important;
      font-family: $font-regular;
      font-size: rem-calc(14);

      .icon-how-to-buy {
        position: absolute;
        top: 4px;
        left: 0;
        font-size: rem-calc(13);
        padding-right: 5px;
      }
    }

    .#{$block}__details {
      &--address-link {
        display: block !important;
        padding-left: 22px !important;
        line-height: rem-calc(22);
      }
    }

    li p {
      padding: 0 $space5;
    }
  }
}
