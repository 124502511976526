$block: "map-icon";
$breakpoint-size: "small";

.#{$block} {
  position: relative;
  width: rem-calc(28);
  height: rem-calc(27);
  //margin top + left added as a fix for PC-60
  margin-top: -28px;
  margin-left: -14px;

  &__icon {
    position: relative;

    &:after {
      @include iconStyles;
      content: map-get($icons, icon-how-to-buy);
      font-size: rem-calc(38);
      color: $color-text;
    }

    &:before {
      content: "";
      padding: 5px;
      height: 5px;
      background: $color-text;
      position: absolute;
      top: -10px;
      left: 8px;
    }
  }

  &__icon-preferred-color {
    z-index: 3;

    .map-icon__icon {
      &:after {
        color: $color-primary-brand;
        z-index: 3;
      }

      &:before {
        content: "";
        background: $color-primary-brand;
        z-index: 3;
      }
    }
     .map-icon__number {
      background: $color-primary-brand;
      z-index: 3;
    }
  }

  &__icon-black {
    z-index: 3;

    .map-icon__icon {
      &:after {
        color: $color-black;
        z-index: 3;
      }

      &:before {
        content: "";
        background: $color-black;
        z-index: 3;
      }
    }

    .map-icon__number {
      background: $color-black;
      z-index: 3;
    }
  }

  &__icon-accent-brand-color {
    z-index: 9999;

    .map-icon__icon {
      &:after {
        color: $color-brand-accent-1;
        z-index: 3;
      }

      &:before {
        content: "";
        background: $color-brand-accent-1;
        z-index: 3;
      }
    }

    .map-icon__number {
      background: $color-brand-accent-1;
      color: $color-black;
      z-index: 3;
    }
  }

  &__number {
    font-weight: bold;
    font-size: rem-calc(14);
    color: $color-white;
    background: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: auto;
  }

}