$breakpoint-size: "large";

h1, .h1 {
  font-size: map-get(map-get($typography, $breakpoint-size), h1);
}

h2, .h2 {
  font-size: map-get(map-get($typography, $breakpoint-size), h2);
}

h2 {
  &.blue-border-heading {
    font-size: $font-size-xlarger;
    line-height: 35px;

    &:after {
      margin-top: rem-calc(18);
    }
  }
}

h3, .h3 {
  font-size: map-get(map-get($typography, $breakpoint-size), h3);
}

h4, .h4 {
  font-size: map-get(map-get($typography, $breakpoint-size), h5);
}

h5, .h5 {
  font-size: map-get(map-get($typography, $breakpoint-size), h5);
}

h6, .h6 {
  font-size: map-get(map-get($typography, $breakpoint-size), h6);
}
