$block: "quick-links";
$breakpoint-size: "small";

.#{$block} {
  z-index: 11;

  // MODIFIER V2
  &--wrapped {
    box-shadow: 0 4px 5px 0 #AFAFAF;

    .quick-links-secondary__contact {
      li:nth-child(1) {
        order: -2;
      }

      &--socialMedia {
        order: -1;
      }
    }

    .quick-links-secondary {
      a,
      p,
      span,
      &__contact--socialMedia a .icon:before {
        color: $color-gray-3;
      }

      &__contactDetails a:hover,
      &__contactDetails a:focus {
        color: $color-primary-brand;

        span {
          color: $color-gray-3;
        }
      }

      &__contact--socialMedia a:hover {
        .icon:before {
          color: $color-primary-brand;
        }
      }

      &__contact--link:hover,
      &__contact--link:focus {
        span {
          color: $color-gray-3;
        }
      }
    }
  }
}