$block: "img-container";
$breakpoint-size: "small";

.#{$block} {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    display: block;
    padding: $space 0;
  }

  &__image {
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-height: rem-calc(46);
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // IE only fix for sticky nav logo

    &__image {
      width: 70%;
      max-height: rem-calc(30);

    }
  }

  .slick, .slick-wrapper, .slick-slider {
    width: 100%;
  }

  // .js-featuredBrandsSlider.img-container__wrapper {
  //
  //   a {
  //     width: 100%;
  //
  //   }
  //
  //   .#{$block}__image {
  //     max-height: rem-calc(67);
  //   }
  // }

  .js-featuredBrandsSlider {
	  .slick-slide > div {
		  height: 0;
		  width: 50%;
		  position: relative;
		  padding-top: 33%;
		  margin: 0 auto;
      text-align: center;
    
      a {
        position: absolute;
        top: 0;
        left: 0;
      } 
    }

	  a {
      height: 100%;
      width: 100%;
		  object-fit: contain;
		  text-align: center;
		  object-position: center;
	  }
  }
}
