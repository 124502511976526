$block: "vertical-slider";
$breakpoint-size: "small";

.#{$block} {
  text-align: center;
  overflow: hidden;
  padding-bottom: rem-calc(10);
  margin-bottom: $space5;

  &__sliderNav {
    padding: $space 0 0;

    .vertical-slider__img {
      height: rem-calc(265);
      width: rem-calc(170);
      margin: auto;
    }
  }

  &__img {
    img, picture {
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: contain;
    }
  }

  &__mainImage {
    display: none;
    visibility: hidden;
  }
}