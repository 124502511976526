$block: "table-models";
$breakpoint-size: "small";

.#{$block} {
  &__detailsList {

    span,
    a {
      display: block;
    }

    a {
      font-family: $font-bold;
      color: $color-primary-brand;
    }
  }
}