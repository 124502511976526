@at-root {
  @font-face {
    font-family: 'cmco-icon';
    src: url('#{$filepath-font-icons}cmco-icon.ttf') format('truetype'),
    url('#{$filepath-font-icons}cmco-icon.woff') format('woff'),
    url('#{$filepath-font-icons}cmco-icon.svg#cmcoicon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cmco-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#{$selector-icon} {
  @include iconStyles;
}

@each $icon-name, $icon-content in $icons {
  .#{$icon-name} {
    transition: color $animate-fast;

    &:before {
      content: $icon-content;
    }
  }
}

.icon-facebook:before {
  color: $color-facebook;
}

.icon-instagram:before,
.icon-x:before {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: '';
  vertical-align: middle;
}

.icon-instagram:before {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/instagram-logo.svg');
}

.icon-x:before {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/x-logo-hover.svg');
}

.icon-twitter:before {
  color: $color-twitter;
}

.icon-checkmark:before {
  color: #0061aa;
}

.icon-youtube:before {
  color: $color-youtube;
}

.icon-linkedin:before {
  color: $color-linkedin;
}

.icon-website-cursor .path1:before {
  content: "\e925";
  color: rgb(13, 13, 13);
}

.icon-website-cursor .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-website-cursor .path3:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-circle-download:before {
  content:  "\e93a";
}

.icon-website-magnetek:before {
  content: "\e927";
}

.icon-website-www:before {
  content: "\e928";
}

.icon-quote-right:before {
  content: "\e91c";
}

.icon-phone:before {
  content: "\e942";
}

.icon-list-circle:before {
  content: "\e90c";
}

.icon-envelope:before {
  content: "\e935";
}

.icon-link:before {
  content: "\e936";
}

.icon-phone-left:before {
  content: "\e937";
}

.icon-external-link:before {
  content: "\e938";
}

.icon-external-link-2:before {
  content: "\e93b";
}

.icon-vimeo::before {
  content: "\e939";
  color: $color-vimeo;
}

.icon-plus-small:before {
  content: "\ea0a";
}

.icon-minus-small:before {
  content: "\ea0b";
}

.icon-arrow-up:before {
  content: "\e92b";
}

.icon-filter:before {
  content: "\e92c";
}

.icon-crosshair:before {
  content: "\e92d";
}

.icon-print:before {
  content: "\e93c";
}

.icon-arrow-left-long:before {
    content: "\e97d";
}

.icon-preview:before {
    content: "\e97e";
}
.icon-grid:before {
    content: "\e97f";
}
.icon-list2:before {
    content: "\e980";
}
