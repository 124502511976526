$block: "hero";
$breakpoint-size: "medium";

.#{$block} {
	&.slider-hero {
		.hero-content {
			position: relative;

			&__text {
			  &--header{
				display: block;
			  }
			  &--header-mobile {
				display: none;
			  }
			}
		}

		.slider-hero__thumbnails {

			span {
				padding: 0 rem-calc(14) rem-calc(16);
				color: $color-white;
				font-family: $font-bold;
				line-height: 15px;
				font-size: rem-calc(13);
				text-transform: uppercase;
			}

			.thumbnail-image {
				img {
					height: 126px;
				}
			}

			.thumbnail-text {
				display: block;
				align-self: flex-end;
			}

			.thumbnail-text-mobile {
				display: none;
			}
		}
	}
}
