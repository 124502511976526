$block: "post-card";
$breakpoint-size: "large";

.#{$block} {
  &__content {
    padding-left: $space;

    &--title {
      font-size: map-get(map-get($typography, $breakpoint-size), p);
    }
  }

  ///////////////////////////////
  // MODIFIER: FEATURED POST CARD
  &--featured {
    .#{$block} {

      &__content {
       &--title {
         font-size: map-get(map-get($typography, $breakpoint-size), h3);
       }

        &--description, p {
          line-height: $line-height;
        }
      }
    }
  }

  //////////////////////////
  // MODIFIER: EVENT CARD
  &--eventCard {
    padding: 0 $space-larger;

    .#{$block} {
      &__content {
        max-width: rem-calc(610);

        &--cardTag,
        &--description, p {
          display: inline-block;
        }
      }

      &__image {
       width: rem-calc(200);
      }

      &__cta {
        margin-left: auto;
      }
    }
  }
}
