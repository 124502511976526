$block: "resources-landing-page";
$breakpoint-size: "large";

.#{$block} {
  .media-block {
    flex-direction: row;

    &--media-left {
      flex-direction: row-reverse;
    }
  }
}