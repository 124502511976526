$block: "facet-pagination";
$breakpoint-size: "large";

.#{$block} {
  background: $color-white;
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  margin: rem-calc(11) 0 0;

  &__per-page {
    display: flex !important;

    font-size: rem-calc(14);

    select, option {
      font-size: rem-calc(14)
    }
  }

  &__pagination-item {
    font-size: rem-calc(14)
  }
}