$block: "table";
$breakpoint-size: "small";

.#{$block} {
  line-height: $line-height;

  table {
    text-align: center;
    border-style: hidden;
    margin: auto;

    td+td,
    th+th {
      border-left: $border-thinnest solid $color-gray-light-2;
    }

    tr+tr td,
    tr+tr th {
      border-top: $border-thinnest solid $color-gray-light-2;
    }
  }

  &__head {
    th {
      background: rgba(13, 13, 13, 0.9);
      color: $color-white;
      text-transform: uppercase;
      font-family: $font-bold;
      font-size: rem-calc(10);
    }
  }

  &__body {

    tr,
    tr:nth-child(even) {
      background: $color-white;
    }

    td {
      text-transform: uppercase;
      font-family: $font-regular;
      color: $color-gray-3;
      font-size: map-get(map-get($typography, $breakpoint-size), table);
    }
  }

  th,
  td {
    text-align: center;
  }
}