$block: "back-to-top";
$breakpoint-size: "small";

.#{$block} {
  position: fixed;
  right: 20px;
  bottom: 40px;
  display: none;
  padding: $space3;
  background: $color-white;
  box-shadow: 0 0 25px 0 rgba($color-black, .3), 7px 7px 0 0 $color-primary-brand;
  z-index: $z-index-max;

  a {
    color: $color-primary-brand;
    font-family: $font-bold;
    font-size: rem-calc(13);
    text-transform: uppercase;

    .icon {
      &:before {
        position: relative;
        top: 3px;
        margin-right: rem-calc(10);
        font-size: rem-calc(18);
      }
    }
  }
}
