$block: "sub-pages-block";
$breakpoint-size: "small";

.#{$block} {
  padding-left: $space5;
  padding-right: $space5;
  position: relative;

  &__backgroundFull,
  &__background {
    background: $color-background-lighter;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  &__backgroundFull {
    top: 0;
    height: 100%;
  }

  &__background {
    bottom: 0;
    height: 70%;
  }

  .text-container {
    width: 100%; // IE fix
  }
}