$block: "pim";
$breakpoint-size: "small";

.#{$block} {
  padding-bottom: rem-calc(14);
  display: flex;
  align-items: center;

  img {
    width: rem-calc(19);
  }

  &__icon {
    color: $color-primary-brand;
    font-size: rem-calc(25);
  }

  span:last-child {
    @include transition;
    color: $color-text;
    line-height: $line-height-small;
    font-size: $font-size-mobile-small;
    font-weight: bold;
    text-transform: capitalize;
    padding-left: rem-calc(10);
    word-break: break-word;
  }

  &:hover {
    span {
      color: $color-primary-brand;
    }
  }
}
