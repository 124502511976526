$block: "region-language-selector";
$breakpoint-size: "small";

.#{$block} {
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 99;

  &.is-active {
    position: relative;
    
    .region-language-selector__menuContent {
      height: 100vh;
      overflow: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & .close-icon {
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding: $space $space $space 0;
    }

    & .icon-close-menu {
      @include transition;
      position: absolute;
      top: 20px;
      right: 30px;
      padding: rem-calc(5);
      border: 1px solid rgba($color-white, 0);
      position: absolute;
      font-size: rem-calc(12);
      border-radius: 50%;
      &:hover, &:focus {
        border: 1px solid rgba($color-gray-light, 0.8);
        padding: rem-calc(10px);
        color: rgba($color-gray-light, 1);
        top: 14.8px;
        right: 25px;
      }


    }

    & .icon::before {
      font-size: rem-calc(21);
      transition: color 0.20s;
      cursor: pointer;
    }

  }

  .hide-see-all {
    display: block;
    position: absolute;
    width: 100px;
    height: 50px;
    right: 0;
    top: -55px;
    background: $color-gray-lightest;
  }

  .region-selector-block {
    &__wrapper {
      text-align: left;
      padding: rem-calc(20 22);
    }
  }

  .countries-selector--reg {
    background: $color-background-light;
  }

  .countries-selector__title {
    padding: rem-calc(22);
    padding-bottom: 0;
    display: block;
    font-family: $font-bold;
    font-size: $font-size-mobile-large;
    text-transform: capitalize;
  }

  .countries-selector__item {
    background: transparent;
    border-bottom: 1px solid $color-gray-light;
    padding: rem-calc(20 0 20 22);
  }

  .countries-selector__list {
    margin-left: 0;
  }

  .countries-selector__countryCol {
    //width: 45%;

    img {
      width: rem-calc(23);
      height: rem-calc(16);
    }
  }

  .countries-selector__langCol {
    //width: 55%;
    //margin: 0;
    a {
      background-color: transparent;
    }
    &--link {
      @include underlineLink;
      width: initial !important;
      color: $color-primary-brand;
      text-transform: capitalize;
      font-size: rem-calc(12) !important;
      font-family: $font-regular !important;
      &:hover, &:focus {
        color: $color-gray-3!important;
      }
    }
  }

  //menu
  &__menu {
    display: flex;
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
  }

  &__menuContent {
    background: $color-white;
    width: 100%;
  }

  &__closeMenu {
    cursor: pointer;
    width: 15%;
    min-width: rem-calc(60);
    height: rem-calc(60);
    background: $color-black;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      color: $color-white;
      font-size: 24px;
    }

    &:hover {
      .icon {
        opacity: .85;
      }
    }
  }

  &__flat {
    display: none;
  }
}
