$block: "brand-mobile-nav";
$breakpoint-size: "small";

.#{$block} {
  display: flex !important;
  height: rem-calc(59) !important;
  align-items: center;

  .cell {
    justify-content: flex-start !important;

	&.small-5 {
		position: relative;
		height: 0;
		max-width: rem-calc(132);
		padding-top: 2.875rem;
	}

	&.small-7 {
		padding-left: rem-calc(16);
	}
  }

  img {
    object-fit: contain;
    max-width: rem-calc(132);
	max-height: 60%;
  }

  picture {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
  }

  p {
    color: $color-primary-brand-gray;
    font-family: $font-regular !important;
    margin-bottom: 0;
  }

}
