$block: "threeX-content-block";
$breakpoint-size: "medium";

.#{$block} {
  display: flex;
  flex-direction: column;

  &__blocks {
    flex-wrap: nowrap;

    .content-block {
      &:first-child {
        padding-right: 8px;
      }
  
      &:last-child {
        padding-left: 8px;
      }
  
      &__content {
        width: auto !important;
      }
    }

  }

  .content-block {
    display: flex;
    margin: 0;
    flex: 1; 

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
        flex: auto;
      }

      .rte-block {
        margin-bottom: $space;
      }

      .ie & {
        height: auto;
      }

      .cta {
        align-self: flex-start;
        margin-top: auto;
        margin-bottom: 0;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //IE11 fix
        flex: auto;
      }
    }

    .image-block {
      .ie & {
        height: rem-calc(354);
      }
    }

    .image-block,
    .video-block,
    .video-block__video {
      img {
        max-height: 190px !important;
      }
    }

  }
}
