$block: "cookiefirst";
$breakpoint-size: "large";

.#{$block}-root {
    div[data-cookiefirst-widget="banner"] {
        padding: rem-calc(25) rem-calc(40) rem-calc(35);
    }

    div[data-cookiefirst-widget="banner"] > div {
        display: flex;
        flex-direction: row;

        div {
            &:first-child {
                flex: 0 0 45%;

                div {
                    flex: 0 0 100%;
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 100%;
                }
            }
            &:last-child {
                flex: 0 0 55%;
                max-width: 55%;

                div {
                    flex: 0 0 100%;
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 100%;
                }
            }
        }
    }

    div[data-cookiefirst-widget="banner"] div {
        button {
            flex: 1 0 auto;
        }
    }

    [data-cf-action="reject"] {
        border-radius: rem-calc(5);
        color: $color-white;
    }

    [data-cf-action="adjust"] {
        display: none;
    }
}
