$block: "narrow-banner";
$breakpoint-size: "large";

.#{$block} {
  &__wrapper {
    max-width: rem-calc(1440);
    padding: 0 rem-calc(70);
  }

  &__content {
    max-width: rem-calc(1080);
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
  }

  &__description {
    font-size: $font-size-smaller;
  }

  /////////////////////////////////
  //MODIFIER: ALIGN CONTENT COLUMN
  &--contentColumn {
    .#{$block} {
      &__content {
        flex-direction: column;
        max-width: rem-calc(522);
        margin-right: rem-calc(105);
      }

      &__heading {
        font-size: map-get(map-get($typography, $breakpoint-size), h3);
        padding-bottom: rem-calc(15);
      }
    }
  }
}