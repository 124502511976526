$block: "document-library";
$breakpoint-size: "large";

.#{$block} {

	&__inner.component {
		padding-top: 0;
		padding-bottom: $space-largest;
	}

	.brands-multi-content-block {
		.grid-x {
			margin-right: -$brand-grid-spacing-lg;
			margin-left: -$brand-grid-spacing-lg;
		}
	}

	&__sort {
		margin: 0;
		padding: $space2 (map-get($grid-column-gutter, $breakpoint-size)/2);
		order: 3;
		flex-grow: 1;
		justify-content: flex-end;

		p {
			min-width: max-content;
		}
	}

	&__search-term-list {
		margin-bottom: $space5;
	}

	&__sort,
	.facets__options-name {
		margin-bottom: $space5;
	}

	&__items-per-page {
		flex-grow: 0;
	}

	&__filter-button {
		display: none;
	}

	&__results-cards {

		.document-library__resource-card {
			margin-top: $space4;
			margin-bottom: $space4;
		}

		&.list-view {
			.document-library__resource-card {
				margin-top: $space2;
				margin-bottom: $space2;
			}

			.document-library__resource-card-content {
				padding-right: rem-calc(200);
			}

			.featured-card__banner {
				top: 33px;
				right: 38px;
				height: auto;
				border: 0;
				box-shadow: none;
			}

			.featured-card__image {
				max-height: rem-calc(150);
			}
		}
	}

	&__results {

		.facets {
			width: auto;
			padding: $space4 $space5;

			.facets__container {
				&--new-search {
					order: 1;
				}

				&--wizard {
					order: 2;
					border-top: 1px solid $color-stroke;
					padding-top: $space;
				}

				&--narrow-results {
					order: 3;
					margin-top: $space3;
				}
			}
		}

		.facet-page {
			&__items {
				width: auto;
				flex-grow: 1;
				padding-left: $space;
			}

			&__filters--hidden-mobile {
				position: static;
				background: $color-background-lighter;

				.facets {
					right: 0;
				}

				.facets__wrapper {
					opacity: 1;
					left: 0;
				}
			}
		}

		&__leading-text {
			flex-flow: row wrap;

			h2 {
				width: 100%;
			}
		}
	}

	&__archived-checkbox {
		flex-grow: 1;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background-color: $color-background-lighter;
		padding-left: $space5;

		input {
			margin: 0 $space2 0 0;
		}
	}

	&__layout {
		flex-basis: auto;

		.facet-sort {
			&__grid-list-icons {
				padding-right: 0;
				margin-right: $space;

				.icon {
					color: inherit;
					&.active {
						border: none;
					}
				}
			}
		}
	}
}
