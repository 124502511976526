$block: "newsroom-article";
$breakpoint-size: "small";

.#{$block} {
  @include pageContainer();

  display: flex;
  flex-direction: column;

  &__content {

    a {
        display: inline-block;
        margin-bottom: rem-calc(10);
        transition: color .3s ease-in-out;
        color: $color-black;
        font-family: $font-bold;
        font-size: rem-calc(21);

        &::after {
          position: relative;
          top: -5px;
          display: inline-block;
          margin-left: rem-calc(5);
          font-family: 'cmco-icon';
          font-size: rem-calc(12);
          content: "\e93b";
        }

        &:hover, &:focus {
          color: #1468a0;
      }
    }

    p {
      font-size: rem-calc(14);
      line-height: rem-calc(24);
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: rem-calc(40);
  }

  &__meta {
    display: flex;
    color: $color-gray-3;
    font-family: $font-bold;
    font-size: $font-size-smallest;
    text-transform: uppercase;

    span:first-child {
      position: relative;
      padding-right: rem-calc(20);

      &::after {
        position: absolute;
        top: 4px;
        right: 10px;
        display: block;
        width: 2px;
        height: 12px;
        background-color: $color-gray-3;
        content: '';
      }
    }
  }

}
