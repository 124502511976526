$block: "overview-text";
$breakpoint-size: "small";

.#{$block} {
  text-align: center;
  margin-bottom: $space;

  &__title {
    font-size: map-get(map-get($typography, $breakpoint-size), p-small);
    text-transform: capitalize;
    line-height: $line-height-small;
    margin-bottom: $space3;

    &.theme-primary {
      color: $color-primary-brand;
    }

    &.themeSecondary {
      color: $color-primary-brand-dark;
    }
  }

  h1 {
    text-align: left;
  }

  h1,
  &__heading {
    margin-bottom: 1rem;
    font-size: map-get(map-get($typography, $breakpoint-size), h2-alt);
    font-family: $font-bold;
  }

  &__sub-heading,
  &__description,
  .rte-block p {
    font-family: $font-regular;
    font-size: map-get(map-get($typography, $breakpoint-size), p);
    color: $color-gray-3;
    margin: $space4 auto 0;
    line-height: $line-height;
  }

  &--article-detail {
    text-align: left;
    padding: 0;

    .overview-text {
      &__sub-heading {
        font-weight: bold;
        color: $color-body-text-2;
      }
    }
  }
}
