$block: "teaser-column-block";
$breakpoint-size: "small";

.#{$block} {
  position: relative;
  padding: $space 0;

  .teaser-card {
    margin: 0 $space3 $space5;
  }

  &__background,
  &__backgroundFull {
    background: $color-background-lighter;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  &__background {
    height: 72%;
  }

  &__backgroundFull {
    height: 100%;
  }
}
