$block: "region-language-selector";
$breakpoint-size: "medium";

.#{$block} {
    top: 0;

    //menu
    &__menu {
        display: flex;
    }
}