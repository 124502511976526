$block: "resource-listing-page";
$breakpoint-size: "medium";

.#{$block} {
  .featured-card {

    &__cta {
      width: unset;
      padding-top: 0;

      .cta {
        width: rem-calc(175);
      }
    }
  }
}