$block: "select-box";
$breakpoint-size: "small";

.#{$block} {
  label {
    font-family: $font-bold;
    text-transform: uppercase;
    font-size: map-get(map-get($typography, $breakpoint-size), label);
    margin-bottom: rem-calc(10);
  }

  select {
    width: 100% !important;
    border-radius: $border-radius;
    border: $border-thin solid $color-border-input;
    padding: rem-calc(12) $space5;
    height: rem-calc(48);
    font-family: $font-light;
    color: $color-gray-2;
    text-transform: capitalize;

    @include placeholder {
      color: $color-placeholder;
    }

    &.error {
      box-shadow: 0 0 6px 0 $color-border-input;
      margin-bottom: rem-calc(10);
    }
  }
}