$block: "service-repair-page";
$breakpoint-size: "small";

.#{$block} {

  .map-container {
    position: relative;
    height: auto;
    width: 100%;
  }

  .update-search-submit {
    display: flex;
    flex-wrap: nowrap;
    height: rem-calc(45);
    margin-top: rem-calc(4);

    .pdp-primary-cta {
      flex: 2;
      margin-top: 0;
    }

    .view-detail-cta {
      flex: 1;
      margin-right: rem-calc(10);
    }
  }

  .map-icon {
    cursor: pointer
  }

  .locator-results-block {
    border-top: 1px solid $color-gray-light;

    &__no-results {

      &--text {
        color: #0D0D0D;
        font-size: rem-calc(14);
        font-weight: bold;
        padding: rem-calc(20);
        border-top: 1px solid $color-gray-lightest;
        border-bottom: 1px solid $color-gray-lightest;
      }

      &--footer {
        padding: rem-calc(20);

        .info-block__name {
          margin-bottom: rem-calc(7);
        }
      }
    }
  }

  // service locator map text
  .gm-style-pbt {
    width: 50%;
    left: 65%;
    transform: translate(-42%, -50%);
  }
}