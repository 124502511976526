$block: "content-landing-page";
$breakpoint-size: "large";

.#{$block} {
  .teaser-column-block {
    &__twoCol,
    &__threeCol {
      padding-bottom: 0;
    }
  }

  .featured-items {
    max-width: $global-width;
    margin: $space-largest auto;
    padding: 0 map-get($grid-column-gutter, $breakpoint-size);
  }
}
