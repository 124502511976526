$block: "hero";
$breakpoint-size: "small";

.#{$block} {
  position: relative;
  display: flex;
  z-index: auto;

  &-content__link {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }
  }

  .cta {
    min-width: rem-calc(185);
  }

  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 640px;
    overflow: hidden;

    &:before { 
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0.2) 100%);
      content: '';
    }

    video {
      width: 100%;
      min-width: 800px;
    }

  }

  &--set-height {
    min-height: rem-calc(365);

    .hero-image {
      &:before {
        background: none;
      }
    }
  }

  // SLIDING HERO
  &.slider-hero {

    .visual-cue {
      display: none;
    }

    .slider-hero__wrapper {
      width: 100%;
    }

    .slider-hero__thumbnails {
      position: relative;
      display: block;
      display: flex;
      flex-wrap: nowrap;

      li {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        border-top: 4px solid transparent;
        background-color: $color-black;
        cursor: pointer;

        .thumbnail-progress {
          content: '';
          position: absolute;
          height: 4px;
          width: 0%;
          top: -4px;
          left: 0;
          background-color: $color-black;
          transition-property: width;
        }

        &.tns-nav-active {
          background-color: #282828;
          position: relative;

          .thumbnail-progress {
            background-color: $color-primary-brand;
            width: 100%;
          }
    
          .thumbnail-text-mobile {
            display: none;
          }

          .thumbnail-image {
            &::before {
              display: none;
            }

          }
        }
      }

      li.tns-user-touch {
        .thumbnail-progress {
          transition: none;
          width: 100%;
        }
      }

      .thumbnail-image {
        position: relative;
        width: 100%;

        img {
          width: 100%;
          height: 82px;
          object-fit: cover;
          object-position: center center;
        }

        &:before {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          background: linear-gradient(359deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.65) 70%, rgba(0, 0, 0, 0.2) 100%);
          content: '';
          z-index: 1;
        }
      }

      span {
        position: absolute;
        width: 100%;
        max-width: 300px;
        padding: 0 rem-calc(10);
        color: $color-white;
        font-family: $font-bold;
        font-size: rem-calc(11);
        line-height: 13px;
        text-transform: uppercase;
        z-index: 1;
      }

      .thumbnail-text {
        display: none;
      }

      .thumbnail-text-mobile {
        display: block;
      }
    }

    .tns-outer {
      width: 100%;
    }

    .slider-hero__slide__content {
      display: flex !important; //slider plugin overwrite
      justify-content: center;
      position: relative;
      height: 100%;

      h1 {
        line-height: 1.1;
      }
    }

    .hero-content {
      display: flex;
      position: relative;

      &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__text {
        &--header {
          display: none;
        }
        &--header-mobile {
          display: block;
        }
      }
    }

    .hero-image {
      img {
        object-position: center center;
      }
    }

    .slider-hero__slides {
      position: relative;
      display: flex;
      background-color: $color-black;
    }

    .slider-hero__slide {
      flex: 1;
    }

    .tns-slider {
      transition: none;//remove default slide animations
    }

    .tns-item {
      position: relative;
      opacity: 0;
      z-index: 1;
    }

    .tns-item.tns-slide-active {
      z-index: 2;
    }
  }
}