$block: "article-comments";
$breakpoint-size: "small";

.#{$block} {
  padding: rem-calc(20) 0;

  &_content-block {
    margin-top: rem-calc(20);
    padding: rem-calc(20);
    background-color: $color-gray-lighter;
    
    .name{
      font-size: $font-size-medium;
      font-weight: $font-bold;

    }

    .timestamp {
      margin-left: rem-calc(20);
      color: $color-gray-light;
      font-size: $font-size-smaller;

    }
    
    p {
      margin-top: rem-calc(15);

    }
  }
}
