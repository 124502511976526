$block: "expanded-brands-landing";
$breakpoint-size: "small";

.#{$block} {

    .component {
        margin-bottom: 0;
        margin-top: rem-calc(10);

        &.product-title {
            margin-top: rem-calc(70);
        }
    }

    .sticky-container {
        margin-bottom: 60px;
    }

    .media-block > div:first-child {
        margin-bottom: rem-calc(10);
    }

    #tools {
        padding-top: 0;
    }

    .featured-items {
        padding-top: 0;
    }

    .overview-text__title {
        font-family: $font-bold;
        color: $color-black;
        font-size: $font-size-large;
    }

}
