$block: "slick-slider";
$breakpoint-size: "medium";

.#{$block} {

  padding-bottom: 0;

  //slick styles
  .slick-slide>div {
    text-align: center;
  }

  .slick-arrow {
    @include transition;
    background: $color-primary-brand;
    border-radius: $border-radius;
    width: rem-calc(40);
    height: rem-calc(60);
    z-index: 1;
    //transform: translateY(-100%);

    &:hover, &:focus {
      background: $color-brand-accent-1;
      color: $color-text;
    }

    &:before {
      content: "";
      font-size: 0;
    }
  }

  .slick-prev,
  .slick-next {
    &:before {
      @include transition;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  .slick-prev {
    left: rem-calc(-20);

    &:hover, &:focus {
      &:before {
        border-color: transparent $color-text transparent transparent;
      }
    }

    &:before {
      border-width: rem-calc(5) rem-calc(6) rem-calc(5) 0;
      border-color: transparent $color-white transparent transparent;
    }
  }

  .slick-next {
    right: rem-calc(-20);

    &:hover, &:focus {
      &:before {
        border-color: transparent transparent transparent $color-text;
      }
    }

    &:before {
      border-width: rem-calc(5) 0 rem-calc(5) rem-calc(6);
      border-color: transparent transparent transparent $color-white;
    }
  }

  @media screen and (max-width: rem-calc(1428)) {
    .slick-prev {
      left: rem-calc(-25);
    }

    .slick-next {
      right: rem-calc(-25);
    }
  }
}
