$block: "document-library";
$breakpoint-size: "medium";

.#{$block} {
	$card-spacing: $space3;

	&__inner.component {
		padding-top: $space;
		padding-bottom: $space-larger;
	}

	&__or {
		margin: $space-larger 0;
		font-size: $font-size-medium-2;
	}

	.brands-multi-content-block {
		.grid-x {
			margin-right: -$brand-grid-spacing-med;
			margin-left: -$brand-grid-spacing-med;
		}

		.brand-logo {
			&__logo {
				min-height: rem-calc(100);
			}
		}
	}

	&__product-type {

      select {
        max-width: rem-calc(424);
      }

  }

	&__sort {
    padding: $space5 map-get($grid-column-gutter, $breakpoint-size);
		margin: -3.5rem (-(map-get($grid-column-gutter, $breakpoint-size))) $space;
	}

	&__results {

		h4 {
			font-size: $font-size-standard;
		}

		&-cards {

			.#{$block}__resource-card-content {
				.content-title {
					padding-top: $space4;
					padding-bottom: $space4;
				}
				.content-metadata {
					font-size: $font-size-smaller;
				}
			}

			&.grid-view {
				margin-left: -$card-spacing;
				margin-right: -$card-spacing;

				.#{$block}__resource-card {
					flex: 0 0 33.33%;
					padding: 0 $card-spacing;
				}
			}

			&.list-view {
				.#{$block}__resource-card {

					&-container {

					}

					&-content {
						background-color: $color-white;

						.content-title,
						.#{$block}__resource-card-btns {
							padding-right: $space4;
							padding-left: $space4;
						}

						.content-title {
							padding-top: $space5;
							h4 {
								font-size: $font-size-standard;
							}
						}

						.content-metadata {
							font-size: $font-size-mobile-small;
						}
					}

					.cta {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						background-color: $color-white;
						color: $color-primary-brand;
						padding: $space4 $space $space4 0;
						border: none;
						flex-grow: 0;
						font-size: $font-size-mobile-small;

						&:hover, &:focus {
							color: $color-text;
						}

						span {
							display: block;
							margin-right: $space2;
							&:before {
								left: 0;
								top: 0;
							}
						}
					}
        }
			}
		}

		.facets {
			width: rem-calc(375);
		}
	}

}
