$block: "content-landing-page";
$breakpoint-size: "small";

.#{$block} {
  background: $color-background-light;
  margin-top: -$space;
  margin-bottom: -$space;
  padding-top: $space;
  padding-bottom: $space;
}
