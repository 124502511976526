$block: "four-pillar-hero";
$breakpoint-size: "large";

.#{$block} {
	&__title {
		padding: rem-calc(36) 0 rem-calc(18);
		background-color: $color-black;

		h1 {
			font-size: rem-calc(42); // not in scale
			line-height: 1.1em;

			span {
				margin-bottom: rem-calc(8);
				font-family: $font-regular;
				font-size: $font-size-medium-3;
				line-height: 1.15em;
			}
		}

		&:after {
			top: 133px;
		}
	}

	&__video {
		flex-basis: percentage(1/4);
		width: percentage(1/4);
		height: rem-calc(625);
		border-bottom: 0;

		&-name {
			justify-content: flex-end;
			background: none;

			a {
				all: unset;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100vh;

				&:after {
					display: none;
				}

				&:hover {
					.video-button {
						border-color: $color-brand-accent-1;
						background: rgba($color-black, .55);
						text-shadow: none;

						&:after {
							margin-top: rem-calc(16);
							margin-bottom: rem-calc(-2);
							width: 0;
							height: 0;
							visibility: hidden;
						}
					}
				}

				.video-button {
					transition: all $transition-med;
					border: 3px solid transparent;
					border-radius: 6px;
					background: none;
					min-width: rem-calc(220);
					margin: 0;
					color: $color-white;
					font-family: $font-bold;
					text-align: center;
					text-shadow: $color-black 0 2px 12px;
					text-transform: uppercase;
					padding: rem-calc(16) rem-calc(16) 0;
					transform: translateY(-60px);
					font-size: $font-size-xlarger;
					line-height: 1.1em;

					&:after {
						display: block;
						width: rem-calc(54);
						height: rem-calc(3);
						margin: rem-calc(11) auto 0;
						background: $color-brand-accent-1;
						content: '';
					}
				}

				&:after {
					width: rem-calc(85);
				}
			}
		}

		&:hover,
		&:focus {
			.#{$block}__video-name {
				a {
					background: transparent;
				}
			}
		}

		video {
			display: block;
		}
	}
}
